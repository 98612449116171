import {
    Autocomplete,
    AutocompleteProps,
    Button,
    Chip,
    CircularProgress,
    Divider,
    List,
    ListItem,
    Pagination,
    TextField,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
// import { useQuery } from "@tanstack/react-query";
import {
    Dispatch,
    FocusEvent,
    Fragment,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react";
import { GET } from "./BaseService";
// import { GET } from "../../../Utilities/BaseService";

type Props = {
    getFullRecord?: boolean;
    params?: {};
    optionsPreprocessor?: (opt: any) => any;
    invalidate?: boolean;
    api: string;
    label: string;
    dep?: {};
    filter?: any;
    setFilter?: Dispatch<SetStateAction<any>>;
    required?: boolean;
    disabled?: boolean;
    multiple?: boolean | undefined;
    size?: "small" | "medium";
    defaultValue?: number | number[];
    InputProps?: any;
    _name?: string | number;
    searchKey?: string;
    optionsFilter?: (opts: any[]) => any;
    clearAll?: boolean;
    setOutput?: (value: any) => void;
    tabIndex?: number;
    // openOnFocus?: boolean;
};

const getData = (
    api: string,
    search: string,
    dep: any,
    otherOps: any,
    multiple: boolean | undefined,
    page: any,
    limit: any
) => {
    const params = multiple
        ? { page: page, limit: limit, search: search, otherOps: otherOps }
        : { page: page, limit: limit, search: search, ...dep };

    return GET(api, { ...params });
};

const UpdatedSearchableInput = ({
    params,
    getFullRecord,
    optionsPreprocessor,
    _name,
    invalidate,
    api,
    label,
    filter,
    setFilter,
    size,
    dep,
    multiple,
    defaultValue,
    disabled,
    required = false,
    InputProps = {},
    searchKey,
    optionsFilter,
    clearAll,
    setOutput,
    tabIndex,
}: // openOnFocus = false,
Props) => {
    const [search, setSearch] = useState<string>("");
    const [value, setValue] = useState<any>(multiple ? [] : null);
    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState<any>(false);

    const [defaultValApplied, setDefaultValApplied] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    });

    const autocompleteRef = useRef<any>(null);

    const handleKeyDown = (event: any) => {
        if (event.key === "Tab") {
            setOpen(true);
        } else if (event.key === "Enter") {
            setOpen(false);
        }
    };

    const handleFocus = (ev: FocusEvent<HTMLDivElement, Element>) => {
        setOpen(true);
    };

    const handleBlur = () => {
        setOpen(false); // Close the dropdown when the input field loses focus
    };

    const getFilterData = (
        api: string,
        page: number,
        limit: number,
        params: any
    ) => {
        return GET(api, { page, limit, ...params });
    };

    const { data, isLoading } = useQuery(
        [label, pagination, search, dep, search, invalidate, value],
        () =>
            getFilterData(
                api,
                pagination.page,
                pagination.limit,
                params
                    ? {
                          [searchKey ? searchKey : "search"]: search
                              ? search
                              : undefined,
                          ...params,
                      }
                    : {
                          [searchKey ? searchKey : "search"]: search
                              ? search
                              : undefined,
                      }
            ),
        {
            onSuccess(res: any) {
                if (optionsPreprocessor) {
                    let filteredOpts = [];

                    if (optionsFilter) {
                        filteredOpts = optionsFilter(res.data.rows);
                        setOptions(
                            filteredOpts.map((opt: any) =>
                                optionsPreprocessor!(opt)
                            )
                        );
                    } else
                        setOptions(
                            res.data.rows.map((opt: any) =>
                                optionsPreprocessor!(opt)
                            )
                        );
                } else setOptions(res.data.rows);
            },
        }
    );

    const ref = useRef(0);

    const handleSelectChange = (_val: any) => {
        setValue(_val);

        if (setFilter) {
            if (multiple) {
                setFilter({
                    ...filter,
                    [_name ? _name : label.toLocaleLowerCase()]: !getFullRecord
                        ? _val.map((v: any) => v.id)
                        : _val,
                });
            } else
                setFilter({
                    ...filter,
                    [_name ? _name : label.toLocaleLowerCase()]: _val
                        ? !getFullRecord
                            ? _val.id
                            : _val
                        : "all",
                });
        }

        setOutput && setOutput(_val);
    };

    const handleInputChange = (ev: any, value: any, reason: any) => {
        if (reason === "reset") return;

        ref.current++;
        const newRef = ref.current;

        setTimeout(() => {
            if (newRef === ref.current) {
                setSearch(value);
                ref.current = 0;
            }
        }, 1000);
    };

    const handleSelectAll = () => {
        if (value.length !== options.length) {
            const modOpt = options.filter(
                (opt: any, idx: number) => !value.includes(opt)
            );

            const _val = [...value, ...modOpt];
            setValue(_val);

            setOutput && setOutput(_val);

            setFilter &&
                setFilter({
                    ...filter,
                    [label.toLocaleLowerCase()]: _val.map((v: any) =>
                        !getFullRecord ? v.id : v
                    ),
                });
        } else {
            setValue([]);
        }
    };

    const handleRemoveSingleOption = (
        multiple: boolean = false,
        itemToRemove: any
    ) => {
        if (multiple) {
            const filteredArray: any[] = value.filter(
                (item: any) => item !== itemToRemove
            );

            setValue(filteredArray);

            setOutput && setOutput(filteredArray);

            setFilter &&
                setFilter({
                    ...filter,
                    [_name ?? label.toLocaleLowerCase()]: getFullRecord
                        ? filteredArray
                        : filteredArray.map((item: any) => item.id),
                });
        }
    };

    useEffect(() => {
        if (Boolean(defaultValue) && options.length && !defaultValApplied) {
            if (!multiple) {
                console.log("Default Value: ", defaultValue);

                const selected = options.find(
                    (opt: any) => opt?.id == defaultValue
                );

                console.log(selected);
                setOutput && setOutput(selected);
                setValue(selected);
                setDefaultValApplied(true);
            } else {
                const targetDefaults = defaultValue as number[];

                const filteredOpts = options.filter((opt: any) =>
                    targetDefaults.includes(opt.id)
                );
                setValue(filteredOpts);

                setOutput && setOutput(filteredOpts);
                setDefaultValApplied(true);
            }
        }
    }, [defaultValue, options, defaultValApplied]);

    useEffect(() => {
        if (clearAll) {
            setValue(multiple ? [] : null);
            setOutput && setOutput(multiple ? [] : "");
            setFilter &&
                setFilter({
                    ...filter,
                    [_name ?? label.toLocaleLowerCase()]: "",
                });
        }
    }, [clearAll]);

    return (
        <Autocomplete
            disabled={disabled}
            fullWidth
            multiple={multiple}
            disableCloseOnSelect={multiple}
            renderOption={(props, options, { selected }) => (
                <Fragment key={options.id}>
                    <ListItem
                        {...props}
                        style={{ paddingTop: 1, paddingBottom: 1 }}
                    >
                        {options.name}
                    </ListItem>
                    <Divider />
                </Fragment>
            )}
            size={size ? size : "small"}
            options={options ?? []}
            getOptionLabel={(option) =>
                multiple ? option.id + "" : option.name + ""
            }
            value={value}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            renderTags={(option) =>
                option.map((val: any, idx) => (
                    <Chip
                        sx={{ m: 0.25 }}
                        key={idx}
                        size="small"
                        label={val.name}
                        onDelete={() => handleRemoveSingleOption(multiple, val)}
                    />
                ))
            }
            onChange={(ev, val, reason) => handleSelectChange(val)}
            renderInput={(props) => (
                <TextField
                    {...props}
                    label={label}
                    required={required}
                    InputLabelProps={{
                        shrink: multiple
                            ? value.length > 0
                            : value
                            ? true
                            : false,
                    }}
                    InputProps={{
                        ...props.InputProps,
                        ...InputProps,

                        startAdornment: (
                            <>
                                {InputProps?.startAdornment}
                                {props.InputProps?.startAdornment}
                            </>
                        ),
                        endAdornment: (
                            <>
                                {isLoading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}

                                {props.InputProps?.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            onInputChange={(event, value, reason) =>
                handleInputChange(event, value, reason)
            }
            groupBy={(option) => ""}
            renderGroup={(params) => (
                <List key={params.key}>
                    {multiple && (
                        <ListItem
                            sx={{ paddingTop: 0, paddingBottom: 0, mb: 1 }}
                        >
                            <Button size="small" onClick={handleSelectAll}>
                                select all
                            </Button>
                        </ListItem>
                    )}
                    {params.group}
                    {params.children}
                    <ListItem
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Pagination
                            size="small"
                            count={Math.ceil(
                                data?.data.count / pagination.limit
                            )}
                            page={pagination.page}
                            onChange={(ev, page) =>
                                setPagination({ ...pagination, page })
                            }
                        />
                    </ListItem>
                </List>
            )}
            loading={isLoading}
            filterOptions={(options) => options}
            open={open} // Control the dropdown open state
            onFocus={handleFocus} // Trigger opening on focus
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            tabIndex={tabIndex}

            // openOnFocus={openOnFocus}
        />
    );
};

export default UpdatedSearchableInput;
