import { Add, FilterAltOutlined } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    Grid,
    Tab,
    Tabs,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { FormEvent, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../Utilities/AppContext";
import { GET } from "../../../Utilities/BaseService";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { getSelectedFilters } from "../../Customer/Property/Property";
import SearchableInput from "../../Dashboard/Components/SearchableInput";
import { getReportTemplates } from "../../Payment/Payment";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";
import { currencyFormatter } from "../Bill/Cells/Last12Summary";
import { dateFormatter, monthFormatter } from "../Bill/SingleBill";
import CollectionDetails from "./CollectionDetails";
type Filter = {
    search: string;
    district: number | "" | "all";
    village: number | "" | "all";
    hamlet: number | "" | "all";
    property: number | "" | "all";
    meterNo: string;
    collectionDate: string;
    startDate: string;
    endDate: string;
    paymentMethod: any;
    powerstation: number | "";
};

const initialFilter: Filter = {
    search: "",
    district: "",
    village: "",
    hamlet: "",
    property: "",
    meterNo: "",
    collectionDate: "",
    startDate: "",
    endDate: "",
    paymentMethod: "",
    powerstation: "",
};

const initialClear = {
    district: false,
    village: false,
    hamlet: false,
    powerstation: false,
};

export const getAllCollections = (pagination: any, filter: any) => {
    return GET("/reading/collection", {
        ...Object.fromEntries(
            Object.entries(filter).filter((entry) => Boolean(entry[1]))
        ),
        page: pagination.page + 1,
        limit: pagination.limit,
    });
};

export const getCollectionReport = (pagination: any, filter: any) => {
    return GET("/reading/collection/collections-report", {
        meterNo: filter.meterNo ? filter.meterNo : "",
        district:
            filter.district && filter.district !== "all"
                ? filter.district
                : undefined,

        powerstation: filter.powerstation ? filter.powerstation : undefined,
        village:
            filter.village && filter.village !== "all"
                ? filter.village
                : undefined,
        hamlet:
            filter.hamlet && filter.hamlet !== "all"
                ? filter.hamlet
                : undefined,
        paymentMethod:
            filter["payment method" as any] == "all"
                ? undefined
                : filter["payment method" as any],

        startDate: filter.startDate ? filter.startDate : undefined,
        endDate: filter.endDate ? filter.endDate : undefined,
        page: pagination.page + 1,
        limit: pagination.limit,
    });
};

const Collection = () => {
    const [pagination, setPagination] = useState<any>({
        page: 0,
        limit: 500,
        count: 0,
    });

    const [overallPagination, setOverAllPagination] = useState<any>({
        page: 0,
        limit: 500,
        count: 0,
    });
    const [rowsCount, setRowsCount] = useState(0);

    const [tabIndex, setTabIndex] = useState<string | number>(0);

    const [filter, setFilter] = useState(initialFilter);
    const [intermediateFilter, setIntermediateFilter] = useState(initialFilter);
    const [feedback, setFeedback] = useState("");
    const [reportTemplates, setReportTemplates] = useState<string[]>([]);
    const [reportTemplatesOfBreakDown, setReportTemplatesOfBreakDown] =
        useState<string[]>([]);

    const [clearAll, setClearAll] = useState(initialClear);
    const [changeCount, setChangeCount] = useState(0);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [isRequiredFiltersSelected, setIsRequiredFiltersSelected] =
        useState(false);

    const { user } = useContext(AppContext);
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));

    const handleTabChange = (newIndex: string | number) => {
        setTabIndex(newIndex);
    };

    const { data, isLoading } = useQuery(
        ["collection", pagination.page, pagination.limit, filter],
        () => getAllCollections(pagination, filter),
        {
            enabled: Boolean(changeCount && tabIndex == 1),
            onSuccess(res) {
                if (res.data) {
                    setPagination({
                        ...pagination,
                        count: res.data.count,
                    });

                    const firstRow = res.data.rows[0] ?? {};

                    const _selectedFills = getSelectedFilters(
                        {
                            district: firstRow.reading?.district,
                            village: firstRow.reading?.village,
                            hamlet: firstRow.reading?.hamlet,
                            powerstation: firstRow.reading?.powerstation,
                            startDate: { value: filter.startDate },
                            endDate: { value: filter.endDate },
                        },
                        [
                            "district",
                            "village",
                            "hamlet",
                            "startDate",
                            "endDate",
                        ],
                        filter,
                        {
                            district: { label: "District", valueKey: "name" },
                            village: { label: "Village", valueKey: "name" },
                            hamlet: { label: "Hamlet", valueKey: "name" },
                            powerstation: {
                                label: "Power Station",
                                valueKey: "name",
                            },
                            startDate: {
                                label: "Start date",
                                valueKey: "value",
                            },
                            endDate: {
                                label: "End date",
                                valueKey: "value",
                            },
                        }
                    );

                    setSelectedFilters(() => _selectedFills);
                }
            },
        }
    );

    const { data: collectionBreakdownreportTemps } = useQuery(
        ["report-templates", tabIndex == 1],
        () => getReportTemplates(user.id, "Collection BreakDown"),
        {
            onSuccess(res) {
                setReportTemplatesOfBreakDown(() =>
                    res.data.rows.map((item: any) => ({
                        key: item.key,
                        value: JSON.parse(item.value),
                    }))
                );
            },
        }
    );

    const { data: reportTemps } = useQuery(
        ["report-templates", tabIndex == 0],
        () => getReportTemplates(user.id, "Collections"),
        {
            onSuccess(res) {
                console.log(res);
                setReportTemplates(() =>
                    res.data.rows.map((item: any) => ({
                        key: item.key,
                        value: JSON.parse(item.value),
                    }))
                );
            },
        }
    );

    const { data: CollectionData, isLoading: isCollectionLoadingLoading } =
        useQuery(
            [
                "arrears",
                overallPagination.page,
                overallPagination.limit,
                {
                    ...filter,
                    meterNo: filter.meterNo ? filter.meterNo : undefined,
                },
                changeCount,
            ],
            () => getCollectionReport(overallPagination, filter),
            {
                enabled: Boolean(changeCount && tabIndex == 0),
                onSuccess(res) {
                    if (res.data) {
                        setOverAllPagination({
                            ...overallPagination,
                            count: res.data.count,
                        });

                        const firstRow = res.data.rows[0] ?? {};

                        const _selectedFills = getSelectedFilters(
                            {
                                district: firstRow.reading?.district,
                                village: firstRow.reading?.village,
                                hamlet: firstRow.reading?.hamlet,
                                powerstation: firstRow.reading?.powerstation,
                                startDate: { value: filter.startDate },
                                endDate: { value: filter.endDate },
                            },
                            [
                                "district",
                                "village",
                                "hamlet",
                                "startDate",
                                "endDate",
                            ],
                            filter,
                            {
                                district: {
                                    label: "District",
                                    valueKey: "name",
                                },
                                village: { label: "Village", valueKey: "name" },
                                hamlet: { label: "Hamlet", valueKey: "name" },
                                powerstation: {
                                    label: "Power Station",
                                    valueKey: "name",
                                },
                                startDate: {
                                    label: "Start date",
                                    valueKey: "value",
                                },
                                endDate: {
                                    label: "End date",
                                    valueKey: "value",
                                },
                            }
                        );

                        setSelectedFilters(() => _selectedFills);
                    }
                },
            }
        );

    const handleApplyFilter = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const requiredFilters = {
            district: Boolean(
                intermediateFilter.district &&
                    intermediateFilter.district !== "all"
            ),
            village: Boolean(
                intermediateFilter.village &&
                    intermediateFilter.village !== "all"
            ),
            hamlet: Boolean(
                intermediateFilter.hamlet && intermediateFilter.hamlet !== "all"
            ),
            powerstation: intermediateFilter.powerstation,
            meterNo: intermediateFilter.meterNo,
        };

        if (
            (requiredFilters.district && requiredFilters.powerstation) ||
            requiredFilters.meterNo
        ) {
            setIsRequiredFiltersSelected(true);
            setChangeCount((c) => c + 1);
        } else {
            setIsRequiredFiltersSelected(false);
            setChangeCount(0);
        }
    };

    useEffect(() => {
        if (intermediateFilter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });
            setIntermediateFilter({
                ...intermediateFilter,
                district: "",
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
            setIntermediateFilter({
                ...intermediateFilter,
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
            setIntermediateFilter({ ...intermediateFilter, hamlet: "" });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [
        intermediateFilter.district,
        intermediateFilter.village,
        intermediateFilter.hamlet,
    ]);

    useEffect(() => {
        if (changeCount) {
            setFilter({
                ...filter,
                ...intermediateFilter,
                paymentMethod:
                    intermediateFilter[
                        "payment method" as keyof typeof intermediateFilter
                    ] == "all"
                        ? undefined
                        : intermediateFilter[
                              "payment method" as keyof typeof intermediateFilter
                          ],
            });
        }
    }, [changeCount]);

    const createSummary = (cols: string[]) => {
        const summaryRow = ["Total"];

        if (
            !data ||
            !data.data ||
            !data.data.rows ||
            !Array.isArray(data.data.rows)
        ) {
            return summaryRow;
        }

        let amount = 0;
        let adjustment = 0;
        let surchargePaid = 0;
        let surchargeAmount = 0;

        let totalAmount = 0;

        for (const row of data?.data.rows) {
            amount += row["amounts"]
                .split(",")
                .reduce(
                    (prev: number, curr: string) => prev + parseFloat(curr),
                    0
                );
            adjustment +=
                row["adjs"]
                    .split(",")
                    .reduce(
                        (prev: number, curr: string) => prev + parseFloat(curr),
                        0
                    ) + row["reading"]["surchargeAdj"];

            surchargePaid += row["reading"]["payedSurcharge"];
            surchargeAmount += row["reading"]["applicableSurcharge"];
            totalAmount += row["reading"]["totalAmount"];
        }

        for (const col of cols) {
            switch (col) {
                case "amount":
                    summaryRow.push(currencyFormatter.format(amount));
                    break;

                case "adjustment":
                    summaryRow.push(currencyFormatter.format(adjustment));
                    break;

                case "surchargePaid":
                    summaryRow.push(currencyFormatter.format(surchargePaid));
                    break;

                case "surchargeAmount":
                    summaryRow.push(currencyFormatter.format(surchargeAmount));
                    break;

                case "totalAmount":
                    summaryRow.push(currencyFormatter.format(totalAmount));
                    break;

                default:
                    summaryRow.push("");
            }
        }

        if (user.ops.includes("DELETE COLLECTION")) {
            summaryRow.push("");
        }

        console.log(summaryRow, "summaryRow");

        return summaryRow;
    };

    const createCollectionSummary = (cols: string[]) => {
        const summaryRow = ["Total"];

        if (
            !CollectionData ||
            !CollectionData.data ||
            !CollectionData.data.rows ||
            !Array.isArray(CollectionData.data.rows)
        ) {
            return summaryRow;
        }

        let amount = 0;
        let adjustment = 0;

        for (const row of CollectionData?.data.rows) {
            console.log(row);
            amount +=
                row["Collection"] + row["PMCCollection"] + row["PaidSurcharge"];
            adjustment += row["Adjustment"] + row["surchargeAdj"];
        }

        for (const col of cols) {
            switch (col) {
                case "amount":
                    summaryRow.push(currencyFormatter.format(amount));
                    break;

                case "adjustment":
                    summaryRow.push(currencyFormatter.format(adjustment));
                    break;

                default:
                    summaryRow.push("");
            }
        }

        if (user.ops.includes("DELETE COLLECTION")) {
            summaryRow.push("");
        }

        console.log(summaryRow, "summaryRow");

        return summaryRow;
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    spacing={1.5}
                    justifyContent="space-between"
                >
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5" color="GrayText">
                            Collections
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        spacing={1.5}
                        justifyContent="flex-end"
                    >
                        <Grid item flex={1}></Grid>

                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize ops={["COLLECT BILL"]}>
                                <Link
                                    to="/bills/collect"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{ height: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                        fullWidth
                                    >
                                        Add Collection
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    spacing={1.5}
                    mt={0.5}
                    component="form"
                    onSubmit={handleApplyFilter}
                >
                    <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <SearchableInput
                                api="/region/district"
                                label="District"
                                multiple={false}
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                clearAll={clearAll.district}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <MultiPermissionAuthorize ops={["READ POWERSTATION"]}>
                        <Grid item xs={12} sm={6} md={3}>
                            <UpdatedSearchableInput
                                label="Powerstation"
                                api="/powerstation"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                dep={intermediateFilter.district}
                                params={{
                                    district: intermediateFilter.district,
                                }}
                                clearAll={clearAll.powerstation}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <SearchableInput
                                api="/region/village"
                                label="Village"
                                multiple={false}
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                dep={{
                                    district: intermediateFilter.district,
                                }}
                                clearAll={clearAll.village}
                                // required={!intermediateFilter.meterNo}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <SearchableInput
                                api="/region/hamlet"
                                label="Hamlet"
                                multiple={false}
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                dep={
                                    intermediateFilter.village &&
                                    intermediateFilter.village !== "all"
                                        ? {
                                              village: [
                                                  intermediateFilter.village,
                                              ],
                                          }
                                        : { village: [0] }
                                }
                                clearAll={clearAll.hamlet}
                                // required={!intermediateFilter.meterNo}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <MultiPermissionAuthorize ops={["READ METER"]}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <TextField
                                fullWidth
                                label="Meter No."
                                size="small"
                                type="text"
                                value={intermediateFilter.meterNo}
                                name="meterNo"
                                onChange={(e) =>
                                    setIntermediateFilter({
                                        ...intermediateFilter,
                                        meterNo: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <Grid item xs={12} sm={6} lg={3}>
                        <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="Star Date"
                            size="small"
                            type="date"
                            name="startDate"
                            value={intermediateFilter.startDate}
                            onChange={(e) =>
                                setIntermediateFilter({
                                    ...intermediateFilter,
                                    startDate: e.target.value,
                                })
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3}>
                        <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="End Date"
                            size="small"
                            type="date"
                            name="endDate"
                            value={intermediateFilter.endDate}
                            required={Boolean(intermediateFilter.startDate)}
                            onChange={(e) =>
                                setIntermediateFilter({
                                    ...intermediateFilter,
                                    endDate: e.target.value,
                                })
                            }
                        />
                    </Grid>

                    <MultiPermissionAuthorize ops={["READ PAYMENT_METHOD"]}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <SearchableInput
                                api="/reading/payment-method"
                                label="Payment Method"
                                multiple={false}
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                clearAll={clearAll.district}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <Grid item xs={12} sm={6} lg={3}>
                        <SearchBar
                            filter={intermediateFilter}
                            setFilter={setIntermediateFilter}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3}>
                        <Button
                            color="secondary"
                            variant="outlined"
                            type="submit"
                            sx={{
                                height: "100%",
                            }}
                            startIcon={<FilterAltOutlined />}
                            fullWidth
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Card>

            <div style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <Tabs
                    value={tabIndex}
                    onChange={(_, newIndex) => handleTabChange(newIndex)}
                    sx={{
                        "& .MuiTab-root": {
                            mx: 1,
                            transition: "0.3s",
                            "&:hover": {
                                color: theme.palette.secondary.main,
                            },
                            color: theme.palette.secondary.main,
                        },
                    }}
                >
                    <Tab label="Collections" tabIndex={0} />
                    <Tab label="Collection breakdown" tabIndex={2} />
                </Tabs>
            </div>

            {tabIndex == 0 && (
                <Box sx={{ height: "55%", paddingTop: "1rem" }}>
                    {!isRequiredFiltersSelected && (
                        <Alert severity="info">
                            Please select "District", "PowerStation" or "Meter
                            Number"
                        </Alert>
                    )}

                    {!isCollectionLoadingLoading &&
                        CollectionData?.data?.rows?.length === 0 &&
                        isRequiredFiltersSelected && (
                            <Alert severity="info">No data found.</Alert>
                        )}

                    <BaseTable
                        load={Boolean(changeCount)}
                        headers={{
                            meterId: "ID",
                            customerName: "Name",
                            fatherName: "fatherName",
                            cnic: "Cnic",
                            mobileNumber: "mobileNumber",
                            meterNo: "meterNo",
                            amount: "Amount",
                            districtName: "District",
                            districtId: "districtId",
                            villageId: "villageId",
                            villageName: "Village",
                            hamletName: "Hamlet",
                            hamletId: "hamletId",
                            adjustment: "Adjustment",
                        }}
                        defaultSelectedHeaders={[
                            "customerName",
                            "fatherName",
                            "cnic",
                            "mobileNumber",
                            "meterNo",
                            "amount",
                            "adjustment",
                            "districtName",
                            "villageName",
                            "hamletName",
                        ]}
                        data={CollectionData?.data.rows.map((row: any) => ({
                            ...row,
                            meterNo: `${row.meterNo}`,
                            amount: (
                                (row.Collection || 0) +
                                (row.PMCCollection || 0) +
                                (row.PaidSurcharge || 0)
                            ).toFixed(2),

                            adjustment: (
                                (row.Adjustment || 0) + (row.surchargeAdj || 0)
                            ).toFixed(2),
                        }))}
                        delEndPoint=""
                        feedback={feedback}
                        setFeedback={setFeedback}
                        rowsCount={overallPagination.count}
                        pagination={overallPagination}
                        setPagination={setOverAllPagination}
                        isLoading={isCollectionLoadingLoading}
                        hasReportTemplates
                        reportTemplates={reportTemplates}
                        permissions={{
                            edit: [""],
                            delete: [""],
                        }}
                        // load={filter.search}
                        queryKey={[
                            "collection",
                            pagination.page,
                            pagination.limit,
                            filter,
                        ]}
                        reportName="Collections"
                        summaryRow={createCollectionSummary}
                    />
                </Box>
            )}

            {tabIndex == 1 && (
                <Box
                    sx={{ height: "55%", paddingTop: "1rem" }}
                    key={"overallCollection"}
                >
                    {!isRequiredFiltersSelected && (
                        <Alert severity="info">
                            Please select "District", "Village", or "Meter
                            Number"
                        </Alert>
                    )}

                    <BaseTable
                        load={Boolean(changeCount)}
                        headers={{
                            id: "ID",
                            meter: "Meter No.",
                            billMonth: "Bill Month",
                            collectionDate: "Collection Date",
                            totalAmount: "Total Amount",
                            surchargeAmount: "SurchargeAmount",
                            amount: "Paid Amount",
                            adjustment: "Adjustment",
                            surchargePaid: "Surcharge Paid",
                            paymentMethod: "Payment Method",
                            description: "Description",
                            operator: "Operator",
                            details: "Details",
                        }}
                        defaultSelectedHeaders={[
                            "meter",
                            "billMonth",
                            "totalAmount",
                            "surchargeAmount",
                            "amount",
                            "adjustment",
                            "surchargePaid",
                            "collectionDate",
                            "description",
                            "operator",
                            "details",
                        ]}
                        data={data?.data.rows.map((row: any) => ({
                            ...row,
                            meter: row.reading?.meter?.meterNo,
                            billMonth: monthFormatter.format(
                                new Date(
                                    row.reading?.year ?? 0,
                                    row.reading?.month - 1 || 0,
                                    1
                                )
                            ),
                            collectionDate: dateFormatter.format(
                                new Date(row.collectionDate)
                            ),
                            paymentMethod: row.paymentMethod.name,
                            totalAmount: currencyFormatter.format(
                                row.reading?.totalAmount ?? 0
                            ),
                            surchargeAmount: currencyFormatter.format(
                                row.reading?.applicableSurcharge ?? 0
                            ),
                            surchargePaid: currencyFormatter.format(
                                row.reading?.payedSurcharge ?? 0
                            ),

                            details: (
                                <CollectionDetails
                                    amounts={row.amounts.split(",") ?? []}
                                    components={row.components.split(",") ?? []}
                                    adjs={row.adjs.split(",") ?? []}
                                />
                            ),

                            amount: currencyFormatter.format(
                                row.amounts
                                    .split(",")
                                    .map((x: string) => parseFloat(x))
                                    .reduce(
                                        (acc: number, x: number) => acc + x,
                                        0
                                    )
                            ),
                            adjustment: currencyFormatter.format(
                                row.adjs
                                    .split(",")
                                    .map((x: string) => parseFloat(x))
                                    .reduce(
                                        (acc: number, x: number) => acc + x,
                                        0
                                    ) + row?.reading?.surchargeAdj
                            ),
                            operator: row?.operator?.name,
                        }))}
                        reportName="Collection BreakDown"
                        delEndPoint="/reading/collection/many"
                        deletionProp="colls"
                        feedback={feedback}
                        setFeedback={setFeedback}
                        rowsCount={pagination.count}
                        pagination={pagination}
                        setPagination={setPagination}
                        isLoading={isLoading}
                        reportDetails={selectedFilters}
                        permissions={{
                            edit: [""],
                            delete: ["DELETE COLLECTION"],
                        }}
                        queryKey={[
                            "collection",
                            pagination.page,
                            pagination.limit,
                            filter,
                        ]}
                        hasReportTemplates
                        reportTemplates={reportTemplatesOfBreakDown}
                        summaryRow={createSummary}
                    />
                </Box>
            )}
        </Box>
    );
};

export default Collection;
