import { Add } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    useTheme,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { FormEvent, useEffect, useState } from "react";
import { SlipService } from "../../../Services/SlipService";
import { GET } from "../../../Utilities/BaseService";
import SlipCustomFields from "../SlipCustomFields";

type Props = { updateMode?: boolean };

type Customer = {
    name: string;
    fatherName: string;
    cnic: string;
    mobileNo: string;
    [key: string]: string;
};
type Property = { id: number; customer: Customer };
type Region = { id: number; name: string };
type Meter = {
    id: number;
    createdAt: string;
    status: "active" | "inactive" | "replaced";
    initialReading: number;
    lineRentEditable: boolean;
    connectionCharges: number;
    connectionChargesApplied: boolean;
    connectionDate: string;
    credit: number;
    prevMeter: Meter | null;
    district: Region;
    village: Region;
    hamlet: Region;
    powerstation: Region;
    meterNo: string;
    property: Property;
};

type ConnectionData = { meter: Meter | null };

export type SlipField = { key: string; label: string; checked: boolean };

const initialConnectionData: ConnectionData = { meter: null };

const initialCustomFields: { [key: string]: SlipField[] } = {
    customer: [
        { key: "name", label: "Name", checked: true },
        { key: "fatherName", label: "Father Name", checked: false },
        { key: "cnic", label: "CNIC", checked: false },
        { key: "mobileNo", label: "Mobile No", checked: false },
    ],

    meter: [
        { key: "meterNo", label: "Meter No.", checked: false },
        { key: "connectionDate", label: "Connection Date", checked: false },
        { key: "connectionCharges", label: "ConnectionCharges", checked: true },
        { key: "status", label: "Status", checked: false },
        { key: "distName", label: "District", checked: false },
        { key: "villName", label: "Village", checked: false },
        { key: "hamName", label: "Hamlet", checked: false },
        { key: "pstName", label: "Powerstation", checked: false },
    ],
};

const AddConnectionSlip = ({ updateMode = false }: Props) => {
    const [connectionData, setConnectionData] = useState<any>();
    const [search, setSearch] = useState(false);
    const [meterNo, setMeterNo] = useState("");
    const [customFields, setCustomFields] = useState(initialCustomFields);
    const [slipData, setSlipData] = useState("");
    const [feedback, setFeedback] = useState<any>("");

    const theme = useTheme();

    const addSlips = useMutation(SlipService.getInstance().addSlip, {
        onSuccess(res: any) {
            setFeedback(res.data.message);
        },

        onError(error: { message: string }) {
            setFeedback(error.message);
        },
    });

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        addSlips.mutate({
            target: connectionData.meterNo!,
            type: "connection",
            data: slipData,
        });
    };

    const fetchMeterData = async () => {
        try {
            setFeedback({ show: false, message: "", severity: "" }); // Reset feedback

            const res = await GET("/customer/meter", {
                page: 1,
                limit: 1,
                search: meterNo,
            });

            if (res.data.rows.length > 0) {
                setConnectionData(res.data.rows[0]);
                setFeedback({
                    show: true,
                    message: "Consumer found successfully",
                    severity: "success",
                });
            } else {
                setConnectionData(null); // Clear previous data
                setFeedback({
                    show: true,
                    message: "No consumer found",
                    severity: "error",
                });
            }
        } catch (error) {
            setConnectionData(null); // Clear previous data
            setFeedback({
                show: true,
                message: "Error fetching meter data",
                severity: "error",
            });
        }
    };

    useEffect(() => {
        if (connectionData?.id as any) {
            const customerInfo = Object.values(customFields["customer"])
                .filter((cf) => cf.checked)
                .reduce(
                    (prev, curr) => ({
                        ...prev,
                        [curr.label]:
                            connectionData?.property?.customer[curr.key],
                    }),
                    {}
                );
            const meterInfo = Object.values(customFields["meter"])
                .filter((cf) => cf.checked)
                .reduce(
                    (prev, curr) => ({
                        ...prev,
                        [curr.label]: connectionData?.id
                            ? connectionData[curr.key]
                            : undefined,
                    }),
                    {}
                );

            console.log(meterInfo, customerInfo);

            setSlipData(() =>
                JSON.stringify({ ...customerInfo, ...meterInfo })
            );
        }
    }, [customFields, connectionData?.id]);

    useEffect(() => {
        if (meterNo) fetchMeterData();
    }, [search]);

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title="Generate Connection Slip"
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />

                {feedback.show && (
                    <Box>
                        <Alert
                            severity={feedback.severity}
                            variant="outlined"
                            sx={(theme) => ({ mx: theme.spacing(2) })}
                        >
                            {feedback.message}
                        </Alert>

                        {connectionData && (
                            <div>
                                <Table
                                    size="small"
                                    padding="none"
                                    sx={(theme) => ({
                                        mt: theme.spacing(2),
                                        mx: theme.spacing(2),
                                    })}
                                >
                                    <TableBody>
                                        {[
                                            {
                                                label: "Name",
                                                value:
                                                    connectionData?.property
                                                        ?.customer?.name ?? "",
                                            },

                                            {
                                                label: "Father Name",
                                                value:
                                                    connectionData?.property
                                                        ?.customer
                                                        ?.fatherName ?? "",
                                            },

                                            {
                                                label: "Meter No",
                                                value:
                                                    connectionData?.meterNo ??
                                                    "",
                                            },

                                            {
                                                label: "District",
                                                value:
                                                    connectionData?.district
                                                        .name ?? "",
                                            },
                                            {
                                                label: "Village",
                                                value:
                                                    connectionData?.village
                                                        .name ?? "",
                                            },
                                            {
                                                label: "Hamlet",
                                                value:
                                                    connectionData?.hamlet
                                                        .name ?? "",
                                            },
                                        ].map((r, index) => (
                                            <TableRow key={`ci-${index}`}>
                                                <TableCell>
                                                    {r?.label}
                                                </TableCell>
                                                <TableCell>
                                                    {r?.value}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        )}
                    </Box>
                )}

                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={2}
                    >
                        <Grid item xs={12} md={4}>
                            <TextField
                                label={"Search by Meter / Customer Name"}
                                value={meterNo}
                                onChange={(ev: any) => {
                                    setMeterNo(ev.target.value);
                                }}
                                fullWidth
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => {
                                    setSearch((prev) => !prev); // Toggle search state
                                }}
                                sx={{ height: "100%" }}
                                size="small"
                                fullWidth
                            >
                                Search
                            </Button>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Grid item xs={12}>
                                {meterNo && meterNo !== ("all" as any) && (
                                    <SlipCustomFields
                                        fields={customFields}
                                        setFields={setCustomFields}
                                        categories={{
                                            customer: "Customer Info",
                                            meter: "Meter Info",
                                        }}
                                    />
                                )}
                            </Grid>

                            <Grid item sx={{ mt: "1rem" }}>
                                <Button
                                    startIcon={<Add />}
                                    endIcon={
                                        addSlips.isLoading ? (
                                            <CircularProgress
                                                color="secondary"
                                                size="1rem"
                                            />
                                        ) : (
                                            ""
                                        )
                                    }
                                    color="secondary"
                                    variant="outlined"
                                    sx={{ height: "100%" }}
                                    type="submit"
                                    disabled={addSlips.isLoading}
                                >
                                    {addSlips.isLoading
                                        ? "generating slip..."
                                        : "generate slips"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addSlips.isSuccess /* || updateMutation.isSuccess */ ? (
                <Alert severity="success">{feedback}</Alert>
            ) : addSlips.isError /* || updateMutation.isError */ ? (
                <Alert severity="error">{feedback}</Alert>
            ) : null}
        </>
    );
};

export default AddConnectionSlip;
