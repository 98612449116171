import { GetApp, InfoOutlined, UploadFile } from "@mui/icons-material";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Collapse,
    IconButton,
    LinearProgress,
    Typography,
} from "@mui/material";
import { useState } from "react";
import * as XLSX from "xlsx";
import { DataImportService } from "../../../Services/DataImportService";

const readExcelFileAndCreateCollections = async (
    bin: string | ArrayBuffer | null | undefined
): Promise<any[]> => {
    console.log("in readExcelFileAndCreateCollections");
    const wb = XLSX.read(bin, { type: "binary" });

    const sheets = wb.Sheets;

    let collectionFailures: any[] = [],
        collectionSuccesses: any[] = [];

    for (const sheet of Object.values(sheets)) {
        // Convert sheet to JSON and format dates
        const json = XLSX.utils.sheet_to_json(sheet, {
            raw: false, // Ensures dates are parsed as strings
            dateNF: "yyyy-mm-dd", // Format for dates
        });

        const parsedJson = json.map((row: any) => {
            // Convert numeric dates to ISO strings if necessary
            if (row.collectionDate && !isNaN(row.collectionDate)) {
                row.collectionDate = XLSX.SSF.format(
                    "yyyy-mm-dd",
                    row.collectionDate
                );
            }
            return row;
        });

        const [data, err] = await DataImportService.createReadingCollections(
            parsedJson
        );

        console.log("data", data, "error", err);

        if (data) {
            collectionSuccesses = data.successes;
            collectionFailures = data.failures;
        } else {
            collectionSuccesses = err.successes;
            collectionFailures = err.failures;
        }
    }

    return [collectionSuccesses, collectionFailures];
};

export const DataImport = () => {
    // const classes = useStyles();
    const [loading, setLoading] = useState<"loading" | "initial" | "finished">(
        "initial"
    );
    const [fname, setFname] = useState("");

    const [successes, setSucesses] = useState<any>([]);
    const [failures, setFailures] = useState<any>([]);
    const [showResponseDetails, setShowResponseDetails] = useState(false);
    const [file, setFile] = useState<File | null>(null);

    const handleFileUpload = async () => {
        // const file = ev.target.files && ev.target.files[0];
        file && setFname(file.name);
        const reader = new FileReader();

        if (file) {
            setLoading(() => "loading");

            reader.onload = async (evt) => {
                const binary = evt.target?.result;

                console.log("before readExcelFileAndCreateCollections call");

                const [_successes, _failures] =
                    await readExcelFileAndCreateCollections(binary);

                console.log("_successes", _successes, "_failures", _failures);

                setFailures(_failures);
                setSucesses(_successes);

                setLoading("finished");
            };

            reader.readAsBinaryString(file);
        }

        // setLoading("finished");
    };

    return (
        <Card>
            <CardContent>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography variant="h5" fontWeight="bold">
                        Import Reading Collections
                    </Typography>

                    <Box display={"flex"} gap={1}>
                        <Button
                            variant="outlined"
                            startIcon={<GetApp />}
                            href="/template/CollectionTemplate.xlsx"
                            download
                            color="secondary"
                        >
                            download template
                        </Button>

                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={loading === "loading"}
                            startIcon={
                                loading === "loading" ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <UploadFile />
                                )
                            }
                            onClick={() => {
                                file && handleFileUpload();
                            }}
                        >
                            Upload File
                        </Button>
                    </Box>
                </Box>

                <div>
                    <div>
                        {/* <Button
                            variant="contained"
                            component="label"
                            sx={{ mt: "1rem" }}
                            color="secondary"
                        >
                             <input
                                // onChange={handleFileUpload}
                                // hidden
                                type="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                             Choose File
                        </Button>  
                         <Typography
                            variant="caption"
                            style={{ fontWeight: "bold" }}
                            sx={{ display: "flex", alignItems: "center" }}
                            mt={"10px"}
                        >
                            {fname}
                        </Typography> */}

                        <input
                            // onChange={handleFileUpload}
                            onChange={(e) => {
                                setFile(
                                    e.target.files?.length
                                        ? e.target.files[0]
                                        : null
                                );
                            }}
                            // hidden
                            type="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        />
                    </div>

                    <Typography variant="body1" gutterBottom mt={"10px"}>
                        Ensure the file format is <strong>.xlsx</strong>. The
                        column names must be:
                        <em>
                            amount, paymentMethod, meterNo, collectionDate,
                            description, adjustment
                        </em>
                        .
                        <br />
                        <br />
                        Note: <strong>collectionDate</strong> must be in ISO
                        format (<em>YYYY-MM-DD</em>), such as{" "}
                        <strong>"2025-02-28"</strong>. Incorrect date formats
                        may result in processing errors.
                        <br />
                        <br />
                        Note: <strong>paymentMethod</strong> must be an ID, such
                        as <strong>1</strong>. Incorrect paymentMethod ID may
                        result in processing errors.
                        <br />
                        <br />
                        Hint: Go to the sidebar, then click on "Payments". Once
                        in the "Payments" section, click on "Payment Method".
                        This will display a list of payment methods, where the
                        ID is shown. Copy that ID and add it to your Excel file
                        under the paymentMethod column.
                    </Typography>

                    {loading === "loading" && <LinearProgress />}

                    <section hidden={loading !== "finished"}>
                        <Alert
                            severity="info"
                            action={
                                <IconButton
                                    onClick={() => {
                                        setShowResponseDetails(
                                            !showResponseDetails
                                        );
                                    }}
                                >
                                    <InfoOutlined fontSize="small" />
                                </IconButton>
                            }
                        >
                            <AlertTitle>
                                {`${successes.length} successes out of ${
                                    (successes.length ?? 0) +
                                    (failures.length ?? 0)
                                } `}
                            </AlertTitle>

                            <Collapse in={showResponseDetails}>
                                {successes?.map(
                                    (
                                        suc: {
                                            message: string;
                                            meterNo: number | string;
                                        },
                                        index: number
                                    ) => (
                                        <Box key={index}>
                                            {`Meter No - (${suc.meterNo}) - ${suc.message}`}
                                        </Box>
                                    )
                                )}

                                {failures.map(
                                    (
                                        err: {
                                            message: string;
                                            meterNo: number | string;
                                        },
                                        index: number
                                    ) => (
                                        <Box key={index}>
                                            {`Meter No - (${err.meterNo}) - ${err.message}`}
                                        </Box>
                                    )
                                )}
                            </Collapse>
                        </Alert>
                    </section>
                </div>
            </CardContent>
        </Card>
    );
};
export default DataImport;
