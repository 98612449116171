import {
    Add,
    FilterAltOutlined,
    PublicOutlined,
    TuneOutlined,
} from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    Checkbox,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../Utilities/AppContext";
import { GET } from "../../Utilities/BaseService";
import {
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../Utilities/constants";
import { MultiPermissionAuthorize } from "../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../Utilities/UpdatedSearchableInput";
import BaseTable from "../Table/BaseTable";
import SearchBar from "../Table/Components/SearchBar";
import { UserRegionsTree } from "./UserRegionsTree";

type Filter = {
    search: string;
    district: number | "" | "all";
    village: number | "" | "all";
    hamlet: number | "" | "all";
    powerstation: number | "" | "all";
};

const initialFilter: Filter = {
    district: "",
    village: "",
    hamlet: "",
    search: "",
    powerstation: "",
};

type Region = { id: number; name: string };

type Regions = {
    district: Region;
    village: Region | null;
    hamlet: Region | null;
    powerstation: Region | null;
};

const initialClearAll = {
    district: false,
    village: false,
    hamlet: false,
    powerstation: false,
};

const Users = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [filter, setFilter] = useState(initialFilter);
    const [feedback, setFeedback] = useState("");
    const { user } = useContext(AppContext);
    const [activeUser, setActiveUser] = useState<null | number>(null);
    const [changeCount, setChangeCount] = useState(0);
    const [intermediateFilter, setIntermediateFilter] =
        useState<Filter>(initialFilter);
    const [allRegionAccess, setAllRegionAccess] = useState<any>(false);
    const [clearAll, setClearAll] = useState(initialClearAll);
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));

    const getUsers = () => {
        return GET("/user", {
            page: pagination.page + 1,
            limit: pagination.limit,
            allRegionAccess: allRegionAccess === true ? 1 : undefined,
            ...Object.fromEntries(
                Object.entries(filter).filter(
                    (entry) => entry[1] !== "all" && entry[1] !== ""
                )
            ),
        });
    };

    const { data, isLoading, isError, isSuccess } = useQuery(
        [
            "users",
            pagination.page,
            pagination.limit,
            filter,
            allRegionAccess,
            filter.search,
        ],
        getUsers,

        {
            enabled: Boolean(changeCount),
            onSuccess(res) {
                if (res) setRowsCount(res?.data.count);
            },
        }
    );

    const activeUserEntity = useMemo(() => {
        if (activeUser) {
            return data?.data.rows.find((r: any) => r.id == activeUser);
        } else return null;
    }, [activeUser]);

    useEffect(() => {
        if (intermediateFilter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });
            setIntermediateFilter(initialFilter);
        }

        if (intermediateFilter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
            setIntermediateFilter({
                ...intermediateFilter,
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
            setIntermediateFilter({ ...intermediateFilter, hamlet: "" });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
                powerstation: true,
            });
        };
    }, [
        intermediateFilter.district,
        intermediateFilter.village,
        intermediateFilter.hamlet,
    ]);

    useEffect(() => {
        if (changeCount) {
            setFilter({ ...filter, ...intermediateFilter });
        }
    }, [changeCount]);

    const getGroupedRegions = useMemo(() => {
        if (activeUser) {
            const activeUserRegions: Regions[] =
                data?.data.rows.find((r: any) => r.id == activeUser).regions ||
                [];

            const targetRegions = activeUserRegions.reduce(
                (result: any, item) => {
                    const { district, village, hamlet } = item;

                    // Group by district
                    const districtKey = district.name;
                    if (!result[districtKey]) {
                        result[districtKey] = {
                            villages: {},
                        };
                    }

                    // Group by village
                    if (village) {
                        const villageKey = village.name;
                        if (!result[districtKey].villages[villageKey]) {
                            result[districtKey].villages[villageKey] = {
                                hamlets: [],
                            };
                        }

                        // Group by hamlet
                        if (hamlet) {
                            result[districtKey].villages[
                                villageKey
                            ].hamlets.push(hamlet.name);
                        }
                    }

                    return result;
                },
                {}
            );

            return targetRegions;
        }
    }, [activeUser]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item xs={12} sm="auto">
                        <Typography variant="h5" color="GrayText">
                            Users
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        container
                        justifyContent="flex-end"
                        spacing={1.5}
                        xs={12}
                        md={6}
                    >
                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize ops={["CREATE USER"]}>
                                <Link
                                    to="/users/add"
                                    style={{
                                        textDecoration: "none",
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                        fullWidth
                                        sx={{ height: "100%" }}
                                    >
                                        add user
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={1.5}>
                        <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/district"
                                    label="District"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    clearAll={clearAll.district}
                                    disabled={allRegionAccess === true}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/village"
                                    label="Village"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.district}
                                    params={{
                                        otherOps: JSON.stringify([
                                            {
                                                op: "in",
                                                operands: [
                                                    [
                                                        intermediateFilter.district
                                                            ? intermediateFilter.district
                                                            : "",
                                                    ],
                                                ],
                                                isDate: false,
                                                col: "district",
                                            },
                                        ]),
                                    }}
                                    clearAll={clearAll.village}
                                    disabled={allRegionAccess === true}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/hamlet"
                                    label="Hamlet"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.village}
                                    params={
                                        intermediateFilter.village &&
                                        intermediateFilter.village !== "all"
                                            ? {
                                                  village: [
                                                      intermediateFilter.village,
                                                  ],
                                              }
                                            : { village: [0] }
                                    }
                                    clearAll={clearAll.hamlet}
                                    disabled={allRegionAccess === true}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ POWERSTATION"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/powerstation"
                                    label="Powerstation"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.district}
                                    params={{
                                        otherOps: JSON.stringify([
                                            {
                                                op: "in",
                                                operands: [
                                                    [
                                                        intermediateFilter.district,
                                                    ],
                                                ],
                                                isDate: false,
                                                col: "district",
                                            },
                                        ]),
                                    }}
                                    clearAll={clearAll.powerstation}
                                    disabled={allRegionAccess === true}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                api="/user/status"
                                label="Status"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                optionsPreprocessor={(opt: any) => ({
                                    name: opt.label,
                                    id: opt.type,
                                })}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <SearchBar
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setChangeCount(changeCount + 1)}
                                sx={{ height: "100%" }}
                                startIcon={<FilterAltOutlined />}
                                fullWidth
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md="auto">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={allRegionAccess}
                                    onChange={(ev, checked) => {
                                        setAllRegionAccess(checked);
                                        setFilter({
                                            search: "",
                                            district: "",
                                            village: "",
                                            hamlet: "",
                                            powerstation: "",
                                        });
                                    }}
                                />
                            }
                            label="Users with all regions."
                        />
                    </Grid>
                </Grid>
            </Card>

            {(!changeCount || !data?.data.rows.length || isError) && (
                <Alert severity="info">
                    {!data?.data.rows.length && isSuccess
                        ? noDataMessage
                        : !hasValue(filter) && !changeCount
                        ? filterSelectionMessage
                        : ""}
                </Alert>
            )}

            <Box sx={{ height: "auto" }}>
                <BaseTable
                    headers={{
                        id: "ID",
                        name: "Name",
                        roleName: "Role",
                        roleDescription: "Role description",
                        status: "Status",
                        ...(user?.ops.includes("READ USER_CREDENTIALS")
                            ? { username: "Username", password: "Password" }
                            : {}),
                        constraints: "Constraints",
                        regions: "Regions",
                    }}
                    data={data?.data.rows.map((row: any) => ({
                        ...row,
                        roleName: row.role.name,
                        roleDescription: row.role.description,
                        status: (
                            <Chip
                                sx={{ minWidth: "65px" }}
                                size="small"
                                variant="outlined"
                                label={
                                    row.status === "active"
                                        ? "Active"
                                        : "Inactive"
                                }
                                color={
                                    row.status === "active"
                                        ? "success"
                                        : "error"
                                }
                            />
                        ),
                        constraints: (
                            <Constraints constraints={row.constraints} />
                        ),

                        regions: (
                            <IconButton
                                size="small"
                                onClick={() => setActiveUser(row.id)}
                                color={
                                    row.regions.length === 0 &&
                                    !row.allRegionAccess
                                        ? "error"
                                        : "success"
                                }
                            >
                                <PublicOutlined fontSize="medium" />
                            </IconButton>
                        ),
                    }))}
                    reportName="Users List"
                    defaultSelectedHeaders={[
                        "id",
                        "name",
                        "roleName",
                        "status",
                        "regions",
                        "constraints",
                    ]}
                    _printables={[
                        "id",
                        "name",
                        "roleName",
                        "roleDescription",
                        "username",
                        "password",
                        "status",
                    ]}
                    delEndPoint="/user"
                    feedback={feedback}
                    setFeedback={setFeedback}
                    rowsCount={rowsCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    isLoading={isLoading}
                    load={Boolean(changeCount)}
                    permissions={{
                        edit: ["UPDATE USER"],
                        delete: ["DELETE USER"],
                    }}
                    queryKey={[
                        "users",
                        pagination.page,
                        pagination.limit,
                        filter,
                        filter.search,
                        allRegionAccess,
                    ]}
                />

                <Dialog
                    open={Boolean(activeUser)}
                    onClose={() => setActiveUser(null)}
                    fullWidth
                >
                    <DialogTitle>User regions</DialogTitle>
                    <DialogContent>
                        {activeUserEntity?.regions.length === 0 &&
                            !activeUserEntity?.allRegionAccess && (
                                <Typography>
                                    User has access to no regions
                                </Typography>
                            )}

                        {activeUserEntity?.allRegionAccess ? (
                            <Typography>
                                User has access to all regions
                            </Typography>
                        ) : (
                            <UserRegionsTree regions={getGroupedRegions} />
                        )}
                    </DialogContent>
                </Dialog>
            </Box>
        </Box>
    );
};

export default Users;

type ConstraintVariant =
    | "collection-back"
    | "collection-forward"
    | "reading-back"
    | "reading-forward"
    | "connection-back"
    | "connection-forward";

type DateConstraintProps = {
    constraints: { id: number; key: ConstraintVariant; value: string }[];
};

const Constraints = ({ constraints }: DateConstraintProps) => {
    const [open, setOpen] = useState(false);

    const constraintsLabels = {
        "collection-back": "Collection Back Date Entry",
        "collection-forward": "Collection Forward Date Entry",

        "reading-back": "Collection Back Date Entry",
        "reading-forward": "Collection Back Date Entry",

        "connection-back": "Connection Back Date Entry",
        "connection-forward": "Connection Back Date Entry",
    };

    return (
        <Box>
            <IconButton size="small" onClick={() => setOpen(true)}>
                <TuneOutlined fontSize="small" />
            </IconButton>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Constraints</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {constraints.map((c) => (
                                <TableRow key={c.id}>
                                    <TableCell>
                                        {constraintsLabels[c.key]}
                                    </TableCell>

                                    <TableCell>{c.value}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        </Box>
    );
};
