import { Error } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <div
                    style={{
                        width: "100%",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                    }}
                >
                    <div
                        style={{
                            width: "6rem",
                            height: "6rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            backgroundColor: "white",
                            padding: "10px",
                        }}
                    >
                        <Error
                            style={{
                                color: "red",
                                fontSize: "5rem",
                            }}
                        />
                    </div>
                    <Typography variant="h6" style={{ marginTop: "10px" }}>
                        Uh-oh! It seems we've encountered a hiccup. Please bear
                        with us as we work to resolve the issue and get back to
                        smooth sailing!
                    </Typography>

                    <Button variant="contained" sx={{ mt: "1rem" }} href="/">
                        Go Back To Home
                    </Button>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
