import { Close } from "@mui/icons-material";
import { Button, Grid, IconButton, MenuItem, TextField } from "@mui/material";
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from "react";

type Props = {
    filter: any;
    setFilter: Dispatch<SetStateAction<any>>;
    customerDetails: any;
    setCustomerDetails: Dispatch<SetStateAction<any>>;
    selectedCustomerDetails: any[];
    setSelectedCustomerDetails: Dispatch<SetStateAction<any>>;
    reportDetails: any;
    setReportDetails: Dispatch<SetStateAction<any>>;
    selectedReportDetails: any;
    setSelectedReportDetails: Dispatch<SetStateAction<any>>;
};

const initialFilter = {
    // district: "",
    // village: "",
    // hamlet: "",
    meterNo: "",
};

const ReportsFilter = ({
    filter,
    setFilter,
    customerDetails,
    selectedCustomerDetails,
    setSelectedCustomerDetails,
    reportDetails,
    selectedReportDetails,
    setSelectedReportDetails,
}: Props) => {
    console.log(customerDetails);
    const [customerFilter, setCustomerFilter] = useState(initialFilter);

    // const [clearAll, setClearAll] = useState({
    //     district: false,
    //     village: false,
    //     hamlet: false,
    // });
    const [date, setDate] = useState({ start: "", end: "", refrence: "" });
    const [applyFilterCount, setApplyFilterCount] = useState(0);

    const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        setDate({ ...date, [e.target.name]: value });
    };

    useEffect(() => {
        setFilter((curr: any) => ({
            ...curr,
            customerFilter,
            dtOpts: {
                start: date.start.split("-").reverse().join(","),
                end: date.end.split("-").reverse().join(","),
                refrence: date.refrence,
            },
        }));
    }, [applyFilterCount]);

    // useEffect(() => {
    //     if (customerFilter.district === "all") {
    //         setClearAll({ district: true, village: true, hamlet: true });
    //         setCustomerFilter(initialFilter);
    //     }

    //     if (customerFilter.village === "all") {
    //         setClearAll({ ...clearAll, village: true, hamlet: true });
    //         setCustomerFilter({ ...customerFilter, village: "", hamlet: "" });
    //     }

    //     if (customerFilter.hamlet === "all") {
    //         setClearAll({ ...clearAll, hamlet: true });
    //         setCustomerFilter({ ...customerFilter, hamlet: "" });
    //     }

    //     return () => {
    //         setClearAll({ district: false, village: false, hamlet: false });
    //     };
    // }, [
    //     customerFilter.district,
    //     customerFilter.village,
    //     customerFilter.hamlet,
    // ]);

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} spacing={2}>
                {/* <Grid item xs={12} sm={6} lg={3}>
                    <UpdatedSearchableInput
                        label="District"
                        api="/region/district"
                        filter={customerFilter}
                        setFilter={setCustomerFilter}
                        multiple={true}
                        clearAll={clearAll.district}
                    />
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                    <UpdatedSearchableInput
                        label="Village"
                        api="/region/village"
                        filter={customerFilter}
                        setFilter={setCustomerFilter}
                        dep={customerFilter.district}
                        params={{
                            otherOps:
                                customerFilter.district &&
                                customerFilter.district !== "all"
                                    ? JSON.stringify([
                                          {
                                              op: "in",
                                              operands: [
                                                  [customerFilter.district],
                                              ],
                                              isDate: false,
                                              col: "district",
                                          },
                                      ])
                                    : undefined,
                        }}
                        multiple={true}
                        clearAll={clearAll.village}
                    />
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                    <UpdatedSearchableInput
                        label="hamlet"
                        api="/region/hamlet"
                        filter={customerFilter}
                        setFilter={setCustomerFilter}
                        dep={customerFilter.village}
                        multiple={true}
                        params={{
                            otherOps:
                                customerFilter.village &&
                                customerFilter.village !== "all"
                                    ? JSON.stringify([
                                          {
                                              op: "in",
                                              operands: [
                                                  [customerFilter.village],
                                              ],
                                              isDate: false,
                                              col: "village",
                                          },
                                      ])
                                    : undefined,
                        }}
                        clearAll={clearAll.hamlet}
                    />
                </Grid> */}
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
                <TextField
                    label="From Date"
                    type="month"
                    size="small"
                    name="start"
                    value={date.start}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleDateChange}
                    fullWidth
                    InputProps={{
                        endAdornment:
                            date.start.length > 0 ? (
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDate({ ...date, start: "" });
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            ) : null,
                    }}
                />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
                <TextField
                    label="To Date"
                    type="month"
                    size="small"
                    name="end"
                    value={date.end}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleDateChange}
                    fullWidth
                    InputProps={{
                        endAdornment:
                            date.end.length > 0 ? (
                                <IconButton
                                    size="small"
                                    onClick={() =>
                                        setDate({ ...date, end: "" })
                                    }
                                >
                                    <Close />
                                </IconButton>
                            ) : null,
                    }}
                />
            </Grid>

            {/*  <Grid item xs={12} md={6} lg={3}>
                <TextField
                    label="Refrence Date"
                    type="date"
                    size="small"
                    name="refrence"
                    value={date.refrence}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleDateChange}
                    fullWidth
                    InputProps={{
                        endAdornment:
                            date.to.length > 0 ? (
                                <IconButton
                                    size="small"
                                    onClick={() => setDate({ ...date, to: "" })}
                                >
                                    <Close />
                                </IconButton>
                            ) : null,
                    }}
                />
            </Grid> */}

            <Grid item xs={12} md={6} lg={3}>
                <TextField
                    label="Report Details"
                    size="small"
                    inputProps={{ multiple: true }}
                    value={selectedReportDetails}
                    fullWidth
                    select
                    onChange={(e) =>
                        setSelectedReportDetails(e.target.value as any)
                    }
                >
                    {Object.entries(reportDetails)?.map(([k, v]: any) => (
                        <MenuItem key={k} value={k}>
                            {v}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
                <TextField
                    label="Customer Details"
                    size="small"
                    inputProps={{ multiple: true }}
                    value={selectedCustomerDetails}
                    fullWidth
                    select
                    onChange={(e) =>
                        setSelectedCustomerDetails(e.target.value as any)
                    }
                >
                    {Object.entries(customerDetails)?.map(([k, v]: any) => (
                        <MenuItem key={k} value={k}>
                            {v}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
                <TextField
                    label="Meter No."
                    size="small"
                    name="meterNo"
                    value={customerFilter.meterNo}
                    onChange={(e) =>
                        setCustomerFilter({
                            ...customerFilter,
                            meterNo: e.target.value,
                        })
                    }
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
                <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                        console.log("Counter", applyFilterCount);
                        setApplyFilterCount((n) => n + 1);
                    }}
                >
                    search
                </Button>
            </Grid>
        </Grid>
    );
};

export default ReportsFilter;
