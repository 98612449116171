import { Add, FilterAltOutlined, InfoOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET } from "../../../Utilities/BaseService";
import {
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../../Utilities/constants";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { getSelectedFilters } from "../../Customer/Property/Property";
import { currencyFormatter } from "../../Reading/Bill/Cells/Last12Summary";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";
import PaymentBreakDown from "./PaymentBreakDown";

type Filter = {
    search: string;
    targetType: "" | "customer" | "property" | "meter" | "all";
    payment: number | "" | "all";
    head: number | "" | "all";
    district: number | "" | "all";
    village: number | "" | "all";
    hamlet: number | "" | "all";
    powerstation: number | "" | "all";
    amount: number | "";
    id: number | "";
};

const initialFilter: Filter = {
    search: "",
    targetType: "",
    payment: "",
    district: "",
    village: "",
    hamlet: "",
    powerstation: "",
    amount: "",
    id: "",
    head: "",
};

const initialClear = {
    district: false,
    village: false,
    hamlet: false,
    powerstation: false,
};

const getCollections = (pagination: any, filter: any) => {
    return GET("/payment/collection", {
        page: pagination.page + 1,
        limit: pagination.limit,
        ...Object.fromEntries(
            Object.entries(filter).filter(([k, v]) => v && v !== "all")
        ),
    });
};

export const PaymentCollection = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 10 });
    const [filter, setFilter] = useState(initialFilter);
    const [intermediateFilter, setIntermediateFilter] = useState(initialFilter);
    const [changeCount, setChangeCount] = useState(0);
    const [feedback, setFeedback] = useState("");
    const [isBreakDownOpen, setIsBreakDownOpen] = useState<any>({ 0: false });
    const [load, setLoad] = useState(false);
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));
    const [clearAll, setClearAll] = useState(initialClear);
    const [selectedFilters, setSelectedFilters] = useState({});

    const {
        data: collections,
        isLoading,
        isError,
        isSuccess,
    } = useQuery(
        ["collections", { ...pagination, ...filter }],
        () => getCollections(pagination, filter),
        {
            enabled: Boolean(changeCount),
            onSuccess(res) {
                if (res.data) {
                    setRowsCount(res.data.count);

                    const _selectedFills = getSelectedFilters(
                        res.data.rows[0],
                        ["district", "village", "hamlet", "powerstation"],
                        filter,
                        {
                            district: {
                                label: "District",
                                valueKey: "name",
                            },
                            village: { label: "Village", valueKey: "name" },
                            hamlet: { label: "Hamlet", valueKey: "name" },
                            powerstation: {
                                label: "Power Station",
                                valueKey: "name",
                            },
                        }
                    );

                    setSelectedFilters(() => _selectedFills);
                }
            },
        }
    );

    useEffect(() => {
        if (changeCount) {
            setFilter({ ...filter, ...intermediateFilter });
        }
    }, [changeCount]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}
                >
                    <Grid item xs={12} md="auto">
                        <Typography variant="h5" color="GrayText">
                            Collections
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} container spacing={1.5}>
                        <Grid item flex={1}></Grid>

                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize ops={["CREATE DISTRICT"]}>
                                <Link
                                    to="/payment/collection/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{
                                            minWidth: "25ch",
                                            height: "100%",
                                        }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                    >
                                        add collection
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} spacing={1.5}>
                        <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/district"
                                    label="District"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    clearAll={clearAll.district}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/village"
                                    label="Village"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.district}
                                    params={{
                                        district: intermediateFilter.district,
                                    }}
                                    clearAll={clearAll.village}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/hamlet"
                                    label="Hamlet"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.village}
                                    params={
                                        intermediateFilter.village &&
                                        intermediateFilter.village !== "all"
                                            ? {
                                                  village: [
                                                      intermediateFilter.village,
                                                  ],
                                              }
                                            : { village: [0] }
                                    }
                                    clearAll={clearAll.hamlet}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ POWERSTATION"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/powerstation"
                                    label="Powerstation"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.district}
                                    params={{
                                        district: intermediateFilter.district,
                                    }}
                                    clearAll={clearAll.powerstation}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                api="/payment/head/targetType"
                                label="Target Type"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                _name="targetType"
                                optionsFilter={(opts: any[]) =>
                                    opts.filter(
                                        (opt: any) => opt.type !== "reading"
                                    )
                                }
                                optionsPreprocessor={(opt: any) => ({
                                    ...opt,
                                    id: opt.type,
                                    name: opt.label,
                                })}
                            />
                        </Grid>

                        <MultiPermissionAuthorize ops={["READ PAYMENT"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/payment"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    label="Payment"
                                    optionsPreprocessor={(opt: any) => ({
                                        ...opt,
                                        name: opt.head.name,
                                    })}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ PAYMENT_HEAD"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/payment/head"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    label="Payment Head"
                                    _name="head"
                                    optionsPreprocessor={(opt: any) => ({
                                        ...opt,
                                        name: opt.head.name,
                                    })}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <Grid item xs={12} md={6} lg={3}>
                            <SearchBar
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setChangeCount(changeCount + 1)}
                                sx={{ height: "100%" }}
                                startIcon={<FilterAltOutlined />}
                                fullWidth
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            {(!changeCount || !collections?.data?.rows.length || isError) && (
                <Alert severity="info">
                    {!collections?.data?.rows.length && isSuccess
                        ? noDataMessage
                        : !hasValue(filter) && !changeCount
                        ? filterSelectionMessage
                        : filterSelectionMessage}
                </Alert>
            )}

            <Box sx={{ height: "70%" }}>
                <BaseTable
                    headers={{
                        id: "ID",
                        name: "Name",
                        targetType: "Target Type",
                        amount: "Total Amount",
                        paidAmount: "Paid Amount",
                        breakDown: "Details",
                    }}
                    defaultSelectedHeaders={[
                        "id",
                        "name",
                        "targetType",
                        "amount",
                        "paidAmount",
                        "breakDown",
                    ]}
                    _printables={[
                        "id",
                        "name",
                        "targetType",
                        "amount",
                        "paidAmount",
                    ]}
                    load={Boolean(changeCount)}
                    data={collections?.data.rows.map((row: any) => ({
                        ...row,
                        name: row.head?.name,
                        amount: currencyFormatter.format(row.amount),
                        paidAmount: currencyFormatter.format(
                            row.collections.reduce(
                                (prev: any, curr: any) => prev + curr.amount,
                                0
                            )
                        ),
                        breakDown: (
                            <>
                                <IconButton
                                    size="small"
                                    onClick={() =>
                                        setIsBreakDownOpen({
                                            ...isBreakDownOpen,
                                            [row.id]: true,
                                        })
                                    }
                                >
                                    <InfoOutlined fontSize="small" />
                                </IconButton>

                                <PaymentBreakDown
                                    row={row.collections}
                                    open={isBreakDownOpen[row.id]}
                                    setOpen={setIsBreakDownOpen}
                                />
                            </>
                        ),
                    }))}
                    reportDetails={selectedFilters}
                    delEndPoint="/payment/collection"
                    feedback={feedback}
                    setFeedback={setFeedback}
                    rowsCount={rowsCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    blindPagination
                    isLoading={isLoading}
                    permissions={{
                        edit: [""],
                        delete: [""],
                    }}
                    queryKey={["collections", { ...pagination, ...filter }]}
                    reportName="Payment Collections List"
                />
            </Box>
        </Box>
    );
};
