import axios from "axios";
import { baseAPI, notConnectedMessage } from "../Utilities/constants";

export class DataImportService {
    static readonly collecectionEndPoint = `${baseAPI}/reading/collection/bulk`;

    static paymentCreationColumns = [
        "amount",
        "paymentMethod",
        "meterNo",
        "collectionDate",
        "description",
        "adjustment",
    ];

    static async createReadingCollections(collections: any[]) {
        const cleanCollection = [];

        for (const singleCollection of collections) {
            const clean: any = {};

            for (const col of this.paymentCreationColumns) {
                clean[col] = singleCollection[col];
            }

            cleanCollection.push(clean);
        }

        try {
            const response = await axios.post(
                this.collecectionEndPoint,
                { collections: cleanCollection },
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log("Error creating reading collections", err);
            return [null, err.response?.data];
        }
    }

    static createCollectionBulk = async (data: any) => {
        try {
            const response = await axios.post(this.collecectionEndPoint, data, {
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            console.error(err);
            return [null, err || notConnectedMessage];
        }
    };
}
