import { Description, FilterAltOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    Chip,
    ChipProps,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GET } from "../../Utilities/BaseService";
import { S3Service } from "../../Utilities/S3Service";
import {
    awsDirStruct,
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../Utilities/constants";
import BaseTable from "../Table/BaseTable";
import { LogFilter } from "./LogFilter";
import { ActionResponse } from "./SingleLog";
import { SingleLogDetails } from "./SingleLogDetails";

const fetchLogsFromS3 = async (isoDate: string, operator: any) => {
    const d = new Date(isoDate);
    const key = `${
        awsDirStruct.logs
    }/log_${d.getFullYear()}_${d.getMonth()}_${d.getDate()}.log`;
    const [data, err] = await S3Service.getS3ByKey(key);

    console.log(data, "data");

    if (data) {
        const response = await fetch(data.url);

        if (response.status !== 200) return { rows: [], count: 0 };

        const blob = await response.blob();
        const fr = new FileReader();

        const pr = new Promise<string>((resolve, reject) => {
            fr.onload = (ev) => {
                if (ev.target) {
                    resolve(ev.target.result as string);
                }
            };

            fr.onerror = reject;
            fr.readAsText(blob);
        });

        try {
            const data = JSON.parse(await pr);
            if (operator) {
                const filtered = data.filter(
                    (row: any) => row.operator.id == operator
                );

                return {
                    rows: filtered,
                    count: filtered.length,
                };
            } else {
                return {
                    rows: data,
                    count: data.length,
                };
            }
        } catch (err) {
            console.log("Error parsing JSON", err);
            return { rows: [] };
        }
    } else {
        return { rows: [] };
    }
};

const fetchLogs = (
    filter: any = {},
    page = 0,
    limit = 100,
    actionFilters: string[]
): Promise<any> => {
    const filterWithoutDate = { ...filter, date: undefined };

    if (filter.date === new Date().toISOString().split("T")[0]) {
        return GET("/logs", { ...filterWithoutDate, page: page + 1, limit });
    } else {
        return fetchLogsFromS3(filter.date, filter.operator);
    }
};

const timeFormatter = Intl.DateTimeFormat("en", {
    dateStyle: "short",
    timeStyle: "short",
});

type Filter = { date: string; operator: "" | "all" | number };

const initialFilter: Filter = {
    date: new Date().toISOString().split("T")[0],
    operator: "",
};

export const Logs = () => {
    const [intermediateFilter, setIntermediateFilter] = useState(initialFilter);
    const [filter, setFilter] = useState(initialFilter);
    const [changeCount, setChangeCount] = useState(0);
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        limit: 100,
    });
    const [logs, setLogs] = useState<Log[]>([]);
    const [actionFilters, setActionFilters] = useState<any[]>([]);
    const theme = useTheme();
    const [feedback, setFeedback] = useState("");
    const [activeLog, setActiveLog] = useState<null | number>(null);
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));

    const {
        data: logsResponse,
        status,
        isLoading,
        isError,
        isSuccess,
    } = useQuery(
        ["logs", filter, pagination.page, pagination.limit, actionFilters],
        () =>
            fetchLogs(filter, pagination.page, pagination.limit, actionFilters),
        {
            enabled: Boolean(changeCount),
            onSuccess(res) {
                if (res.data) {
                    console.log(res.data);
                    setLogs(res.data.rows);
                    setPagination({ ...pagination, count: res.data.count });
                } else {
                    setLogs(res.rows);
                    setPagination({ ...pagination, count: res.count });
                }
            },
        }
    );

    const handleActionFilters = (method: "POST" | "PATCH" | "DELETE") => {
        if (actionFilters.includes(method)) {
            setActionFilters(
                actionFilters.filter((action) => action !== method)
            );
        } else {
            setActionFilters([...actionFilters, method]);
        }
    };

    const parseActionText = (text: string, payload: any) => {
        const regex = /#[a-zA-Z]+/g;
        const matches = Array.from(text.matchAll(regex)).map((exp) => exp[0]);

        payload = JSON.parse(payload);

        for (const match of matches) {
            text = text.replaceAll(match, payload[match.slice(1)]);
        }

        return text;
    };

    useEffect(() => {
        if (changeCount) {
            setFilter({ ...filter, ...intermediateFilter });
        }
    }, [changeCount]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid container justifyContent={"space-between"}>
                    <Grid item>
                        <Typography gutterBottom variant="h5" color="GrayText">
                            Logs
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        justifyContent="flex-end"
                        spacing={1}
                        container
                        xs={6}
                    >
                        <LogFilter
                            filter={intermediateFilter}
                            setFilter={setIntermediateFilter}
                        />
                    </Grid>

                    <Grid item>
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => setChangeCount(changeCount + 1)}
                            sx={{ height: "100%" }}
                            startIcon={<FilterAltOutlined />}
                            fullWidth
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Card>

            {logs.length > 0 ? (
                <Box sx={{ height: "80%" }}>
                    <BaseTable
                        delEndPoint=""
                        headers={{
                            createdAt: "Timestamp",
                            operator: "Operator",
                            action: "Action",
                            details: "View Details",
                            status: "Response Status",
                        }}
                        data={
                            logs.map((log) => ({
                                createdAt: timeFormatter.format(
                                    new Date(log.createdAt)
                                ),
                                operator: log.operator.name,
                                action: parseActionText(
                                    log.actionText,
                                    log.payload
                                ),
                                details: (
                                    <>
                                        <IconButton
                                            size="small"
                                            onClick={() => setActiveLog(log.id)}
                                        >
                                            <Description fontSize="small" />
                                        </IconButton>

                                        <SingleLogDetails
                                            open={
                                                activeLog
                                                    ? activeLog === log.id
                                                    : false
                                            }
                                            setOpen={setActiveLog}
                                            log={log}
                                        />
                                    </>
                                ),
                                status: (
                                    <ResponseIndicator
                                        response={log.response}
                                    />
                                ),
                            })) as any
                        }
                        feedback={feedback}
                        setFeedback={setFeedback}
                        pagination={{
                            page: pagination.page,
                            limit: pagination.limit,
                        }}
                        setPagination={setPagination}
                        rowsCount={pagination.count}
                        isLoading={isLoading}
                        permissions={{
                            edit: [""],
                            delete: [""],
                        }}
                        reportName={"Logs"}
                        load={Boolean(changeCount)}
                        queryKey={[]}
                        defaultSelectedHeaders={[
                            "createdAt",
                            "operator",
                            "action",
                            "details",
                            "status",
                        ]}
                        _printables={[
                            "createdAt",
                            "operator",
                            "action",
                            "status",
                        ]}
                    />
                </Box>
            ) : hasValue(filter.operator) ? (
                <Alert severity="info">{noDataMessage}</Alert>
            ) : (
                <Alert severity="info">{filterSelectionMessage}</Alert>
            )}
        </Box>
    );
};

const chipDefaults: ChipProps = {
    size: "small",
};

const ResponseIndicator = ({ response }: { response: ActionResponse }) => {
    if (response === ActionResponse.Success) {
        return <Chip color="success" label="Success" {...chipDefaults} />;
    } else if (response === ActionResponse.Failure) {
        return <Chip color="error" label="Failed" {...chipDefaults} />;
    } else {
        return <Chip label="Unknown" {...chipDefaults} />;
    }
};
