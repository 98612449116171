import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import axios from "axios";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import { Layout } from "./Features/Layout";
import { theme } from "./Theme/theme";
import { AppContext } from "./Utilities/AppContext";
import { GET } from "./Utilities/BaseService";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            networkMode: "offlineFirst",
        },
        mutations: {
            networkMode: "offlineFirst",
        },
    },
});

axios.defaults.httpAgent = navigator.userAgent;
axios.defaults.httpsAgent = navigator.userAgent;

function App() {
    const [user, setUser] = useState<any>(null);
    const [store, setStore] = useState<any>({});
    const [isOffline, setIsOffline] = useState(false);

    const getUser = async () => {
        const userInLocalStorage = localStorage.getItem("user");

        if (userInLocalStorage) {
            setUser(JSON.parse(userInLocalStorage));
        } else {
            const res = await GET("/user/is-logged");
            if (res) {
                setUser(res.data?.user);
            }
        }
    };

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (
                    error.code === "ERR_NETWORK" &&
                    window.location.pathname !== "/no-internet"
                ) {
                    // window.location.assign("/no-internet");
                    setIsOffline(true);
                } else {
                    setIsOffline(false);
                    return Promise.reject(error);
                }
            }
        );
    }, []);

    return (
        <AppContext.Provider
            value={{
                user,
                setUser,
                store,
                setStore,
                isOffline,
                setIsOffline,
            }}
        >
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <ErrorBoundary>
                            <Layout />
                        </ErrorBoundary>
                    </BrowserRouter>

                    <ReactQueryDevtools
                        initialIsOpen={false}
                        position="bottom-right"
                    />
                </QueryClientProvider>
            </ThemeProvider>
        </AppContext.Provider>
    );
}

export default App;
