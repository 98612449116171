import { Add } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "../../../Theme/theme";
import { GET, PATCH, POST } from "../../../Utilities/BaseService";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import SearchableInput from "../../Dashboard/Components/SearchableInput";

const addProperty = (property: any) => POST("/customer/property", property);

const updateCustomer = (property: any, id: any) => {
    console.log(property);
    return PATCH("/customer/property", property, { id });
};

const AddProperty = ({ updateMode }: any) => {
    const [id, setId] = useState("");

    const [property, setProperty] = useState({
        name: "",
        address: "",
        customer: "",
        district: "",
        village: "",
        powerstation: "",
        hamlet: "",
        type: "",
    });
    const [feedback, setFeedback] = useState();
    const [clear, setClear] = useState({
        district: false,
        village: false,
        hamlet: false,
        powerstation: false,
    });
    const [defaultRegions, setDefaultRegions] = useState({
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });

    const location = useLocation();
    const navigate = useNavigate();

    const getPropertyById = () => {
        return GET(`/customer/property?id=${id}`);
    };

    const { data: propertyByid } = useQuery([`property`], getPropertyById, {
        enabled: Boolean(id),
        onSuccess(response) {
            if (response.data.rows.length > 0) {
                const _property = response.data.rows[0];

                setDefaultRegions({
                    district: _property.district.id,
                    village: _property.village.id,
                    hamlet: _property.hamlet.id,
                    powerstation: _property.powerstation.id,
                });

                setProperty({
                    ...property,
                    name: _property.name,
                    address: _property.address,
                    customer: _property.customer.id,
                    district: _property.district.id,
                    village: _property.village.id,
                    hamlet: _property.hamlet.id,
                    powerstation: _property.powerstation.id,
                    type: _property.type.id,
                });
            }
        },
        refetchOnMount: true,
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setProperty({ ...property, [e.target.name]: e.target.value });
    };

    const handleSubmit = (ev: any) => {
        ev.preventDefault();

        const targetProperty = Object.fromEntries(
            Object.entries(property).filter(([k, v]) => v && v !== "all")
        );
        {
            !updateMode
                ? addMutation.mutate(targetProperty)
                : updateMutation.mutate();
        }
    };

    const addMutation = useMutation(addProperty, {
        onSuccess(res) {
            return setFeedback(res.data.message);
        },
        onError(err: any) {
            return setFeedback(err.response.data.message);
        },
    });

    const updateMutation = useMutation(
        () =>
            updateCustomer(
                Object.fromEntries(
                    Object.entries(property).filter(
                        ([k, v]) => v && v !== "all"
                    )
                ),
                id
            ),
        {
            onSuccess(res) {
                setFeedback(res.data.message);
            },
            onError(err: any) {
                return setFeedback(err.response.data.message);
            },
        }
    );

    useEffect(() => {
        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");

            if (_id) {
                setId(_id);
            }
        }
    }, [updateMode]);

    useEffect(() => {
        if (property.district === "all") {
            setClear({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });
            setProperty({
                ...property,
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (property.village === "all") {
            setClear({ ...clear, village: true, hamlet: true });
            setProperty({
                ...property,
                village: "",
                hamlet: "",
            });
        }

        if (property.hamlet === "all") {
            setClear({ ...clear, hamlet: true });
            setProperty({ ...property, hamlet: "" });
        }

        if (defaultRegions.district !== property.district) {
            setClear({
                ...clear,
                village: true,
                hamlet: true,
            });

            setDefaultRegions({
                ...defaultRegions,
                district: property.district as any,
            });

            setProperty({
                ...property,
                village: "",
                hamlet: "",
            });
        }

        return () => {
            setClear({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [property.district, property.village, property.hamlet]);

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title={updateMode ? "update property" : "add property"}
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />

                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={1.5}
                    >
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                size="small"
                                name="name"
                                value={property.name}
                                label="Property Name"
                                onChange={handleChange}
                                fullWidth
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                size="small"
                                name="address"
                                value={property.address}
                                label="Address"
                                onChange={handleChange}
                                fullWidth
                                type="text"
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="District"
                                api="/region/district"
                                filter={property}
                                setFilter={setProperty}
                                required
                                defaultValue={
                                    updateMode && defaultRegions.district
                                }
                                clearAll={clear.district}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                key={defaultRegions?.village}
                                label="Village"
                                api="/region/village"
                                filter={property}
                                setFilter={setProperty}
                                dep={property.district}
                                params={{ district: property.district }}
                                defaultValue={
                                    updateMode && defaultRegions.village
                                }
                                clearAll={clear.village}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Hamlet"
                                api="/region/hamlet"
                                filter={property}
                                setFilter={setProperty}
                                dep={property.village}
                                params={
                                    property.village &&
                                    property.village !== "all"
                                        ? { village: [property.village] }
                                        : { village: [0] }
                                }
                                defaultValue={
                                    updateMode && defaultRegions.hamlet
                                }
                                clearAll={clear.hamlet}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Powerstation"
                                api="/powerstation"
                                filter={property}
                                setFilter={setProperty}
                                dep={property.district}
                                params={{ district: property.district }}
                                defaultValue={
                                    updateMode && defaultRegions.powerstation
                                }
                                clearAll={clear.powerstation}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Customer"
                                api="/customer"
                                filter={property}
                                setFilter={setProperty}
                                dep={[
                                    property.district,
                                    property.village,
                                    property.hamlet,
                                    property.powerstation,
                                ]}
                                params={Object.fromEntries(
                                    Object.entries(property).filter(
                                        ([k, v]) =>
                                            [
                                                "district",
                                                "village",
                                                "hamlet",
                                                "powerstation",
                                            ].includes(k) &&
                                            v &&
                                            v !== "all"
                                    )
                                )}
                                defaultValue={
                                    updateMode &&
                                    propertyByid?.data.rows[0].customer.id
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <SearchableInput
                                label="Type"
                                api="/customer/property/type"
                                filter={property}
                                setFilter={setProperty}
                                defaultValue={
                                    updateMode &&
                                    propertyByid?.data.rows[0].type
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                endIcon={
                                    addMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                size="small"
                                type="submit"
                            >
                                {updateMode ? "update" : "add"} property
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess || updateMutation.isSuccess ? (
                <Alert severity="success">{feedback}.</Alert>
            ) : addMutation.isError || updateMutation.isError ? (
                <Alert severity="error">{feedback}.</Alert>
            ) : null}
        </>
    );
};

export default AddProperty;
