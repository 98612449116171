import {
    AssessmentOutlined,
    BoltOutlined,
    DashboardOutlined,
    Equalizer,
    Link,
    MonitorHeartOutlined,
    Payment,
    PeopleOutlineOutlined,
    PersonOutlined,
    PublicOutlined,
    ReceiptLongOutlined,
    ReceiptOutlined,
    SettingsOutlined,
    SupervisorAccountOutlined,
} from "@mui/icons-material";

export const sidebarData = [
    {
        id: 1,
        name: "dashboard",
        icon: <DashboardOutlined />,
        subfields: [
            {
                id: 1,
                name: "dashboard",
                href: "/dashboard",
                permissions: ["READ DASHBOARD", "READ SIMPLE_DASHBOARD"],
            },
        ],
    },

    {
        id: 2,
        name: "Roles",
        icon: <SupervisorAccountOutlined />,
        subfields: [
            {
                id: 1,
                name: "roles",
                href: "/roles",
                permissions: [
                    "CREATE ROLE",
                    "READ ROLE",
                    "UPDATE ROLE",
                    "DELETE ROLE",
                ],
            },
        ],
    },

    {
        id: 3,
        name: "users",
        icon: <PersonOutlined />,
        subfields: [
            {
                id: 1,
                name: "users",
                href: "/users",
                permissions: [
                    "CREATE USER",
                    "READ USER",
                    "UPDATE USER",
                    "DELETE USER",
                ],
            },
        ],
    },

    {
        id: 4,
        name: "regions",
        icon: <PublicOutlined />,
        subfields: [
            {
                id: 1,
                name: "districts",
                href: "/regions/districts",
                permissions: [
                    "CREATE DISTRICT",
                    "READ DISTRICT",
                    "UPDATE DISTRICT",
                    "DELETE DISTRICT",
                ],
            },

            {
                id: 2,
                name: "villages",
                href: "/regions/villages",
                permissions: [
                    "CREATE VILLAGE",
                    "READ VILLAGE",
                    "UPDATE VILLAGE",
                    "DELETE VILLAGE",
                ],
            },

            {
                id: 3,
                name: "hamlet",
                href: "/regions/hamlets",
                permissions: [
                    "CREATE HAMLET",
                    "READ HAMLET",
                    "UPDATE HAMLET",
                    "DELETE HAMLET",
                ],
            },
        ],
    },

    {
        id: 5,
        name: "powerstations",
        icon: <BoltOutlined />,
        subfields: [
            {
                id: 1,
                name: "powerstations",
                href: "/powerstations",
                permissions: [
                    "CREATE POWERSTATION",
                    "READ POWERSTATION",
                    "UPDATE POWERSTATION",
                    "DELETE POWERSTATION",
                ],
            },
        ],
    },

    {
        id: 6,
        name: "connections",
        icon: <Link />,
        subfields: [
            {
                id: 1,
                name: "unit charges",
                href: "/connections/unit-charges",
                permissions: [
                    "CREATE RULE",
                    "READ RULE",
                    "UPDATE RULE",
                    "DELETE RULE",
                ],
            },

            {
                id: 3,
                name: "surcharge",
                href: "/connections/surcharge",
                permissions: [
                    "CREATE SURCHARGE",
                    "READ SURCHARGE",
                    "UPDATE SURCHARGE",
                    "DELETE SURCHARGE",
                ],
            },

            {
                id: 4,
                name: "line rent",
                href: "/connections/line-rent",
                permissions: [
                    "CREATE LINE_RENT",
                    "READ LINE_RENT",
                    "UPDATE LINE_RENT",
                ],
            },

            {
                id: 2,
                name: "connection types",
                href: "/connections/types",
                permissions: [
                    "CREATE CONNECTION_TYPE",
                    "READ CONNECTION_TYPE",
                    "UPDATE CONNECTION_TYPE",
                    "DELETE CONNECTION_TYPE",
                ],
            },

            {
                id: 6,
                name: "price types",
                href: "/connections/price-types",
                permissions: [
                    "CREATE PRICE_TYPE",
                    "READ PRICE_TYPE",
                    "EDIT PRICE_TYPE",
                    "DELETE PRICE_TYPE",
                    "REPLACE PRICE_TYPE",
                    "READ DEFAULT_PRICE_TYPE",
                ],
            },
        ],
    },

    {
        id: 7,
        name: "consumer",
        icon: <PeopleOutlineOutlined />,
        subfields: [
            {
                id: 1,
                name: "consumers",
                href: "/customer/consumers",
                permissions: [
                    "CREATE CUSTOMER",
                    "READ CUSTOMER",
                    "UPDATE CUSTOMER",
                    "DELETE CUSTOMER",
                ],
            },

            {
                id: 2,
                name: "property type",
                href: "/customer/property-type",
                permissions: [
                    "CREATE PROPERTY_TYPE",
                    "READ PROPERTY_TYPE",
                    "UPDATE PROPERTY_TYPE",
                    "DELETE PROPERTY_TYPE",
                ],
            },

            {
                id: 3,
                name: "property",
                href: "/customer/property",
                permissions: [
                    "CREATE PROPERTY",
                    "READ PROPERTY",
                    "UPDATE PROPERTY",
                    "DELETE PROPERTY",
                ],
            },

            {
                id: 4,
                name: "Connections",
                href: "/customer/meters",
                permissions: [
                    "CREATE METER",
                    "READ METER",
                    "UPDATE METER",
                    "DELETE METER",
                ],
            },

            {
                id: 5,
                name: "add connection",
                href: "/customer/connection",
                permissions: ["CREATE CUSTOMER"],
            },

            {
                id: 6,
                name: "Consumer History",
                href: "/customer/timeline",
                permissions: ["READ CUSTOMER"],
            },
        ],
    },

    {
        id: 11,
        name: "payment",
        icon: <Payment />,
        subfields: [
            {
                id: 1,
                name: "payment method",
                href: "/payment/method",
                permissions: [
                    "CREATE PAYMENT_METHOD",
                    "READ PAYMENT_METHOD",
                    "DELETE PAYMENT_METHOD",
                ],
            },

            {
                id: 2,
                name: "payment head",
                href: "/payment/head",
                permissions: [
                    "CREATE PAYMENT_HEAD",
                    "READ PAYMENT_HEAD",
                    "UPDATE PAYMENT_HEAD",
                    "DELETE PAYMENT_HEAD",
                ],
            },

            {
                id: 3,
                name: "payment",
                href: "/payment",
                permissions: [
                    "CREATE PAYMENT",
                    "READ PAYMENT",
                    "UPDATE PAYMENT",
                    "DELETE PAYMENT",
                ],
            },

            {
                id: 4,
                name: "Collection",
                href: "/payment/collection",
                permissions: [
                    "CREATE PAYMENT_COLLECTION",
                    "READ PAYMENT_COLLECTION",
                    "UPDATE PAYMENT_COLLECTION",
                    "DELETE PAYMENT_COLLECTION",
                ],
            },
        ],
    },

    {
        id: 8,
        name: "bills",
        icon: <ReceiptOutlined />,
        subfields: [
            {
                id: 1,
                name: "readings",
                href: "/bills/readings",
                permissions: [
                    "CREATE READING",
                    "READ READING",
                    "DELETE READING",
                ],
            },
            {
                id: 2,
                name: "update readings",
                href: "/bills/readings/update",
                permissions: ["UPDATE READING"],
            },

            {
                id: 3,
                name: "generate",
                href: "/bills",
                permissions: ["GENERATE BILL"],
            },
            {
                id: 4,
                name: "collection",
                href: "/bills/collection",
                permissions: ["READ COLLECTION"],
            },

            {
                id: 5,
                name: "Arrears",
                href: "/bills/arrears",
                permissions: ["READ DEFAULTERS_REPORT"],
            },

            {
                id: 6,
                name: "Excel Collection",
                href: "/bills/collect/excel",
                permissions: ["COLLECT BILL"],
            },

            {
                id: 6,
                name: "Excel Reading",
                href: "/bills/reading/excel",
                permissions: ["CREATE READING"],
            },
        ],
    },

    {
        id: 9,
        name: "Logs",
        icon: <MonitorHeartOutlined />,
        subfields: [
            {
                id: 1,
                name: "logs",
                href: "/logs",
                permissions: ["READ LOGS"],
            },
        ],
    },

    {
        id: 12,
        name: "Customer Reports",
        icon: <AssessmentOutlined />,
        subfields: [
            {
                id: 1,
                name: "Customer Report",
                href: "/report/customer",
                permissions: ["READ CUSTOMER_REPORT"],
            },
        ],
    },

    {
        id: 13,
        name: "Receipt / Slips",
        icon: <ReceiptLongOutlined />,
        subfields: [
            {
                id: 1,
                name: "Connection Slip",
                href: "/slips/connection",
                permissions: ["CREATE SLIP", "READ SLIP", "DELETE SLIP"],
            },

            {
                id: 2,
                name: "Meter Replacement",
                href: "/slips/meter-replacement",
                permissions: ["CREATE SLIP", "READ SLIP", "DELETE SLIP"],
            },

            {
                id: 3,
                name: "Arrears",
                href: "/slips/arrears",
                permissions: ["CREATE SLIP", "READ SLIP", "DELETE SLIP"],
            },
        ],
    },
    {
        id: 14,
        name: "unit consumption",
        icon: <Equalizer />,
        subfields: [
            {
                id: 1,
                name: "unit consumption",
                href: "/unit-consumption",
                permissions: ["READ UNIT_CONSUMPTION"],
            },
        ],
    },

    {
        id: 10,
        name: "settings",
        icon: <SettingsOutlined />,
        subfields: [
            {
                id: 1,
                name: "settings",
                href: "/settings",
                permissions: [
                    "CREATE SETTING",
                    "READ SETTING",
                    "UPDATE SETTING",
                    "DELETE SETTING",
                ],
            },
        ],
    },
];
