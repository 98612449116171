import {
    Add,
    EditOffOutlined,
    EditOutlined,
    FilterAltOutlined,
} from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    Chip,
    Collapse,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../Utilities/AppContext";
import { GET } from "../../Utilities/BaseService";
import {
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../Utilities/constants";
import { MultiPermissionAuthorize } from "../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../Utilities/UpdatedSearchableInput";
import { getSelectedFilters } from "../Customer/Property/Property";
import SearchableInput from "../Dashboard/Components/SearchableInput";
import { currencyFormatter } from "../Reading/Bill/Cells/Last12Summary";
import BaseTable from "../Table/BaseTable";
import SearchBar from "../Table/Components/SearchBar";
import EditPayment from "./EditPayment";
import PaymentCollectee from "./PaymentCollectee";

type TargetType = "meter" | "property" | "customer";

type Filter = {
    search: string;
    targetType: "" | "customer" | "property" | "meter" | "all";
    targetId: number | "" | "all";
    district: number | "" | "all";
    village: number | "" | "all";
    hamlet: number | "" | "all";
    powerstation: number | "" | "all";
};

const initialFilter: Filter = {
    search: "",
    targetType: "",
    targetId: "",
    district: "",
    village: "",
    hamlet: "",
    powerstation: "",
};

const initialClear = {
    district: false,
    village: false,
    hamlet: false,
    powerstation: false,
};

const getPayments = (pagination: any, filter: any) => {
    return GET("/payment", {
        page: pagination.page + 1,
        limit: pagination.limit,
        ...Object.fromEntries(
            Object.entries(filter).filter(([k, v]) => v && v !== "all")
        ),
        // search: filter.search ? filter.search : undefined,
        // targetId:
        //     filter.targetId && filter.targetId !== "all"
        //         ? filter.targetId
        //         : undefined,
        // targetType:
        //     filter.targetType && filter.targetType !== "all"
        //         ? filter.targetType
        //         : undefined,
        // head:
        //     filter["payment head"] && filter["payment head"] !== "all"
        //         ? filter["payment head"]
        //         : undefined,
    });
};

const getTarget = async (targetType: TargetType, id: number) => {
    const getApi = () => {
        switch (targetType) {
            case "customer":
                return "/customer";

            case "meter":
                return "/customer/meter";

            case "property":
                return "/customer/property";
            default:
                return "";
        }
    };

    if (getApi()) {
        const { data } = await GET(getApi(), { id });

        return data.rows[0];
    } else return {};
};

export const getReportTemplates = async (
    userId: number,
    reportPrefix: string
) => {
    return await GET("/preferences", { user: userId, category: reportPrefix });
};

export const Payment = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [filter, setFilter] = useState(initialFilter);
    const [feedback, setFeedback] = useState("");
    const [payments, setPayments] = useState<any>([]);
    const [showEditPayment, setShowEditPayment] = useState<any>({ 0: false });
    const [reportTemplates, setReportTemplates] = useState<string[]>([]);
    const [clearAll, setClearAll] = useState(initialClear);
    const [selectedFilters, setSelectedFilters] = useState({});

    const [changeCount, setChangeCount] = useState(0);
    const [intermediateFilter, setIntermediateFilter] =
        useState<Filter>(initialFilter);

    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));
    const { user } = useContext(AppContext);

    const { data, isLoading, isError, isSuccess } = useQuery(
        [
            "payment",
            {
                ...pagination,
                ...Object.fromEntries(
                    Object.entries(filter).filter(([k, v]) => v && v !== "all")
                ),
            },
        ],
        () => getPayments(pagination, filter),
        {
            enabled: Boolean(changeCount),
            async onSuccess(res) {
                if (res) setRowsCount(res?.data.count);
                const _payments = res?.data.rows || [];

                const promises: any = [];

                for (const row of _payments) {
                    promises.push(getTarget(row.targetType, row.targetId));
                }

                const collectess = await Promise.all(promises);

                console.log(collectess);

                let withCollectees: any = [];
                let index = 0;

                for (const item of collectess) {
                    if (item.id === _payments[index].targetId) {
                        withCollectees.push({
                            ..._payments[index],
                            collectee: item,
                        });
                    }
                    index++;
                }

                setPayments(withCollectees);

                const _selectedFills = getSelectedFilters(
                    withCollectees[0],
                    ["district", "village", "hamlet", "powerstation"],
                    filter,
                    {
                        district: { label: "District", valueKey: "name" },
                        village: { label: "Village", valueKey: "name" },
                        hamlet: { label: "Hamlet", valueKey: "name" },
                        powerstation: {
                            label: "Power Station",
                            valueKey: "name",
                        },
                    }
                );

                setSelectedFilters(() => _selectedFills);
            },
        }
    );

    const { data: reportTemps } = useQuery(
        ["report-templates"],
        () => getReportTemplates(user.id, "Payment"),
        {
            onSuccess(res) {
                setReportTemplates(() =>
                    res.data.rows.map((item: any) => ({
                        key: item.key,
                        value: JSON.parse(item.value),
                    }))
                );
            },
        }
    );

    useEffect(() => {
        if (intermediateFilter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });
            setIntermediateFilter({
                ...intermediateFilter,
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (intermediateFilter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
            setIntermediateFilter({
                ...intermediateFilter,
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
            setIntermediateFilter({ ...intermediateFilter, hamlet: "" });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [
        intermediateFilter.district,
        intermediateFilter.village,
        intermediateFilter.hamlet,
        intermediateFilter.powerstation,
    ]);

    useEffect(() => {
        if (changeCount) {
            setFilter({ ...filter, ...intermediateFilter });
        }
    }, [changeCount]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}
                >
                    <Grid item xs={12} md="auto">
                        <Typography variant="h5" color="GrayText">
                            Payments
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} container spacing={1.5}>
                        <Grid item flex={1}></Grid>

                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize ops={["CREATE PAYMENT"]}>
                                <Link
                                    to="/payment/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{ height: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                    >
                                        add payment
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Collapse in={true}>
                            <Grid container spacing={1.5}>
                                {/* <Grid item xs={12} md={6} lg={4}> */}

                                <MultiPermissionAuthorize
                                    ops={["READ DISTRICT"]}
                                >
                                    <Grid item xs={12} md={6} lg={3}>
                                        <UpdatedSearchableInput
                                            api="/region/district"
                                            label="District"
                                            filter={intermediateFilter}
                                            setFilter={setIntermediateFilter}
                                            clearAll={clearAll.district}
                                        />
                                    </Grid>
                                </MultiPermissionAuthorize>

                                <MultiPermissionAuthorize
                                    ops={["READ VILLAGE"]}
                                >
                                    <Grid item xs={12} md={6} lg={3}>
                                        <UpdatedSearchableInput
                                            api="/region/village"
                                            label="Village"
                                            filter={intermediateFilter}
                                            setFilter={setIntermediateFilter}
                                            dep={intermediateFilter.district}
                                            params={{
                                                district:
                                                    intermediateFilter.district,
                                            }}
                                            clearAll={clearAll.village}
                                        />
                                    </Grid>
                                </MultiPermissionAuthorize>

                                <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <UpdatedSearchableInput
                                            api="/region/hamlet"
                                            label="Hamlet"
                                            filter={intermediateFilter}
                                            setFilter={setIntermediateFilter}
                                            dep={intermediateFilter.village}
                                            params={
                                                intermediateFilter.village &&
                                                intermediateFilter.village !==
                                                    "all"
                                                    ? {
                                                          village: [
                                                              intermediateFilter.village,
                                                          ],
                                                      }
                                                    : { village: [0] }
                                            }
                                            clearAll={clearAll.hamlet}
                                        />
                                    </Grid>
                                </MultiPermissionAuthorize>

                                <MultiPermissionAuthorize
                                    ops={["READ POWERSTATION"]}
                                >
                                    <Grid item xs={12} md={6} lg={3}>
                                        <UpdatedSearchableInput
                                            api="/powerstation"
                                            label="Powerstation"
                                            filter={intermediateFilter}
                                            setFilter={setIntermediateFilter}
                                            dep={intermediateFilter.district}
                                            params={{
                                                district:
                                                    intermediateFilter.district,
                                            }}
                                            clearAll={clearAll.powerstation}
                                        />
                                    </Grid>
                                </MultiPermissionAuthorize>

                                <Grid item xs={12} md={6} lg={3}>
                                    <UpdatedSearchableInput
                                        api="/payment/head/targetType"
                                        label="Target Type"
                                        filter={intermediateFilter}
                                        setFilter={setIntermediateFilter}
                                        _name="targetType"
                                        optionsFilter={(opts: any[]) =>
                                            opts.filter(
                                                (opt: any) =>
                                                    opt.type !== "reading"
                                            )
                                        }
                                        optionsPreprocessor={(opt: any) => ({
                                            ...opt,
                                            id: opt.type,
                                            name: opt.label,
                                        })}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <UpdatedSearchableInput
                                        api={
                                            intermediateFilter.targetType
                                                ? intermediateFilter.targetType ===
                                                  "customer"
                                                    ? "/customer"
                                                    : intermediateFilter.targetType ===
                                                      "meter"
                                                    ? "/customer/meter"
                                                    : "/customer/property"
                                                : ""
                                        }
                                        label="Target"
                                        _name="targetId"
                                        filter={intermediateFilter}
                                        setFilter={setIntermediateFilter}
                                        dep={[
                                            intermediateFilter.targetType,
                                            intermediateFilter.district,
                                            intermediateFilter.village,
                                            intermediateFilter.hamlet,
                                            intermediateFilter.powerstation,
                                        ]}
                                        optionsPreprocessor={
                                            intermediateFilter.targetType ===
                                            "meter"
                                                ? (opt: any) => ({
                                                      ...opt,
                                                      name: opt.meterNo,
                                                  })
                                                : undefined
                                        }
                                        required
                                        disabled={
                                            !intermediateFilter.targetType ||
                                            intermediateFilter.targetType ===
                                                "all"
                                        }
                                        params={
                                            intermediateFilter.targetType &&
                                            intermediateFilter.targetType !==
                                                "customer"
                                                ? Object.fromEntries(
                                                      Object.entries(
                                                          intermediateFilter
                                                      ).filter(
                                                          ([k, v]) =>
                                                              [
                                                                  "district",
                                                                  "village",
                                                                  "hamlet",
                                                              ].includes(k) &&
                                                              v &&
                                                              v !== "all"
                                                      )
                                                  )
                                                : undefined
                                        }
                                    />
                                </Grid>

                                <MultiPermissionAuthorize
                                    ops={["READ PAYMENT_HEAD"]}
                                >
                                    <Grid item xs={12} md={6} lg={3}>
                                        <SearchableInput
                                            api="/payment/head"
                                            label="Payment Head"
                                            filter={intermediateFilter}
                                            setFilter={setIntermediateFilter}
                                        />
                                    </Grid>
                                </MultiPermissionAuthorize>

                                <Grid item xs={12} md={6} lg={3}>
                                    <SearchBar
                                        filter={intermediateFilter}
                                        setFilter={setIntermediateFilter}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        onClick={() =>
                                            setChangeCount(changeCount + 1)
                                        }
                                        sx={{
                                            height: "100%",
                                        }}
                                        startIcon={<FilterAltOutlined />}
                                        fullWidth
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Grid>
                </Grid>
            </Card>

            {(!changeCount || !data?.data.rows.length || isError) && (
                <Alert severity="info">
                    {!data?.data.rows.length && isSuccess
                        ? noDataMessage
                        : !hasValue(filter) && !changeCount
                        ? filterSelectionMessage
                        : filterSelectionMessage}
                </Alert>
            )}

            <Box sx={{ height: "60%" }}>
                <BaseTable
                    _printables={[
                        "id",
                        "targetType",
                        "name",
                        "head",
                        "amount",
                        "description",
                    ]}
                    headers={{
                        id: "ID",
                        targetType: "Target Type",
                        name: "Collectee",
                        head: "Payment Head",
                        amount: "Amount",
                        editable: "Editable",
                        completed: "Status",
                        description: "Description",
                    }}
                    defaultSelectedHeaders={[
                        "id",
                        "name",
                        "head",
                        "amount",
                        "description",
                        "editable",
                        "completed",
                    ]}
                    load={Boolean(changeCount)}
                    data={payments.map((row: any) => ({
                        ...row,
                        head: row.head.name,
                        name: (
                            <PaymentCollectee
                                targetType={row.targetType}
                                collectee={row.collectee}
                            />
                        ),
                        // row.targetType === "meter"
                        //     ? row.collectee.meterNo
                        //     : row.collectee.name,
                        amount: row.head.editable
                            ? currencyFormatter.format(row.amount)
                            : currencyFormatter.format(row.head.amount),
                        completed: (
                            <Chip
                                label={row.completed ? "Completed" : "Pending"}
                                size="small"
                                variant="outlined"
                                color={row.completed ? "success" : "warning"}
                                sx={{ width: "12ch" }}
                            />
                        ),

                        editable:
                            row.head.editable && !row.completed ? (
                                <>
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            setShowEditPayment({
                                                [row.id]: true,
                                            })
                                        }
                                    >
                                        <EditOutlined fontSize="small" />
                                    </IconButton>

                                    <EditPayment
                                        open={showEditPayment}
                                        setOpen={setShowEditPayment}
                                        row={{
                                            amount: row.amount
                                                ? row.amount
                                                : row.head.amount,
                                            id: row.id,
                                        }}
                                    />
                                </>
                            ) : (
                                <IconButton size="small" disabled>
                                    <EditOffOutlined fontSize="small" />
                                </IconButton>
                            ),
                    }))}
                    delEndPoint="/payment"
                    feedback={feedback}
                    setFeedback={setFeedback}
                    rowsCount={rowsCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    isLoading={isLoading}
                    permissions={{
                        edit: [""],
                        delete: ["DELETE PAYMENT"],
                    }}
                    queryKey={[
                        "payment",
                        {
                            ...pagination,
                            ...Object.fromEntries(
                                Object.entries(filter).filter(
                                    ([k, v]) => v && v !== "all"
                                )
                            ),
                        },
                    ]}
                    reportName="Payment Report"
                    reportCategory="Payment"
                    reportTemplates={reportTemplates}
                    reportDetails={selectedFilters}
                    hasReportTemplates
                />
            </Box>
        </Box>
    );
};
