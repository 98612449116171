import {
    Add,
    FilterAltOutlined,
    PublicOutlined,
    Visibility,
} from "@mui/icons-material";
import {
    Alert,
    Avatar,
    Box,
    Button,
    Card,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { GET } from "../../../Utilities/BaseService";
import {
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../../Utilities/constants";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { currencyFormatter } from "../../Reading/Bill/Cells/Last12Summary";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";
import { UserRegionsTree } from "../../User/UserRegionsTree";
import ReplacePriceType from "../Components/ReplacePriceType";

export const getAllPriceTypes = (pagination: any, filter: any) => {
    return GET("/priceType", {
        page: pagination.page + 1,
        limit: pagination.limit,
        ...Object.fromEntries(
            Object.entries(filter).filter(([k, v]) => v && v !== "all")
        ),
    });
};

type Filter = {
    search: string;
    district: number | "" | "all";
    village: number | "" | "all";
    hamlet: number | "" | "all";
    powerstation: number | "" | "all";
    surcharge: number | "" | "all";
    lineRent: number | "" | "all";
    unitChargeRule: number | "" | "all";
    connectionType: number | "" | "all";
    isDefault: 0 | 1;
};

type Region = { id: number; name: string };

type Regions = {
    district: Region;
    village: Region | null;
    hamlet: Region | null;
    powerstation: Region | null;
};

const initialFilter: Filter = {
    search: "",
    district: "",
    village: "",
    hamlet: "",
    powerstation: "",
    surcharge: "",
    lineRent: "",
    unitChargeRule: "",
    connectionType: "",
    isDefault: 0,
};

const initialClear = {
    district: false,
    village: false,
    hamlet: false,
    powerstation: false,
};

const PriceType = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [load, setLoad] = useState(false);
    const [filter, setFilter] = useState(initialFilter);
    const [clear, setClear] = useState(initialClear);
    const [feedback, setFeedback] = useState("");
    const [showRegions, setShowRegions] = useState<any | null>(null);
    const [showUsers, setShowUsers] = useState<any | null>(null);
    const [changeCount, setChangeCount] = useState(0);
    const [intermediateFilter, setIntermediateFilter] =
        useState<Filter>(initialFilter);
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));

    const { data, isLoading, isError, isSuccess } = useQuery(
        ["price-types", pagination.page, pagination.limit, filter],
        () => getAllPriceTypes(pagination, filter),
        {
            enabled: Boolean(changeCount),
            onSuccess(res) {
                if (res) setRowsCount(res?.data.count);
            },
        }
    );

    const getGroupedRegions = useMemo(() => {
        if (showRegions?.regions) {
            const activeUserRegions: Regions[] = showRegions.regions ?? [];

            const targetRegions = activeUserRegions.reduce(
                (result: any, item) => {
                    const { district, village, hamlet } = item;

                    // Group by district
                    const districtKey = district.name;
                    if (!result[districtKey]) {
                        result[districtKey] = {
                            villages: {},
                        };
                    }

                    // Group by village
                    if (village) {
                        const villageKey = village.name;
                        if (!result[districtKey].villages[villageKey]) {
                            result[districtKey].villages[villageKey] = {
                                hamlets: [],
                            };
                        }

                        // Group by hamlet
                        if (hamlet) {
                            result[districtKey].villages[
                                villageKey
                            ].hamlets.push(hamlet.name);
                        }
                    }

                    return result;
                },
                {}
            );

            return targetRegions;
        }
    }, [showRegions?.regions?.length]);

    useEffect(() => {
        if (intermediateFilter.district === "all") {
            setClear({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });
            setIntermediateFilter({
                ...intermediateFilter,
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (intermediateFilter.village === "all") {
            setClear({ ...clear, village: true, hamlet: true });
            setIntermediateFilter({
                ...intermediateFilter,
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.hamlet === "all") {
            setClear({ ...clear, hamlet: true });
            setIntermediateFilter({ ...intermediateFilter, hamlet: "" });
        }

        return () => {
            setClear({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [
        intermediateFilter.district,
        intermediateFilter.village,
        intermediateFilter.hamlet,
    ]);

    useEffect(() => {
        if (changeCount) {
            setFilter({ ...filter, ...intermediateFilter });
        }
    }, [changeCount]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                >
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5" color="GrayText">
                            Price Types
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} container spacing={1.5}>
                        <Grid item flex={1}></Grid>

                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize
                                ops={["CREATE PRICE_TYPE"]}
                            >
                                <Link
                                    to="/connections/price-types/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{ height: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                        fullWidth
                                    >
                                        add price type
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={1.5}>
                        <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/district"
                                    label="District"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    clearAll={clear.district}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/village"
                                    label="Village"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.district}
                                    params={{
                                        district: intermediateFilter.district,
                                    }}
                                    clearAll={clear.village}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/hamlet"
                                    label="Hamlet"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.village}
                                    params={
                                        intermediateFilter.village &&
                                        intermediateFilter.village !== "all"
                                            ? {
                                                  village: [
                                                      intermediateFilter.village,
                                                  ],
                                              }
                                            : { village: [0] }
                                    }
                                    clearAll={clear.hamlet}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ POWERSTATION"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/powerstation"
                                    label="Powerstation"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.district}
                                    params={{
                                        district: intermediateFilter.district,
                                    }}
                                    clearAll={clear.powerstation}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ SURCHARGE"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/connection/surcharge"
                                    label="Surcharge"
                                    _name="surcharge"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    optionsPreprocessor={(row: any) => ({
                                        ...row,
                                        name: `${row.name} - ${
                                            row.isSurchargePct
                                                ? row.value
                                                : currencyFormatter.format(
                                                      row.value
                                                  )
                                        } ${row.isSurchargePct ? "%" : ""}`,
                                    })}
                                    dep={[
                                        intermediateFilter.district,
                                        intermediateFilter.village,
                                        intermediateFilter.hamlet,
                                        intermediateFilter.powerstation,
                                    ]}
                                    params={Object.fromEntries(
                                        Object.entries(
                                            intermediateFilter
                                        ).filter(
                                            ([k, v]) =>
                                                [
                                                    "district",
                                                    "village",
                                                    "hamlet",
                                                    "powerstation",
                                                ].includes(k) &&
                                                v &&
                                                v !== "all"
                                        )
                                    )}
                                    clearAll={clear.district}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ LINE_RENT"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/line-rent"
                                    label="Line Rent"
                                    _name="lineRent"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    optionsPreprocessor={(row: any) => ({
                                        ...row,
                                        name: `${
                                            row.name
                                        } - ${currencyFormatter.format(
                                            row.amount
                                        )}`,
                                    })}
                                    dep={[
                                        intermediateFilter.district,
                                        intermediateFilter.village,
                                        intermediateFilter.hamlet,
                                        intermediateFilter.powerstation,
                                    ]}
                                    params={Object.fromEntries(
                                        Object.entries(
                                            intermediateFilter
                                        ).filter(
                                            ([k, v]) =>
                                                [
                                                    "district",
                                                    "village",
                                                    "hamlet",
                                                    "powerstation",
                                                ].includes(k) &&
                                                v &&
                                                v !== "all"
                                        )
                                    )}
                                    clearAll={clear.district}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ RULE"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/connection/unit-charge-rule"
                                    label="Unit Charge Rule"
                                    _name="unitChargeRule"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={[
                                        intermediateFilter.district,
                                        intermediateFilter.village,
                                        intermediateFilter.hamlet,
                                        intermediateFilter.powerstation,
                                    ]}
                                    params={Object.fromEntries(
                                        Object.entries(
                                            intermediateFilter
                                        ).filter(
                                            ([k, v]) =>
                                                [
                                                    "district",
                                                    "village",
                                                    "hamlet",
                                                    "powerstation",
                                                ].includes(k) &&
                                                v &&
                                                v !== "all"
                                        )
                                    )}
                                    clearAll={clear.district}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize
                            ops={["READ CONNECTION_TYPE"]}
                        >
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/connection"
                                    label="Connection Type"
                                    _name="connectionType"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={[
                                        intermediateFilter.district,
                                        intermediateFilter.village,
                                        intermediateFilter.hamlet,
                                        intermediateFilter.powerstation,
                                    ]}
                                    params={Object.fromEntries(
                                        Object.entries(
                                            intermediateFilter
                                        ).filter(
                                            ([k, v]) =>
                                                [
                                                    "district",
                                                    "village",
                                                    "hamlet",
                                                    "powerstation",
                                                ].includes(k) &&
                                                v &&
                                                v !== "all"
                                        )
                                    )}
                                    clearAll={clear.district}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <Grid item xs={12} md={6} lg={3}>
                            <Box
                                sx={{
                                    border: `1px solid ${theme.palette.action.disabled}`,
                                    borderRadius: theme.spacing(0.5),
                                    px: 1.5,
                                }}
                            >
                                <FormControlLabel
                                    label="Default Price Type"
                                    control={
                                        <Checkbox
                                            checked={Boolean(
                                                intermediateFilter.isDefault
                                            )}
                                            onChange={(e) =>
                                                setIntermediateFilter({
                                                    ...intermediateFilter,
                                                    isDefault: e.target.checked
                                                        ? 1
                                                        : 0,
                                                })
                                            }
                                        />
                                    }
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <SearchBar
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setChangeCount(changeCount + 1)}
                                sx={{ height: "100%" }}
                                startIcon={
                                    <FilterAltOutlined fontSize="small" />
                                }
                                fullWidth
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            {(!changeCount || !data?.data.rows.length || isError) && (
                <Alert severity="info">
                    {!data?.data.rows.length && isSuccess
                        ? noDataMessage
                        : !hasValue(filter) && !changeCount
                        ? filterSelectionMessage
                        : ""}
                </Alert>
            )}

            <Box sx={{ height: "60%" }}>
                <BaseTable
                    headers={{
                        id: "ID",
                        name: "Name",
                        lineRent: "Line Rent",
                        surcharge: "Surcharge",
                        unitChargeRule: "Unit Charge Rule",
                        connectionType: "Connection Type",
                        isDefault: "Type",
                        replaceUnitChargeRule: "Replace",
                        regions: "Regions",
                    }}
                    defaultSelectedHeaders={[
                        "id",
                        "name",
                        "lineRent",
                        "surcharge",
                        "unitChargeRule",
                        "connectionType",
                        "isDefault",
                        "replaceUnitChargeRule",
                        "regions",
                    ]}
                    _printables={[
                        "id",
                        "name",
                        "lineRent",
                        "surcharge",
                        "unitChargeRule",
                        "connectionType",
                        "isDefault",
                    ]}
                    data={data?.data.rows.map((row: any) => ({
                        ...row,
                        lineRent: (
                            <Tooltip title={row.lineRent.name}>
                                <Typography variant="body2">
                                    {currencyFormatter.format(
                                        row.lineRent.amount
                                    )}
                                </Typography>
                            </Tooltip>
                        ),

                        surcharge: (
                            <Tooltip title={row.surcharge.name}>
                                <Typography variant="body2">
                                    {row.surcharge.isSurchargePct
                                        ? `${row.surcharge.value} %`
                                        : currencyFormatter.format(
                                              row.surcharge.value
                                          )}
                                </Typography>
                            </Tooltip>
                        ),

                        unitChargeRule: row.unitChargeRule.name,
                        connectionType: row.connectionType.name,
                        isDefault: (
                            <Chip
                                label={
                                    row.isDefault ? "Default" : "Not Default"
                                }
                                color={row.isDefault ? "success" : "warning"}
                                variant="outlined"
                                size="small"
                            />
                        ),

                        replaceUnitChargeRule: (
                            <MultiPermissionAuthorize
                                ops={["REPLACE PRICE_TYPE"]}
                            >
                                <ReplacePriceType row={row} />
                            </MultiPermissionAuthorize>
                        ),

                        regions:
                            row.regions?.length == 0 ? (
                                "Global"
                            ) : (
                                <IconButton
                                    size="small"
                                    onClick={() =>
                                        setShowRegions({ regions: row.regions })
                                    }
                                    color={
                                        row.regions.length === 0 &&
                                        !row.allRegionAccess
                                            ? "error"
                                            : "success"
                                    }
                                >
                                    <PublicOutlined fontSize="medium" />
                                </IconButton>
                            ),
                    }))}
                    load={Boolean(changeCount)}
                    delEndPoint="/priceType"
                    feedback={feedback}
                    setFeedback={setFeedback}
                    rowsCount={rowsCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    isLoading={isLoading}
                    permissions={{
                        edit: ["EDIT PRICE_TYPE"],
                        delete: ["DELETE PRICE_TYPE"],
                    }}
                    queryKey={[
                        "price-types",
                        pagination.page,
                        pagination.limit,
                        filter,
                    ]}
                    reportName="Price Types List"
                />

                <Dialog
                    fullWidth
                    open={Boolean(showRegions)}
                    onClose={() => setShowRegions(null)}
                >
                    <DialogTitle>
                        Region&nbsp;access&nbsp;-&nbsp;{showRegions?.name}
                    </DialogTitle>
                    <DialogContent>
                        {showRegions && showRegions.regions.length === 0 && (
                            <Typography>This price type is global</Typography>
                        )}
                        {Boolean(showRegions) &&
                            Boolean(showRegions.regions.length > 0) && (
                                <UserRegionsTree regions={getGroupedRegions} />
                            )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowRegions(null)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth
                    open={Boolean(showUsers)}
                    onClose={() => setShowUsers(null)}
                >
                    <DialogTitle>
                        User&nbsp;access&nbsp;-&nbsp;{showUsers?.name}
                    </DialogTitle>
                    <DialogContent>
                        <List dense>
                            {showUsers && showUsers.users.length === 0 && (
                                <Typography>
                                    All users have access to this price type
                                </Typography>
                            )}
                            {showUsers?.users.map((user: any) => (
                                <ListItem key={user.id}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            {user.name
                                                .split(" ")
                                                .map((part: string) => part[0])
                                                .join("")
                                                .toUpperCase()}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={user.name}
                                        secondary={user.role.name}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowUsers(null)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
};

export default PriceType;
