import { ChevronLeft, HomeOutlined } from "@mui/icons-material";
import { Divider, Drawer, Grid, IconButton, List } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BaseCollapse from "./BaseCollapse";

const drawerWidth = 240;
const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
    const theme = useTheme();
    const location = useLocation();

    const handleClose = () => {
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        if (location.pathname === "/no-internet") {
            setSidebarOpen(false);
        }
    }, [location.pathname]);

    return location.pathname !== "/no-internet" ? (
        <Grid container sx={{ zIndex: "11" }} component={"aside"} onFocus={e => e.preventDefault()}>
            <Grid item >
                <Drawer
                    sx={{
                        // bgcolor: theme.palette.background.default,
                        position: "relative",
                        width: sidebarOpen ? drawerWidth : "0",
                        flexShrink: 0,
                        "& .MuiDrawer-paper": {
                            width: drawerWidth,
                            boxSizing: "border-box",
                        },
                        transition: "0.35s all ease",
                    }}
                    variant="persistent"
                    anchor="left"
                    open={sidebarOpen}
                    PaperProps={{
                        sx: { bgcolor: theme.palette.primary.light },
                    }}
                >
                    <DrawerHeader
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            bgcolor: theme.palette.primary.main,
                        }}
                    >
                        <IconButton href="/"
                            tabIndex={-1}
                        >
                            <HomeOutlined />
                            {/* <img src="/logo-white.png" width="75" /> */}
                        </IconButton>

                        <IconButton onClick={handleClose}
                            tabIndex={-1}
                        >
                            <ChevronLeft />
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List disablePadding
                        tabIndex={-1}
                    >
                        <BaseCollapse
                            sidebarOpen={sidebarOpen}
                            setSidebarOpen={setSidebarOpen}
                        />
                    </List>
                </Drawer>
            </Grid>
        </Grid>
    ) : null;
}
