import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";

const PaymentView = ({ activeRow, setActiveRow }: any) => {
    const [open, setOpen] = useState(true);

    const opts = useMemo(() => {
        try {
            return activeRow?.opts ? JSON.parse(activeRow.opts) : {};
        } catch (error) {
            console.log(error);
            return {};
        }
    }, [activeRow.opts]);

    const handleClose = () => {
        setOpen(!open);
        setActiveRow(!activeRow);
    };
    return (
        <Dialog open={open} fullWidth>
            <DialogContent>
                <Table size="small">
                    <TableBody>
                        {Object.values(opts).length === 0 && (
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Typography
                                        variant="h6"
                                        color="action.disabled"
                                    >
                                        No Fields
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {Object.values(opts).map((op: any, idx: number) => (
                            <TableRow
                                key={idx}
                                sx={{
                                    bgcolor: "common.white",
                                }}
                            >
                                <TableCell>
                                    <Typography variant="body1">
                                        {op.label}
                                    </Typography>
                                </TableCell>

                                <TableCell sx={{ fontWeight: "700" }}>
                                    <Divider orientation="vertical" />
                                </TableCell>

                                <TableCell>
                                    <Typography variant="body1">
                                        {op.value}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" color="error" onClick={handleClose}>
                    close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentView;
