import { VisibilityOutlined } from "@mui/icons-material";
import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { GET } from "../../../Utilities/BaseService";
import { Print } from "../../../Utilities/Print";
import { TableToExcel } from "../../../Utilities/TableToExcel";
import { Header } from "../../Header/Header";
import { currencyFormatter } from "../Bill/Cells/Last12Summary";
import { monthFormatter } from "../Bill/SingleBill";

export interface MonthlyDefaulterBreakdown {
    reading_month: number;
    reading_year: number;
    meter_id: number;
    meterNo: string;
    districtId: number;
    districtName: string;
    villageId: number;
    villageName: string;
    hamletId: number;
    hamletName: string;
    customerId: number;
    customerName: string;
    fatherName: string;
    cnic: string;
    mobileNumber: string;
    Total: number;
    Collection: number | null;
    PMCCollection: number | null;
    AppliedSurcharge: number | null;
    PaidSurcharge: number | null;
    AppliedSurchargeWhenNoCollection: null | number;
    surchargeAdj?: number;
}

const initial_pagination = { page: 0, limit: 100, count: 0 };

const ArrearsReportDetail = ({ row }: any) => {
    const [open, setOpen] = useState(false);
    const [printMode, setPrintMode] = useState(false);
    const printRef = useRef<any>();
    const excelRef = useRef<any>();

    const [pagination, setPagination] = useState(initial_pagination);
    const [arrearsDetails, setArrearsDetail] = useState<
        MonthlyDefaulterBreakdown[]
    >([]);
    const [loading, setLoading] = useState(false);

    const calcArrearsWithoutSurcharge = useMemo(
        () => (row: MonthlyDefaulterBreakdown) => {
            const { Total, Collection, PMCCollection } = row;

            const surcharge =
                Total - ((Collection || 0) + (PMCCollection || 0));

            return currencyFormatter.format(surcharge);
        },
        [arrearsDetails.length]
    );

    const calcArrearsWithSurcharge = useMemo(
        () => (row: MonthlyDefaulterBreakdown) => {
            const {
                Total,
                AppliedSurcharge,
                PaidSurcharge,
                Collection,
                PMCCollection,
                AppliedSurchargeWhenNoCollection,
                surchargeAdj,
            } = row;

            const surcharge =
                Total +
                (Collection === null
                    ? AppliedSurchargeWhenNoCollection ?? 0
                    : 0) +
                (AppliedSurcharge || 0) -
                ((PaidSurcharge || 0) +
                    (surchargeAdj || 0) +
                    (Collection || 0) +
                    (PMCCollection || 0));

            return currencyFormatter.format(surcharge);
        },
        [arrearsDetails.length]
    );

    const handlePageChange = (ev: any, page: number) => {
        setPagination({ ...pagination, page: page });
    };

    const handleRowsPerPageChange = (ev: any) => {
        setPagination({ ...pagination, limit: ev.target.value });
    };

    const getSingleArrearsDetail = async () => {
        setLoading(true);

        const res = await GET("/reading/single-detail-defaulter-report", {
            id: row.meterId,
            page: pagination.page + 1,
            limit: pagination.limit,
        });

        console.log(res);

        if (res.data.readings) {
            setArrearsDetail(res.data.readings);
            setPagination((curr) => ({ ...curr, count: res.data.count }));
        }

        setLoading(false);
    };

    useEffect(() => {
        if (!open) return;

        getSingleArrearsDetail();
    }, [open, pagination.page, pagination.limit]);

    return (
        <div>
            <IconButton size="small" onClick={() => setOpen(true)}>
                <VisibilityOutlined fontSize="small" />
            </IconButton>

            <div>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box>Arrears Breakdown</Box>
                            <Box display="flex" alignItems="center" gap={2}>
                                <TablePagination
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={
                                        handleRowsPerPageChange
                                    }
                                    rowsPerPage={pagination.limit}
                                    component="div"
                                    page={pagination?.page}
                                    count={pagination.count}
                                />
                                <Print
                                    componentRef={printRef}
                                    setPrintMode={setPrintMode}
                                />
                                <TableToExcel
                                    fileName="Arrears Breakdown"
                                    sheetName=""
                                    targetRef={excelRef}
                                />
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        {loading ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height="100%"
                            >
                                <CircularProgress />
                            </Box>
                        ) : arrearsDetails && arrearsDetails?.length ? (
                            <TableContainer ref={printRef}>
                                {printMode && (
                                    <Header name={"Arrears Breakdown"} />
                                )}
                                <Table size="small" ref={excelRef}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Month(Year)</TableCell>
                                            <TableCell>
                                                Arrears Without Surcharge
                                            </TableCell>
                                            <TableCell>
                                                Arrears With Surcharge
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {arrearsDetails.map(
                                            (row, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {monthFormatter.format(
                                                            new Date(
                                                                row.reading_year,
                                                                row.reading_month -
                                                                    1 || 0,
                                                                1
                                                            )
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {calcArrearsWithoutSurcharge(
                                                            row
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {calcArrearsWithSurcharge(
                                                            row
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height="100%"
                            >
                                <p>No arrears details available.</p>
                            </Box>
                        )}
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
};

export default ArrearsReportDetail;
