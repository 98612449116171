import { Add, FilterAltOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET } from "../../../Utilities/BaseService";
import {
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../../Utilities/constants";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";

type Filter = {
    search: string;
    district: number | "" | "all";
};

const initialFilter: Filter = {
    search: "",
    district: "",
};

export const fetchVillages = (params: any = {}) => {
    return GET("/region/village", params);
};

const Villages = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [filter, setFilter] = useState<any>(initialFilter);
    const [changeCount, setChangeCount] = useState(0);
    const [intermediateFilter, setIntermediateFilter] =
        useState<Filter>(initialFilter);
    const [feedback, setFeedback] = useState("");
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));

    const getVillages = () => {
        return GET("/region/village", {
            page: pagination.page + 1,
            limit: pagination.limit,
            search: filter.search,
            district:
                filter.district === "all" || filter.district?.length === 0
                    ? undefined
                    : filter.district,
        });
    };

    const { data, isLoading, isError, isSuccess } = useQuery(
        ["villages", pagination.page, pagination.limit, filter],
        getVillages,
        {
            enabled: Boolean(changeCount),
            onSuccess(res) {
                if (res) setRowsCount(res?.data.count);
            },
        }
    );

    useEffect(() => {
        if (changeCount) {
            setFilter(intermediateFilter);
        }
    }, [changeCount]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}
                >
                    <Grid item xs={12} md="auto">
                        <Typography color="GrayText" variant="h5">
                            Villages
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        spacing={1.5}
                        justifyContent="flex-end"
                    >
                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize ops={["CREATE VILLAGE"]}>
                                <Link
                                    to="/regions/villages/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                        sx={{ minWidth: "20ch" }}
                                        fullWidth
                                    >
                                        add village
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} spacing={1.5}>
                        <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                            <Grid item xs={12} md={4}>
                                <UpdatedSearchableInput
                                    label="District"
                                    api="/region/district"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    multiple={true}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <Grid item xs={12} md={4}>
                            <SearchBar
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setChangeCount(changeCount + 1)}
                                sx={{ minWidth: "20ch", height: "100%" }}
                                startIcon={<FilterAltOutlined />}
                                fullWidth
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            {(!changeCount || !data?.data.rows.length || isError) && (
                <Alert severity="info">
                    {!data?.data.rows.length && isSuccess
                        ? noDataMessage
                        : !hasValue(filter) && !changeCount
                        ? filterSelectionMessage
                        : ""}
                </Alert>
            )}

            <Box sx={{ height: "70%" }}>
                <BaseTable
                    headers={{
                        id: "ID",
                        name: "Name",
                        districtName: "District",
                    }}
                    data={data?.data.rows.map((row: any) => ({
                        ...row,
                        districtName: row.district.name,
                    }))}
                    delEndPoint="/region/village"
                    defaultSelectedHeaders={["id", "name", "districtName"]}
                    feedback={feedback}
                    setFeedback={setFeedback}
                    rowsCount={rowsCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    isLoading={isLoading}
                    load={Boolean(changeCount)}
                    permissions={{
                        edit: ["UPDATE VILLAGE"],
                        delete: ["DELETE VILLAGE"],
                    }}
                    queryKey={[
                        "villages",
                        pagination.page,
                        pagination.limit,
                        filter,
                    ]}
                    reportName="Villages Report"
                />
            </Box>
        </Box>
    );
};

export default Villages;
