import { ViewColumn, ViewColumnOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
    ChangeEvent,
    Dispatch,
    FormEvent,
    MutableRefObject,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../../Utilities/AppContext";
import { GET, PATCH, POST } from "../../../Utilities/BaseService";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { AddAcs } from "./Components/AddAcs";
import { ReadingRow } from "./Components/ReadingRow";

type DateConstraintVariant =
    | "collection-back"
    | "collection-forward"
    | "reading-back"
    | "reading-forward";

type DateConstraint = { key: DateConstraintVariant; value: string };

type Props = {
    updateMode?: boolean;
};

type ReadingTarget = {
    id: number;
    meterNo: string;
    lastReading: number;
};

type AdditionalCharge = {
    head: number;
    amount: number;
    editable: boolean;
};

export type CreateReadingData = {
    select: boolean;
    units: number;
    lineRent: number;
    isLineRentEditable: boolean;
    additionalCharges: AdditionalCharge[];
    arrears: number;
    feedback: { message: string; status: "success" | "error" | null };
};

export enum CurrReadingFrom {
    ZERO = "zero",
    LAST_READING = "last-reading",
}

type ReadingCtxType = {
    // readingsDataRef: MutableRefObject<{ [meterId: string]: CreateReadingData }>;
    readingsData: { [meterId: string]: CreateReadingData };
    setReadingsData: Dispatch<
        SetStateAction<{ [meterId: string]: CreateReadingData }>
    >;
    readingsDataRef: MutableRefObject<{ [meterId: string]: CreateReadingData }>;
    rowChangeCountMap: { [rowIndex: string]: number };
    setRowChangeCountMap: Dispatch<
        SetStateAction<{ [rowIndex: string]: number }>
    >;
    activeMeter: number | null;
    setActiveMeter: Dispatch<SetStateAction<number | null>>;
    mode: boolean;
    currReadingFrom: CurrReadingFrom;
    currReadingInputsRef: MutableRefObject<{
        [rowIndex: string]: HTMLInputElement;
    }>;
    currLineRentInputsRef: MutableRefObject<{
        [rowIndex: string]: HTMLInputElement;
    }>;
    // setCurrReadingFrom: Dispatch<SetStateAction<CurrReadingFrom>>;
};

const fetchMeters = (params: any = {}, updateMode: boolean) => {
    const cleanParams = Object.fromEntries(
        Object.entries(params).filter((e) => e[1] !== "all" && e[1])
    );

    const api = updateMode
        ? "/reading/update-targets"
        : "/customer/meter/reading-targets";

    return GET(api, cleanParams);
};

const createReadings = (
    indivisualReadings: { [key: string]: any },
    commonData: any,
    readingTargets: any[]
) => {
    const selectedMeters = readingTargets.map((m: any) => m.id + "");
    const actualIndvisualReadings = Object.entries(indivisualReadings)
        .filter(
            (entry: any[]) =>
                selectedMeters.includes(entry[0]) && entry[1].select
        )
        .map((entry) => ({ ...entry[1], meterId: entry[0] }));

    return POST("/reading/bulk", {
        ...commonData,
        meters: actualIndvisualReadings,
    });
};

const updateReadings = (
    indivisualReadings: { [key: string]: any },
    commonData: any
) => {
    const actualIndvisualReadings = Object.entries(indivisualReadings)
        .filter((entry: any[]) => entry[1].select)
        .map((entry) => ({
            ...entry[1],
            meterId: parseInt(entry[0]),
            units: parseInt(entry[1].units),
        }));

    return PATCH("/reading/bulk", {
        ...Object.fromEntries(
            Object.entries(commonData).filter((entry) => entry[1])
        ),
        meters: actualIndvisualReadings,
    });
};

const createReadingTableCols = [
    // "",
    "Status",
    "Serial no.",
    "Meter no.",
    "Customer name",
    "Previous reading",
    "Curr. reading",
    "Consumed Units",
    "Line Rent",
    "Additional Charges",
    "Calculations",
    "Surcharge",
    "Connection charges",
];

const initialCommonData = {
    month: "",
    year: "",
    readingDate: "",
    issueDate: "",
    dueDate: "",
};

const initialClearAll = {
    district: false,
    village: false,
    hamlet: false,
    powerstation: false,
};

const initialDefaultRegions = {
    district: "",
    village: "",
    hamlet: "",
};

export const ReadingCtx = createContext({} as ReadingCtxType);

const initialColumnVisibility = Object.fromEntries(
    createReadingTableCols.map((col) => [col, true])
);

// {
//     "Line Rent": true,
//     "Additional Charges": true,
// };

export const AddReadings = ({ updateMode = false }: Props) => {
    const [activeMeter, setActiveMeter] = useState<number | null>(null);
    const [mode, setMode] = useState(updateMode);
    const [id, setId] = useState("");
    const [columnsVisibility, setColumnsVisibility] = useState(
        initialColumnVisibility
    );
    const inputRef = useRef<any>(null);
    const [showColumns, setShowColumns] = useState(false);
    const [currReadingFrom, setCurrReadingFrom] = useState(
        CurrReadingFrom.ZERO
    );
    const [rowChangeCountMap, setRowChangeCountMap] = useState<{
        [rowIndex: string]: number;
    }>({});
    const [filter, setFilter] = useState({
        district: "",
        village: "",
        hamlet: "",
        meterNo: "",
        month: "",
        year: "",
    });
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 500,
        count: 0,
    });
    const [date, setDate] = useState("");
    const [commonData, setCommonData] = useState<any>(initialCommonData);
    const [feedback, setFeedback] = useState("");
    const [clearAll, setClearAll] = useState(initialClearAll);
    const [defaultRegions, setDefaultRegions] = useState(initialDefaultRegions);
    const [fetchTargets, setFetchTargets] = useState(0);
    const viewReadingsBtnRef = useRef<null | HTMLButtonElement>(null);
    const { user } = useContext(AppContext);
    const showColumnsBtn = useRef<HTMLButtonElement | null>(null);
    const location = useLocation();
    const theme = useTheme();
    const currReadingInputsRef = useRef<{
        [rowIndex: number]: HTMLInputElement;
    }>({});
    const currLineRentInputsRef = useRef<{
        [rowIndex: number]: HTMLInputElement;
    }>({});
    const [overallLineRent, setOverallLineRent] = useState(0);
    const [allChecked, setAllChecked] = useState(false);
    const filtersWrapperRef = useRef<HTMLDivElement | null>(null);

    const handleCommonDataChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setCommonData({ ...commonData, [ev.target.name]: ev.target.value });
    };

    const handleColumnVisibilityChange = (column: string) => {
        setColumnsVisibility((prev: any) => {
            const newVisibility = {
                ...prev,
                [column]: !prev[column],
            };

            localStorage.setItem(
                "paymentAgainstBank",
                JSON.stringify(newVisibility)
            );

            return newVisibility;
        });
    };

    const handleReadingTargetOnSuccess = (res: any) => {
        setPagination({ ...pagination, count: parseInt(res.data.count) });

        readingsDataRef.current = res.data.rows.reduce(
            (prev: any, curr: any) => ({
                ...prev,
                [curr.id]: {
                    units: updateMode
                        ? curr.initialReading
                        : readingsDataRef.current[curr.id]?.units || 0,
                    lineRent: curr.lineRent,
                    isLineRentEditable: curr.lineRentEditable,
                    additionalCharges: curr.acs || [],
                    feedback: { message: "", status: null },
                    select: updateMode
                        ? false
                        : Boolean(readingsDataRef.current[curr.id]?.units),
                },
            }),
            {}
        );

        // setReadingsData(readingsDataRef.current);

        setPagination({ ...pagination, count: res.data.count });

        const params = new URLSearchParams(location.search);
        const bulk = params.get("bulk");

        const first_row = res.data.rows[0];

        console.log(bulk, first_row);

        if (bulk?.toString() == "false" && first_row) {
            setCommonData({
                readingDate: first_row.readingDate.split("T")[0],
                issueDate: first_row.issueDate.split("T")[0],
                dueDate: first_row.dueDate.split("T")[0],
                year: filter.year + "",
                month: filter.month,
            });
        }
    };

    const { data: readingTargets, isFetching } = useQuery(
        ["reading-targets", pagination.page, pagination.limit, fetchTargets],
        () => {
            return fetchMeters(
                {
                    ...filter,
                    page: pagination.page + 1,
                    limit: pagination.limit,
                    month: filter.month,
                    year: filter.year,
                    id,
                },
                updateMode
            );
        },

        {
            enabled: Boolean(filter.month) && Boolean(filter.year),
            onSuccess: handleReadingTargetOnSuccess,
            networkMode: "always",
            retry: false,
        }
    );

    const oldestConnectionDate = useMemo(() => {
        if (readingTargets?.data.rows.length) {
            return readingTargets?.data.rows[0].connectionDate;
        }
    }, [readingTargets]);

    const [readingsData, setReadingsData] = useState<{
        [meterId: string]: CreateReadingData;
    }>({});
    const readingsDataRef = useRef(readingsData);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (updateMode) {
            updateMutation.mutate();
        } else {
            addMutation.mutate();
        }
    };

    const addMutation = useMutation(
        () => {
            console.log(readingsDataRef.current);

            return createReadings(
                readingsDataRef.current,
                {
                    ...commonData,
                    month: parseInt(commonData.month),
                    year: parseInt(commonData.year),
                },
                readingTargets.data.rows
            );
        },
        {
            onSuccess(res) {
                const targetResponse = res.data.successes.reduce(
                    (prev: any, curr: any) => ({
                        ...prev,
                        [curr.id]: { ...curr, status: "success" },
                    }),
                    {}
                );
                const readingsDataWithFeedback = Object.fromEntries(
                    Object.entries(readingsDataRef.current).map(([k, v]) => [
                        k,
                        { ...v, feedback: targetResponse[k] },
                    ])
                );

                readingsDataRef.current = readingsDataWithFeedback;

                // setReadingsData(readingsDataRef.current);

                const numberOfSuccesses = res.data.successes.length;

                setFeedback(
                    `${numberOfSuccesses}/${numberOfSuccesses} readings have recorded successfully.`
                );
            },

            onError(err: any) {
                if (!err.response) {
                    // No internet connection or network error
                    setFeedback(
                        "No internet connection found. Please check your network and try again."
                    );
                    return;
                }
                if (!err.response.data.message) {
                    const targetResponse = {
                        ...err.response.data.successes.reduce(
                            (prev: any, curr: any) => ({
                                ...prev,
                                [curr.id]: { ...curr, status: "success" },
                            }),
                            {}
                        ),
                        ...err.response.data.failures.reduce(
                            (prev: any, curr: any) => ({
                                ...prev,
                                [curr.id]: {
                                    message: curr.message,
                                    status: "error",
                                },
                            }),
                            {}
                        ),
                    };

                    const readingsDataWithFeedback = Object.fromEntries(
                        Object.entries(readingsDataRef.current).map(
                            ([k, v]) => [
                                k,
                                { ...v, feedback: targetResponse[k] },
                            ]
                        )
                    );

                    readingsDataRef.current = readingsDataWithFeedback;

                    // setReadingsData(readingsDataRef.current);

                    const numberOfSuccesses =
                        err.response.data.successes.length;
                    const numberOfFailures = err.response.data.failures.length;

                    setFeedback(
                        `${numberOfSuccesses}/${
                            numberOfSuccesses + numberOfFailures
                        } readings have recorded successfully. ${numberOfFailures} records have failed.`
                    );
                } else {
                    setFeedback(err.response.data.message);
                }
            },
        }
    );

    const updateMutation = useMutation(
        () => {
            console.log(readingsDataRef.current);

            return updateReadings(readingsDataRef.current, {
                ...commonData,
                month: parseInt(commonData.month),
                year: parseInt(commonData.year),
            });
        },
        {
            onSuccess(res) {
                const targetResponse = res.data.successes.reduce(
                    (prev: any, curr: any) => ({
                        ...prev,
                        [curr.id]: { ...curr, status: "success" },
                    }),
                    {}
                );
                const readingsDataWithFeedback = Object.fromEntries(
                    Object.entries(readingsDataRef.current).map(([k, v]) => [
                        k,
                        { ...v, feedback: targetResponse[k] },
                    ])
                );

                readingsDataRef.current = readingsDataWithFeedback;

                // setReadingsData(readingsDataRef.current);

                const numberOfSuccesses = res.data.successes.length;

                setFeedback(
                    `${numberOfSuccesses}/${numberOfSuccesses} readings have recorded successfully.`
                );
            },

            onError(err: any) {
                if (!err.response.data.message) {
                    const targetResponse = {
                        ...err.response.data.successes.reduce(
                            (prev: any, curr: any) => ({
                                ...prev,
                                [curr.id]: { ...curr, status: "success" },
                            }),
                            {}
                        ),
                        ...err.response.data.failures.reduce(
                            (prev: any, curr: any) => ({
                                ...prev,
                                [curr.id]: {
                                    message: curr.message,
                                    status: "error",
                                },
                            }),
                            {}
                        ),
                    };

                    const readingsDataWithFeedback = Object.fromEntries(
                        Object.entries(readingsDataRef.current).map(
                            ([k, v]) => [
                                k,
                                { ...v, feedback: targetResponse[k] },
                            ]
                        )
                    );

                    readingsDataRef.current = readingsDataWithFeedback;

                    // setReadingsData(readingsDataRef.current);

                    const numberOfSuccesses =
                        err.response.data.successes.length;
                    const numberOfFailures = err.response.data.failures.length;

                    setFeedback(
                        `${numberOfSuccesses}/${
                            numberOfSuccesses + numberOfFailures
                        } readings have recorded successfully. ${numberOfFailures} records have failed.`
                    );
                } else {
                    setFeedback(err.response.data.message);
                }
            },
        }
    );

    function manipulateDate(
        currentDate: Date,
        daysToAddOrSubtract: number,
        op: "add" | "sub"
    ) {
        const dateObj = new Date(currentDate);

        const millisecondsInADay = 24 * 60 * 60 * 1000;

        let newDate: Date;
        switch (op) {
            case "add":
                newDate = new Date(
                    dateObj.getTime() + daysToAddOrSubtract * millisecondsInADay
                );
                break;

            case "sub":
                newDate = new Date(
                    dateObj.getTime() - daysToAddOrSubtract * millisecondsInADay
                );
                break;
        }

        return newDate;
    }

    function getRestrictedDate(target: DateConstraintVariant) {
        const hasDateRestriction = Boolean(
            user.constraints.find((c: DateConstraint) => c.key === target)
        );

        if (hasDateRestriction) {
            console.log("has date constraints: ", hasDateRestriction);
            const days: number = user.constraints.find(
                (c: DateConstraint) => c.key === target
            ).value;

            switch (target) {
                case "reading-back":
                    const backDate = manipulateDate(new Date(), days, "sub")
                        .toISOString()
                        .slice(0, 10);

                    console.log(backDate, target);

                    return backDate;

                case "reading-forward":
                    const forwardDate = manipulateDate(new Date(), days, "add")
                        .toISOString()
                        .slice(0, 10);

                    console.log(target);

                    return forwardDate;
            }
        } else return undefined;
    }

    function handleSelectAll(
        event: ChangeEvent<HTMLInputElement>,
        select: boolean
    ) {
        setAllChecked(select);

        readingsDataRef.current = Object.fromEntries(
            Object.entries(readingsDataRef.current).map(([k, v]) => [
                k,
                { ...v, select },
            ])
        );

        // setReadingsData(readingsDataRef.current);
    }

    const handleApplyFilter = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const [year, month] = date.split("-");
        setCommonData({ ...commonData, year, month });

        setFilter({ ...filter, month, year });

        setFetchTargets(fetchTargets + 1);
    };

    function handleAllLinerentChange(event: ChangeEvent<HTMLInputElement>) {
        setOverallLineRent(
            event.target.value ? parseInt(event.target.value) : 0
        );

        readingsDataRef.current = Object.fromEntries(
            Object.entries(readingsDataRef.current).map(([k, v]) => [
                k,
                {
                    ...v,
                    lineRent: v.select
                        ? v.isLineRentEditable
                            ? parseInt(event.target.value)
                            : 0
                        : 0,
                },
            ])
        );

        // setReadingsData(readingsDataRef.current);
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const month = params.get("month") ?? "";
        const year = params.get("year") ?? "";
        const meterNo = params.get("meterNo") ?? "";

        console.log({ month, year, meterNo });

        if (!month && !year && !meterNo) return;

        // if (location.state?.id) {
        setFilter((f) => ({
            ...f,
            month,
            year,
            meterNo,
        }));

        setDate(`${year}-${parseInt(month) <= 9 ? `0${month}` : month}`);

        // setCommonData({
        //     readingDate: location.state.readingDate.split("T")[0],
        //     issueDate: location.state.issueDate.split("T")[0],
        //     dueDate: location.state.dueDate.split("T")[0],
        //     year: location.state.year + "",
        //     month:
        //         location.state.month <= 9
        //             ? "0" + location.state.month
        //             : location.state.month,
        // });

        // }
    }, [location.search]);

    const handleDateChange = (ev: any) => {
        setDate(ev.target.value);
    };

    useEffect(() => {
        setMode(updateMode);

        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id") ?? "";

            setId(_id);
        }
    }, [updateMode]);

    useEffect(() => {
        if (filter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });

            setFilter({ ...filter, district: "", village: "", hamlet: "" });
        }

        if (filter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
        }

        if (filter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
        }

        if (defaultRegions.district !== filter.district) {
            setClearAll({
                ...clearAll,
                village: true,
                hamlet: true,
                powerstation: true,
            });

            setDefaultRegions({
                ...defaultRegions,
                district: filter.district,
            });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [filter.district, filter.village, filter.hamlet]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <ReadingCtx.Provider
            value={{
                readingsData,
                setReadingsData,
                activeMeter,
                setActiveMeter,
                mode,
                readingsDataRef,
                currReadingFrom,
                currReadingInputsRef,
                currLineRentInputsRef,
                // setCurrReadingFrom,
                rowChangeCountMap,
                setRowChangeCountMap,
            }}
        >
            <Grid
                ref={filtersWrapperRef}
                container
                justifyContent="space-between"
                spacing={1}
                // position={"sticky"}
                // top={theme.spacing(8)}
                bgcolor={"white"}
                zIndex={10}
            >
                <Grid item xs={12}>
                    <AddAcs />

                    <Typography variant="h5" sx={{ my: theme.spacing(3) }}>
                        {updateMode ? "Update Readings" : "Add Readings"}
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <UpdatedSearchableInput
                        label="District"
                        api="/region/district"
                        filter={filter}
                        setFilter={setFilter}
                        clearAll={clearAll.district}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <UpdatedSearchableInput
                        label="Village"
                        api="/region/village"
                        filter={filter}
                        setFilter={setFilter}
                        dep={filter.district}
                        params={{ district: filter.district }}
                        clearAll={clearAll.village}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <UpdatedSearchableInput
                        label="Hamlet"
                        api="/region/hamlet"
                        filter={filter}
                        setFilter={setFilter}
                        dep={filter.village}
                        params={
                            filter.village && filter.village !== "all"
                                ? { village: [filter.village] }
                                : { village: [0] }
                        }
                        multiple={false}
                        clearAll={clearAll.hamlet}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <TextField
                        size="small"
                        name="meterNo"
                        label="Meter no."
                        fullWidth
                        value={filter.meterNo}
                        onChange={(e) =>
                            setFilter({ ...filter, meterNo: e.target.value })
                        }
                    />
                </Grid>

                <Grid
                    container
                    item
                    xs={12}
                    component="form"
                    onSubmit={handleApplyFilter}
                    spacing={1}
                >
                    <Grid item xs={12} mb={2}>
                        <Divider>
                            <Chip label="Date" size="small" />
                        </Divider>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        {/* <MultiPermissionAuthorize
                            ops={
                                updateMode
                                    ? ["UPDATE DATE_YEAR"]
                                    : ["CREATE DATE_YEAR"]
                            }
                        > */}
                        <TextField
                            fullWidth
                            size="small"
                            name="date"
                            label="Billing Month"
                            value={date}
                            onChange={handleDateChange}
                            required
                            type="month"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // tabIndex={5}
                        />
                        {/* </MultiPermissionAuthorize> */}
                    </Grid>

                    <Grid item xs={12} md={3}>
                        {/* <MultiPermissionAuthorize
                            ops={
                                updateMode
                                    ? ["UPDATE READING_DATE"]
                                    : ["CREATE READING_DATE"]
                            }
                        > */}
                        <TextField
                            name="readingDate"
                            value={commonData.readingDate}
                            label="Reading Date"
                            fullWidth
                            size="small"
                            type="date"
                            inputProps={
                                // oldestConnectionDate
                                //     ? {
                                //           min: oldestConnectionDate,
                                //       }
                                //     : undefined
                                {
                                    min: getRestrictedDate("reading-back"),
                                    max: getRestrictedDate("reading-forward"),
                                }
                            }
                            onChange={handleCommonDataChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // tabIndex={6}
                        />
                        {/* </MultiPermissionAuthorize> */}
                    </Grid>

                    <Grid item xs={12} md={3}>
                        {/* <MultiPermissionAuthorize
                            ops={
                                updateMode
                                    ? ["UPDATE ISSUE_DATE"]
                                    : ["CREATE ISSUE_DATE"]
                            }
                        > */}
                        <TextField
                            name="issueDate"
                            value={commonData.issueDate}
                            label="Issue Date"
                            fullWidth
                            size="small"
                            type="date"
                            onChange={handleCommonDataChange}
                            inputProps={
                                date
                                    ? {
                                          min: new Date(date)
                                              .toISOString()
                                              .split("T")[0],
                                      }
                                    : undefined
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // tabIndex={7}
                        />
                        {/* </MultiPermissionAuthorize> */}
                    </Grid>

                    <Grid item xs={12} md={3}>
                        {/* <MultiPermissionAuthorize
                            ops={
                                updateMode
                                    ? ["UPDATE DUE_DATE"]
                                    : ["CREATE DUE_DATE"]
                            }
                        > */}
                        <TextField
                            name="dueDate"
                            value={commonData.dueDate}
                            label="Due Date"
                            fullWidth
                            size="small"
                            type="date"
                            onChange={handleCommonDataChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // tabIndex={8}
                        />
                        {/* </MultiPermissionAuthorize> */}
                    </Grid>

                    {/* {!Boolean(date) && (
                        <Grid item xs={12} mt={1}>
                            <Alert severity="info">
                                Please select a month!
                            </Alert>
                        </Grid>
                    )} 

                    <Grid item xs={12} mt={2}>
                        <Divider>
                            <Chip label="Pagination" size="small" />
                        </Divider>
                    </Grid> */}

                    <Grid item xs={12}>
                        <Button
                            ref={viewReadingsBtnRef}
                            variant="outlined"
                            color="secondary"
                            type="submit"
                            fullWidth
                            // tabIndex={9}
                        >
                            view readings
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={(theme) => ({ py: theme.spacing(2) })}
                // position={"sticky"}
                // top={(filtersWrapperRef.current?.clientHeight ?? 0) + 64}
                bgcolor={"white"}
                zIndex={10}
            >
                <Box display="flex" gap={1} alignItems="center">
                    {!updateMode && (
                        <TextField
                            size="small"
                            sx={(theme) => ({ minWidth: theme.spacing(24) })}
                            select
                            label="Set Current Reading From"
                            value={currReadingFrom}
                            onChange={(e) =>
                                setCurrReadingFrom(
                                    e.target.value as CurrReadingFrom
                                )
                            }
                        >
                            {Object.entries(CurrReadingFrom).map(
                                ([key, value]) => (
                                    <MenuItem
                                        key={key}
                                        value={value}
                                        sx={() => ({
                                            textTransform: "capitalize",
                                        })}
                                    >
                                        {key
                                            .replaceAll("_", " ")
                                            .toLowerCase()
                                            .split(" ")
                                            .map(
                                                (w) =>
                                                    w[0].toUpperCase() +
                                                    w.slice(1)
                                            )
                                            .join(" ")}
                                    </MenuItem>
                                )
                            )}
                        </TextField>
                    )}

                    <Tooltip title="Select Columns">
                        <IconButton
                            ref={showColumnsBtn}
                            onClick={() => setShowColumns(true)}
                        >
                            {showColumns ? (
                                <ViewColumn
                                    htmlColor={theme.palette.primary.main}
                                />
                            ) : (
                                <ViewColumnOutlined />
                            )}
                        </IconButton>
                    </Tooltip>

                    <Menu
                        open={showColumns}
                        anchorEl={showColumnsBtn.current}
                        onClose={() => setShowColumns(false)}
                        elevation={4}
                        variant="menu"
                    >
                        {Object.keys(columnsVisibility).map((col) => (
                            <MenuItem
                                key={col}
                                style={{ paddingLeft: 0 }}
                                onClick={() =>
                                    handleColumnVisibilityChange(col)
                                }
                            >
                                <Checkbox
                                    size="small"
                                    checked={
                                        columnsVisibility[
                                            col as keyof typeof columnsVisibility
                                        ]
                                    } // Checked if the column is visible
                                    // onChange={() =>
                                    //     handleColumnVisibilityChange(col)
                                    // }
                                    color="primary"
                                />

                                <Typography>{col}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>

                <TablePagination
                    rowsPerPageOptions={[10, 50, 100, 500]}
                    component="div"
                    onPageChange={(ev, page) =>
                        setPagination({ ...pagination, page: page })
                    }
                    onRowsPerPageChange={(ev) =>
                        setPagination({
                            ...pagination,
                            limit: parseInt(ev.target.value),
                            page: 0,
                        })
                    }
                    count={pagination.count}
                    page={pagination.page}
                    rowsPerPage={pagination.limit}
                />
            </Box>

            {Boolean(date) && isFetching ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                </Box>
            ) : fetchTargets > 0 ? (
                <Box component="form" onSubmit={handleSubmit}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            onChange={handleSelectAll}
                                            size="small"
                                            checked={allChecked}
                                            // checked={Object.entries(
                                            //     readingsDataRef.current
                                            // ).every(([_, r]) => r.select)}
                                        />
                                    </TableCell>

                                    {createReadingTableCols
                                        .filter(
                                            (col) =>
                                                columnsVisibility[
                                                    col as keyof typeof columnsVisibility
                                                ]
                                        )
                                        .map((col) => (
                                            <TableCell key={col}>
                                                {col === "Line Rent" ? (
                                                    <TextField
                                                        label={col}
                                                        size="small"
                                                        type="number"
                                                        value={overallLineRent}
                                                        onChange={
                                                            handleAllLinerentChange
                                                        }
                                                        InputProps={{
                                                            inputProps: {
                                                                style: {
                                                                    appearance:
                                                                        "textfield",
                                                                },
                                                            },
                                                        }}
                                                        sx={{
                                                            "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                                                                {
                                                                    display:
                                                                        "none",
                                                                },
                                                            "& input[type=number]":
                                                                {
                                                                    MozAppearance:
                                                                        "textfield", // Removes spinner in Firefox
                                                                },
                                                        }}
                                                    />
                                                ) : (
                                                    col
                                                )}
                                            </TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {readingTargets?.data.rows.map(
                                    (target: ReadingTarget, idx: number) => (
                                        <ReadingRow
                                            overallLineRent={overallLineRent}
                                            index={idx}
                                            columnVisibility={columnsVisibility}
                                            key={target.id}
                                            target={target}
                                            updateMode={updateMode}
                                            count={
                                                idx +
                                                1 +
                                                pagination.limit *
                                                    pagination.page
                                            }
                                        />
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {readingTargets?.data.rows.length == 0 && (
                        <Alert severity="info"> No readings found.</Alert>
                    )}

                    {addMutation.isSuccess ||
                        (addMutation.isError && (
                            <Alert
                                sx={{ mt: 2 }}
                                severity={
                                    addMutation.isSuccess ? "success" : "error"
                                }
                            >
                                {feedback}
                            </Alert>
                        ))}

                    <Button
                        variant="outlined"
                        fullWidth
                        color="secondary"
                        sx={{ my: 2 }}
                        type="submit"
                        disabled={
                            updateMutation.isLoading || addMutation.isLoading
                        }
                        endIcon={
                            updateMutation.isLoading ||
                            addMutation.isLoading ? (
                                <CircularProgress size={20} />
                            ) : undefined
                        }
                    >
                        {updateMode
                            ? updateMutation.isLoading
                                ? "updating readings..."
                                : "update readings"
                            : addMutation.isLoading
                            ? "adding readings..."
                            : "add readings"}
                    </Button>
                </Box>
            ) : null}
        </ReadingCtx.Provider>
    );
};
