import { Add, FilterAltOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET } from "../../Utilities/BaseService";
import {
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../Utilities/constants";
import { MultiPermissionAuthorize } from "../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../Utilities/UpdatedSearchableInput";
import BaseTable from "../Table/BaseTable";
import SearchBar from "../Table/Components/SearchBar";

type Filter = {
    search: string;
    district: number[] | "" | "all";
};

const initialFilter: Filter = {
    search: "",
    district: "",
};

const PowerStation = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [filter, setFilter] = useState(initialFilter);
    const [intermediateFilter, setIntermediateFilter] =
        useState<Filter>(initialFilter);
    const [changeCount, setChangeCount] = useState(0);
    const [feedback, setFeedback] = useState("");
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));

    const getPowerStations = () => {
        return GET("/powerstation", {
            page: pagination.page + 1,
            limit: pagination.limit,
            search: filter.search,
            otherOps:
                filter.district === "all" || filter.district.length === 0
                    ? undefined
                    : JSON.stringify([
                          {
                              op: "in",
                              operands: [filter.district],
                              isDate: false,
                              col: "district",
                          },
                      ]),
        });
    };

    const { data, isLoading } = useQuery(
        ["powerstations", pagination.page, pagination.limit, filter],
        getPowerStations,
        {
            onSuccess(res) {
                if (res) setRowsCount(res?.data.count);
            },
            enabled: Boolean(changeCount),
        }
    );

    useEffect(() => {
        if (changeCount) {
            setFilter(intermediateFilter);
        }
    }, [changeCount]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}
                >
                    <Grid item xs={12} md="auto">
                        <Typography color="GrayText" variant="h5">
                            Powerstations
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        justifyContent="flex-end"
                        spacing={1.5}
                    >
                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize
                                ops={["CREATE POWERSTATION"]}
                            >
                                <Link
                                    to="/powerstations/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{ width: "28ch", height: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                        fullWidth
                                    >
                                        add powerstation
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} spacing={1} mt={1}>
                        <Grid item xs={12} md={4}>
                            <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                                <Grid item flex={1}>
                                    <UpdatedSearchableInput
                                        label="Districts"
                                        _name="district"
                                        api="/region/district"
                                        filter={intermediateFilter}
                                        setFilter={setIntermediateFilter}
                                        multiple={true}
                                    />
                                </Grid>
                            </MultiPermissionAuthorize>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <SearchBar
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setChangeCount(changeCount + 1)}
                                sx={{ width: "100%", height: "100%" }}
                                startIcon={<FilterAltOutlined />}
                                fullWidth
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            {data?.data.rows.length > 0 ? (
                <Box sx={{ height: "60%" }}>
                    <BaseTable
                        headers={{
                            id: "ID",
                            name: "Name",
                            districtName: "District",
                        }}
                        data={data?.data.rows.map((row: any) => ({
                            ...row,
                            districtName: row.district.name,
                        }))}
                        load={Boolean(changeCount)}
                        defaultSelectedHeaders={["id", "name", "districtName"]}
                        delEndPoint="/powerstation"
                        rowsCount={rowsCount}
                        pagination={pagination}
                        setPagination={setPagination}
                        isLoading={isLoading}
                        feedback={feedback}
                        setFeedback={setFeedback}
                        permissions={{
                            edit: ["UPDATE POWERSTATION"],
                            delete: ["DELETE POWERSTATION"],
                        }}
                        queryKey={[
                            "powerstations",
                            pagination.page,
                            pagination.limit,
                            filter,
                        ]}
                        reportName="Powerstations Report"
                    />
                </Box>
            ) : hasValue(filter) ? (
                <Alert severity="info">{noDataMessage}</Alert>
            ) : (
                <Alert severity="info">{filterSelectionMessage}</Alert>
            )}
        </Box>
    );
};

export default PowerStation;
