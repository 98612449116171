import { FilterAltOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    Grid,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { GET } from "../../../Utilities/BaseService";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";
import { currencyFormatter } from "../Bill/Cells/Last12Summary";
import ArrearsReportDetail, {
    MonthlyDefaulterBreakdown,
} from "./ArrearsReportDetail";

type Filter = {
    search: string;
    district: number | "" | "all";
    village: number | "" | "all";
    hamlet: number | "" | "all";
    startDate: any;
    endDate: any;
};

const initialFilter: Filter = {
    search: "",
    district: "",
    village: "",
    hamlet: "",
    startDate: "",
    endDate: "",
};

const initialClearAll = {
    district: false,
    village: false,
    hamlet: false,
};

export const getArrearsReport = (pagination: any, filter: any) => {
    console.log(pagination);

    return GET("/reading/defaulters-report", {
        meterNo: filter.search ? filter.search : "",
        district:
            filter.district && filter.district !== "all"
                ? filter.district
                : undefined,
        village:
            filter.village && filter.village !== "all"
                ? filter.village
                : undefined,
        hamlet:
            filter.hamlet && filter.hamlet !== "all"
                ? filter.hamlet
                : undefined,

        startDate: filter.startDate ? filter.startDate : undefined,
        endDate: filter.endDate ? filter.endDate : undefined,
        page: pagination.page + 1,
        limit: pagination.limit,
    });
};

const ArrearsReport = () => {
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    // const [load, setLoad] = useState(false);
    const [filter, setFilter] = useState<any>(initialFilter);
    const [feedback, setFeedback] = useState("");
    const [changeCount, setChangeCount] = useState(0);
    const [rowsCount, setRowsCount] = useState(0);
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));
    const [intermediateFilter, setIntermediateFilter] =
        useState<Filter>(initialFilter);

    const [clearAll, setClearAll] = useState(initialClearAll);

    const { data, isLoading } = useQuery(
        ["arrears", pagination.page, pagination.limit, filter, changeCount],
        () => getArrearsReport(pagination, filter),
        {
            onSuccess(res) {
                if (res) setRowsCount(res?.data.count);
            },
        }
    );

    useEffect(() => {
        if (intermediateFilter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
            });
            setIntermediateFilter({
                ...intermediateFilter,
                district: "",
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
            setIntermediateFilter({
                ...intermediateFilter,
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
            setIntermediateFilter({ ...intermediateFilter, hamlet: "" });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
            });
        };
    }, [
        intermediateFilter.district,
        intermediateFilter.village,
        intermediateFilter.hamlet,
    ]);

    const calcArrearsWithoutSurcharge = useMemo(
        () => (row: MonthlyDefaulterBreakdown) => {
            const { Total, Collection, PMCCollection } = row;

            const surcharge =
                Total - ((Collection || 0) + (PMCCollection || 0));

            return currencyFormatter.format(surcharge);
        },
        [data?.data?.rows?.length]
    );

    const calcArrearsWithSurcharge = useMemo(
        () => (row: MonthlyDefaulterBreakdown) => {
            const {
                Total,
                AppliedSurcharge,
                PaidSurcharge,
                Collection,
                PMCCollection,
                AppliedSurchargeWhenNoCollection,
                surchargeAdj,
            } = row;

            const appliedSurcharge = AppliedSurchargeWhenNoCollection ?? 0;
            const surcharge =
                Total +
                appliedSurcharge +
                (AppliedSurcharge || 0) -
                ((PaidSurcharge || 0) +
                    (surchargeAdj || 0) +
                    (Collection || 0) +
                    (PMCCollection || 0));

            return currencyFormatter.format(surcharge);
        },
        [data?.data?.rows?.length]
    );

    useEffect(() => {
        if (changeCount) {
            setFilter({ ...filter, ...intermediateFilter });
        }
    }, [changeCount]);

    useEffect(() => {
        if (intermediateFilter.search !== "") return;

        setChangeCount((n) => n + 1);
    }, [intermediateFilter.search]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 64px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid container>
                    <Grid item xs={12} md="auto">
                        <Typography variant="h5" color="GrayText">
                            Arrears Report
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={1} mt={1}>
                    <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                        <Grid item xs={12} md={6} lg={4}>
                            <UpdatedSearchableInput
                                api="/region/district"
                                label="District"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                clearAll={clearAll.district}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                        <Grid item xs={12} md={6} lg={4}>
                            <UpdatedSearchableInput
                                api="/region/village"
                                label="Village"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                dep={intermediateFilter.district}
                                params={{
                                    district: intermediateFilter.district,
                                }}
                                clearAll={clearAll.village}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                        <Grid item xs={12} md={6} lg={4}>
                            <UpdatedSearchableInput
                                api="/region/hamlet"
                                label="Hamlet"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                dep={intermediateFilter.village}
                                params={Object.fromEntries(
                                    Object.entries(intermediateFilter)
                                        .filter(
                                            ([k, v]) =>
                                                [
                                                    "district",
                                                    "village",
                                                ].includes(k) &&
                                                v &&
                                                v !== "all"
                                        )
                                        .map(([k, v]) => [k, [v]])
                                )}
                                clearAll={clearAll.hamlet}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <Grid item xs={12} md={6} lg={4}>
                        <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="Star Date"
                            size="small"
                            type="date"
                            name="startDate"
                            value={intermediateFilter.startDate}
                            onChange={(e) =>
                                setIntermediateFilter({
                                    ...intermediateFilter,
                                    startDate: e.target.value,
                                })
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="End Date"
                            size="small"
                            type="date"
                            name="endDate"
                            value={intermediateFilter.endDate}
                            required={Boolean(intermediateFilter.startDate)}
                            onChange={(e) =>
                                setIntermediateFilter({
                                    ...intermediateFilter,
                                    endDate: e.target.value,
                                })
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <SearchBar
                            filter={intermediateFilter}
                            setFilter={setIntermediateFilter}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                                setChangeCount(changeCount + 1);
                                // setLoad(true);
                            }}
                            sx={{ height: "100%" }}
                            startIcon={<FilterAltOutlined />}
                            fullWidth
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Card>

            <Box sx={{ height: "70%" }}>
                {!(
                    (intermediateFilter.district &&
                        intermediateFilter.village) ||
                    intermediateFilter.search
                ) ? (
                    <Alert severity="info" sx={{ mt: "1rem" }}>
                        Please select "District, Village, Hamlet" or enter
                        "Meter No"
                    </Alert>
                ) : (
                    <BaseTable
                        headers={{
                            meterId: "ID",
                            customerName: "Name",
                            fatherName: "fatherName",
                            cnic: "Cnic",
                            mobileNumber: "mobileNumber",
                            meterNo: "meterNo",
                            districtName: "District",
                            districtId: "districtId",
                            villageId: "villageId",
                            villageName: "Village",
                            hamletName: "Hamlet",
                            hamletId: "hamletId",
                            arrearsWithoutSurcharge:
                                "Arrears Without Surcharge",
                            arrearsWithSurcharge: "Arrears With Surcharge",
                            details: "Details",
                        }}
                        defaultSelectedHeaders={[
                            "customerName",
                            "fatherName",
                            "cnic",
                            "mobileNumber",
                            "meterNo",
                            "districtName",
                            "villageName",
                            "hamletName",
                            "arrearsWithoutSurcharge",
                            "arrearsWithSurcharge",
                            "details",
                        ]}
                        data={data?.data.rows.map((row: any) => ({
                            ...row,
                            details: <ArrearsReportDetail row={row} />,
                            arrearsWithoutSurcharge:
                                calcArrearsWithoutSurcharge(row),
                            arrearsWithSurcharge: calcArrearsWithSurcharge(row),
                            meterNo: `${row.meterNo}`,
                        }))}
                        delEndPoint=""
                        feedback={feedback}
                        setFeedback={setFeedback}
                        rowsCount={rowsCount}
                        pagination={pagination}
                        setPagination={setPagination}
                        isLoading={isLoading}
                        permissions={{
                            edit: [""],
                            delete: [""],
                        }}
                        load={filter.village || filter.search}
                        queryKey={[
                            "arrears",
                            pagination.page,
                            pagination.limit,
                            filter.search,
                        ]}
                        reportName="Arrears List"
                    />
                )}
            </Box>
        </Box>
    );
};

export default ArrearsReport;
