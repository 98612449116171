import {
    ReceiptLongOutlined,
    ViewColumn,
    ViewColumnOutlined,
} from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    ChangeEvent,
    FormEvent,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { DataImportService } from "../../../Services/DataImportService";
import { AppContext } from "../../../Utilities/AppContext";
import { GET, POST } from "../../../Utilities/BaseService";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { CollecteesFilter } from "./CollecteesFilter";
import { CollectionRow } from "./CollectionRow";

type DateConstraintVariant =
    | "collection-back"
    | "collection-forward"
    | "reading-back"
    | "reading-forward";

type DateConstraint = { key: DateConstraintVariant; value: string };

export const fetchCollectees = (filter: any) => {
    return GET(
        "/reading/collection/collectees",
        Object.fromEntries(
            Object.entries(filter).filter(
                (entry) => Boolean(entry[1]) && entry[1] !== "all"
            )
        )
    );
};

export const collectByMeter = (data: any) => {
    console.log(data);
    return POST("/reading/collection/collect-by-meter", data);
};

export const fetchPaymentMethods = () => {
    return GET("/reading/payment-method");
};

const collectionBillCols = [
    // "",
    "Response",
    "Serial no.",
    "Meter no.",
    "Customer",
    "Property name",
    "Total",
    "Collect amount",
    "Adjustment",
    "Payment Method",
    "Description",
];

const initialColumnVisibility = Object.fromEntries(
    collectionBillCols.map((col) => [col, true])
);

export const CollectBills = () => {
    const [columnsVisibility, setColumnsVisibility] = useState(
        initialColumnVisibility
    );
    const [allChecked, setAllChecked] = useState(false);
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        limit: 500,
    });
    const [filter, setFilter] = useState({
        meterNo: "",
        id: "",
        district: "",
        village: "",
        hamlet: "",
        paymentMethod: "",
        refetchCount: 0,
    });
    const [collectionResults, setCollectionResults] = useState<any>({});
    const [activeResult, setActiveResult] = useState<any>(null);
    const [feedback, setFeedback] = useState({
        message: "",
        show: false,
        severity: null,
    });
    const [showColumns, setShowColumns] = useState(false);

    console.log(filter.meterNo);

    const [loading, setLoading] = useState(false);
    const [collectionDate, setCollectionDate] = useState<string>(
        new Date().toISOString().split("T")[0]
    );
    // const [collections, setCollections] = useState<{
    //     [key: number]: RawCollection;
    // }>({});
    const collectionsRef = useRef<{ [key: number]: RawCollection }>({});
    const [checkAll, setCheckAll] = useState(false);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<null | {
        id: string;
        name: string;
    }>(null);
    const theme = useTheme();
    const { user } = useContext(AppContext);
    const amountInputsRef = useRef<{ [rowIndex: number]: HTMLInputElement }>(
        {}
    );
    const adjustmentInputsRef = useRef<{
        [rowIndex: number]: HTMLInputElement;
    }>({});

    const queryClient = useQueryClient();

    const shouldFilter = () => {
        return (
            Boolean(filter.id) ||
            Boolean(filter.meterNo) ||
            (Boolean(filter.district) &&
                Boolean(filter.village) &&
                Boolean(filter.hamlet))
        );
    };

    const handleColumnVisibilityChange = (column: string) => {
        setColumnsVisibility((prev: any) => {
            const newVisibility = {
                ...prev,
                [column]: !prev[column],
            };

            return newVisibility;
        });
    };

    const { data: collectionDetailsResponse, isFetching } = useQuery(
        [
            "collectees",
            filter.district,
            filter.village,
            filter.hamlet,
            filter.id,
            filter.meterNo,
            pagination.page,
            pagination.limit,
            filter.refetchCount,
        ],
        () =>
            fetchCollectees({
                ...filter,
                page: pagination.page + 1,
                limit: pagination.limit,
            }),
        {
            enabled: shouldFilter(),
            onSuccess(res) {
                if (res.data) {
                    setPagination({
                        ...pagination,
                        count: res.data?.count ?? 0,
                    });

                    collectionsRef.current = res.data.rows.reduce(
                        (prev: any, curr: Collectee) => (
                            console.log(curr),
                            {
                                ...prev,
                                [curr.id]: {
                                    checked:
                                        Boolean(
                                            collectionsRef.current[curr.id]
                                                ?.amount ||
                                                collectionsRef.current[curr.id]
                                                    ?.checked
                                        ) || false,
                                    amount:
                                        collectionsRef.current[curr.id]
                                            ?.amount ?? "",
                                    paymentMethod:
                                        collectionsRef.current[curr.id]
                                            ?.paymentMethod || null,

                                    description:
                                        collectionsRef.current[curr.id]
                                            ?.description ?? "",
                                    adjustment:
                                        collectionsRef.current[curr.id]
                                            ?.adjustment ?? "",

                                    meterNo: curr?.meterNo,
                                },
                            }
                        ),
                        {}
                    );

                    // setCollections(collectionsRef.current);
                }
            },
        }
    );
    const showColumnsBtn = useRef<HTMLButtonElement | null>(null);

    const { data: paymentMethodsRes } = useQuery(["payment-methods"], () =>
        fetchPaymentMethods()
    );

    const mutation = useMutation(["collect"], (data: any) =>
        collectByMeter(data)
    );

    const handleCollectBills = async (ev: FormEvent<any>) => {
        ev.preventDefault();
        setLoading(true);

        if (!collectionDate) return;

        const actualPayments = Object.entries(collectionsRef.current)
            .filter((entry) => entry[1].checked)
            .map((entry) => ({
                ...entry[1],
                paymentName: entry[1].paymentMethod!.name,
                meter: entry[0],
                paymentMethod: entry[1].paymentMethod!.id,
                collectionDate: collectionDate,
            }));

        const [data, err] = await DataImportService.createCollectionBulk({
            collections: actualPayments,
        });

        let successes: { meter: string; success: boolean; message: string }[] =
            [];
        let failures: { meter: string; success: boolean; message: string }[] =
            [];

        if (data) {
            successes = data.successes;
            failures = data.failures;
            // setRefetchCount((c) => c + 1);
        } else {
            successes = err.response.data.successes;
            failures = err.response.data.failures;
        }
        setLoading(false);

        setCollectionResults(() => ({
            ...successes.reduce(
                (
                    prev: {
                        [meterId: string]: {
                            success: boolean;
                            message: string;
                        };
                    },
                    curr
                ) => {
                    return {
                        ...prev,
                        [curr.meter]: {
                            success: true,
                            message: curr.message,
                            //
                        },
                    };
                },
                {}
            ),

            ...failures.reduce(
                (
                    prev: {
                        [meterId: string]: {
                            success: boolean;
                            message: string;
                        };
                    },
                    curr
                ) => {
                    return {
                        ...prev,
                        [curr.meter]: {
                            success: false,
                            message: curr.message,
                            //
                        },
                    };
                },
                {}
            ),
        }));
    };
    function manipulateDate(
        currentDate: Date,
        daysToAddOrSubtract: number,
        op: "add" | "sub"
    ) {
        const dateObj = new Date(currentDate);

        const millisecondsInADay = 24 * 60 * 60 * 1000;

        let newDate: Date;
        switch (op) {
            case "add":
                newDate = new Date(
                    dateObj.getTime() + daysToAddOrSubtract * millisecondsInADay
                );
                break;

            case "sub":
                newDate = new Date(
                    dateObj.getTime() - daysToAddOrSubtract * millisecondsInADay
                );
                break;
        }

        return newDate;
    }

    function getRestrictedDate(target: DateConstraintVariant) {
        const hasDateRestriction = Boolean(
            user.constraints.find((c: DateConstraint) => c.key === target)
        );

        if (hasDateRestriction) {
            const days: number = user.constraints.find(
                (c: DateConstraint) => c.key === target
            ).value;

            switch (target) {
                case "collection-back":
                    const backDate = manipulateDate(new Date(), days, "sub")
                        .toISOString()
                        .slice(0, 10);

                    return backDate;

                case "collection-forward":
                    const forwardDate = manipulateDate(new Date(), days, "add")
                        .toISOString()
                        .slice(0, 10);

                    return forwardDate;
            }
        } else return undefined;
    }

    function handleSelectAll(
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) {
        console.log(checked);
        setAllChecked(checked);

        console.log(
            (collectionsRef.current = Object.fromEntries(
                Object.entries(collectionsRef.current).map(([k, v]) => [
                    k,
                    { ...v, checked },
                ])
            ))
        );
        collectionsRef.current = Object.fromEntries(
            Object.entries(collectionsRef.current).map(([k, v]) => [
                k,
                { ...v, checked },
            ])
        );

        // setReadingsData(readingsDataRef.current);
    }

    useEffect(() => {
        if (filter.paymentMethod) {
            collectionsRef.current = Object.fromEntries(
                Object.entries(collectionsRef.current).map(([k, v]) => [
                    k,
                    {
                        ...v,
                        paymentMethod:
                            filter.paymentMethod === "all"
                                ? null
                                : (filter.paymentMethod as any),
                    },
                ])
            );
        }
    }, [filter.paymentMethod]);

    return (
        <Grid container spacing={1}>
            <CollecteesFilter
                filter={filter}
                setFilter={setFilter}
                billsFetchStatus={isFetching}
            />

            <Grid item xs={12} display="flex" justifyContent="space-between">
                <div />

                <TablePagination
                    component="div"
                    onPageChange={(ev, page) =>
                        setPagination({
                            ...pagination,
                            page: page,
                        })
                    }
                    onRowsPerPageChange={(ev) =>
                        setPagination({
                            ...pagination,
                            limit: parseInt(ev.target.value),
                            page: 0,
                        })
                    }
                    count={pagination.count}
                    page={pagination.page}
                    rowsPerPage={pagination.limit}
                    rowsPerPageOptions={[50, 100, 250, 500]}
                />
            </Grid>

            {!shouldFilter() ? (
                <Grid item xs={12}>
                    <Alert severity="info">
                        Enter a meter no. or meter ID or select a district,
                        village, and hamlet.
                    </Alert>
                </Grid>
            ) : (
                <>
                    <Tooltip title="Select Columns">
                        <IconButton
                            ref={showColumnsBtn}
                            onClick={() => setShowColumns(true)}
                        >
                            {showColumns ? (
                                <ViewColumn
                                    htmlColor={theme.palette.primary.main}
                                />
                            ) : (
                                <ViewColumnOutlined />
                            )}
                        </IconButton>
                    </Tooltip>

                    <Menu
                        open={showColumns}
                        anchorEl={showColumnsBtn.current}
                        onClose={() => setShowColumns(false)}
                        elevation={4}
                        variant="menu"
                    >
                        {Object.keys(columnsVisibility).map((col) => (
                            <MenuItem
                                key={col}
                                style={{ paddingLeft: 0 }}
                                onClick={() =>
                                    handleColumnVisibilityChange(col)
                                }
                            >
                                <Checkbox
                                    size="small"
                                    checked={
                                        columnsVisibility[
                                            col as keyof typeof columnsVisibility
                                        ]
                                    } // Checked if the column is visible
                                    // onChange={() =>
                                    //     handleColumnVisibilityChange(col)
                                    // }
                                    color="primary"
                                />

                                <Typography>{col}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                    <Grid item xs={12}>
                        {isFetching ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    spacing={1}
                                    xs={12}
                                    component="form"
                                    onSubmit={handleCollectBills}
                                >
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            size="small"
                                            name="collectionDate"
                                            label="Collection Date"
                                            InputLabelProps={{ shrink: true }}
                                            type="date"
                                            inputProps={{
                                                min: getRestrictedDate(
                                                    "collection-back"
                                                ),
                                                max: getRestrictedDate(
                                                    "collection-forward"
                                                ),
                                            }}
                                            value={collectionDate}
                                            onChange={(ev) =>
                                                setCollectionDate(
                                                    ev.target.value
                                                )
                                            }
                                        />
                                    </Grid>

                                    {!filter.meterNo && (
                                        <Grid item xs={6}>
                                            <UpdatedSearchableInput
                                                api="/reading/payment-method"
                                                label="Payment Method"
                                                _name="paymentMethod"
                                                disabled={
                                                    !collectionDetailsResponse
                                                        ?.data.rows
                                                }
                                                setOutput={(val) => {
                                                    setDefaultPaymentMethod(
                                                        val
                                                    );
                                                    collectionsRef.current =
                                                        Object.fromEntries(
                                                            Object.entries(
                                                                collectionsRef.current
                                                            ).map(([k, v]) => [
                                                                k,
                                                                {
                                                                    ...v,
                                                                    paymentMethod:
                                                                        v.checked
                                                                            ? val
                                                                            : null,
                                                                },
                                                            ])
                                                        );
                                                }}
                                            />
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <TableContainer>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow
                                                        sx={{
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        <TableCell>
                                                            <FormControl>
                                                                <Checkbox
                                                                    onChange={
                                                                        handleSelectAll
                                                                    }
                                                                    size="small"
                                                                    checked={
                                                                        allChecked
                                                                    }
                                                                    // checked={Object.entries(
                                                                    //     readingsDataRef.current
                                                                    // ).every(([_, r]) => r.select)}
                                                                />
                                                            </FormControl>
                                                        </TableCell>

                                                        {collectionBillCols
                                                            .filter(
                                                                (col) =>
                                                                    columnsVisibility[
                                                                        col as keyof typeof columnsVisibility
                                                                    ]
                                                            )
                                                            .map((col) => (
                                                                <TableCell
                                                                    key={col}
                                                                >
                                                                    {col !=
                                                                    "Adjustment" ? (
                                                                        col
                                                                    ) : (
                                                                        <MultiPermissionAuthorize
                                                                            ops={[
                                                                                "CREATE READING_ADJUSTMENT",
                                                                            ]}
                                                                        >
                                                                            <TableCell>
                                                                                Adjustment
                                                                            </TableCell>
                                                                        </MultiPermissionAuthorize>
                                                                    )}
                                                                </TableCell>
                                                            ))}
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {collectionDetailsResponse?.data.rows.map(
                                                        (
                                                            coll: Collectee,
                                                            id: number
                                                        ) => (
                                                            <CollectionRow
                                                                collectionsRef={
                                                                    collectionsRef
                                                                }
                                                                rowIndex={id}
                                                                amountInputsRef={
                                                                    amountInputsRef
                                                                }
                                                                adjustmentInputsRef={
                                                                    adjustmentInputsRef
                                                                }
                                                                count={
                                                                    id +
                                                                    1 +
                                                                    pagination.limit *
                                                                        pagination.page
                                                                }
                                                                key={id}
                                                                collectionResults={
                                                                    collectionResults
                                                                }
                                                                collectee={coll}
                                                                // collections={collections}
                                                                // setCollections={setCollections}

                                                                collectionDate={
                                                                    collectionDate
                                                                }
                                                                paymentMethodRes={
                                                                    paymentMethodsRes
                                                                        ?.data
                                                                        .rows ??
                                                                    []
                                                                }
                                                                setActiveResult={
                                                                    setActiveResult
                                                                }
                                                                checkAll={
                                                                    checkAll
                                                                }
                                                                columnVisibility={
                                                                    columnsVisibility
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>

                                    {feedback.show && (
                                        <Grid item xs={12}>
                                            <Alert severity="error">
                                                {feedback.message}
                                            </Alert>
                                        </Grid>
                                    )}

                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ mb: theme.spacing(2) }}
                                    >
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            type="submit"
                                            color="secondary"
                                            endIcon={
                                                loading ? (
                                                    <CircularProgress size="1rem" />
                                                ) : (
                                                    <ReceiptLongOutlined />
                                                )
                                            }
                                        >
                                            Collect
                                        </Button>
                                    </Grid>
                                </Grid>
                                {activeResult && (
                                    <Dialog
                                        open={Boolean(activeResult)}
                                        fullWidth
                                        onClose={() => setActiveResult(null)}
                                    >
                                        <DialogTitle>
                                            Collection result
                                        </DialogTitle>
                                        <DialogContent>
                                            {
                                                collectionResults[activeResult]
                                                    .message
                                            }
                                        </DialogContent>
                                    </Dialog>
                                )}
                            </>
                        )}
                    </Grid>
                </>
            )}
        </Grid>
    );
};
