import {
    AddOutlined,
    CloseOutlined,
    DeleteOutlineOutlined,
    DeleteOutlined,
    EditOutlined,
    ReplayOutlined,
    ViewColumn,
} from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    LinearProgress,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    CSSProperties,
    Dispatch,
    Fragment,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../Utilities/AppContext";
import { DELETE, POST } from "../../Utilities/BaseService";
import { MultiPermissionAuthorize } from "../../Utilities/MultiPermissionAuthorize";
import { Print } from "../../Utilities/Print";
import { TableToExcel } from "../../Utilities/TableToExcel";
import { Footer } from "../Header/Footer";
import { Header } from "../Header/Header";
import { BlindPagination } from "./Components/BlindPagination";
import SearchBar from "./Components/SearchBar";

type ComplexHeaders = {
    label: string;
    styles: CSSProperties;
    props: any;
};

type Props = {
    headers: any;
    data: any[];
    delEndPoint: string;
    feedback?: string;
    setFeedback?: Dispatch<SetStateAction<any>>;
    pagination: { page: any; limit: any };
    setPagination: Dispatch<SetStateAction<any>>;
    rowsCount: number;
    isLoading: boolean;
    permissions: { edit: string[]; delete: string[] };
    reportName: string;
    queryKey: any[];
    defaultSelectedHeaders: any[];
    blindPagination?: boolean;
    reportTemplates?: any[];
    reportCategory?: string;
    hasReportTemplates?: boolean;
    width?: number;
    load?: boolean;
    search?: any;
    setSearch?: Dispatch<SetStateAction<any>>;
    _printables?: string[];
    features?: { download: ReactNode };
    reportDetails?: { [key: string]: { label: string; value: any } };
    deletionProp?: any;
    summaryRow?: (cols: string[]) => any[];
};

const addReportTemplate = (template: {
    key: string;
    value: string;
    user: number;
    category: string;
}) => POST("/preferences", template);

const BaseTable = ({
    headers,
    data,
    delEndPoint,
    feedback,
    setFeedback,
    rowsCount,
    pagination,
    setPagination,
    isLoading,
    permissions,
    reportName = "",
    queryKey,
    defaultSelectedHeaders = [],
    blindPagination = false,
    reportTemplates = [],
    reportCategory,
    hasReportTemplates = false,
    width = 1024,
    load = false,
    search,
    setSearch,
    _printables = [],
    features,
    reportDetails,
    deletionProp = "id",
    summaryRow,
}: Props) => {
    const [open, setOpen] = useState(false);
    const [id, setId] = useState("");
    const [printMode, setPrintMode] = useState(false);
    const [printables, setPrintables] = useState(_printables);
    const [openAddReportTemplate, setOpenAddReportTemplate] = useState(false);
    const [deletionResponse, setDeletionResponse] = useState("");
    const [reportTemplateFeedback, setReportTemplateFeedback] = useState({
        show: false,
        loading: false,
        message: "",
        status: "",
    });
    const [selectedHeaders, setSelectedHeaders] = useState<any>(
        defaultSelectedHeaders
    );
    const [selectedReportTemplate, setSelectedReportTemplate] = useState<{
        name: string;
        columns: string[];
    }>({
        name: "",
        columns: [],
    });
    const [newReportTemplate, setNewReportTemplate] = useState({
        key: "",
        value: [],
    });

    const { user } = useContext(AppContext);

    const client = useQueryClient();
    const printRef = useRef<any>();
    const navigate = useNavigate();
    const history = useLocation();
    const qc = useQueryClient();

    const name = history.pathname.split("/");

    const handleEdit = (item: any) => {
        const updatedUrl = history.pathname + "/update?id=" + item.id;
        navigate(updatedUrl);
    };

    const handleDelete = (id: string) => {
        setId(id);
        setOpen(true);
    };

    const deleteSoft = () => {
        return DELETE(delEndPoint + `/soft?id=${id}`);
    };

    const delMutation = useMutation(deleteSoft, {
        onSuccess(res) {
            setDeletionResponse(res.data.message);
            client.invalidateQueries(queryKey);
        },
        onError(err: any) {
            setDeletionResponse(err.response.data.message);
        },
    });

    const next = () => {
        delMutation.mutate();
    };

    const handleAddReportTemplate = () => {
        setReportTemplateFeedback({ ...reportTemplateFeedback, loading: true });
        reportTemplateMutation.mutate({
            ...newReportTemplate,
            value: JSON.stringify(newReportTemplate.value),
            user: user.id,
            category: reportCategory ?? reportName,
        });
    };

    const reportTemplateMutation = useMutation(addReportTemplate, {
        onSuccess(res) {
            setReportTemplateFeedback({
                show: true,
                loading: false,
                message: res.data.message,
                status: "success",
            });
        },

        onError(err) {
            setReportTemplateFeedback({
                show: true,
                loading: false,
                message: err as string,
                status: "error",
            });
        },
    });

    const deleteReportTemplateMutation = useMutation(
        (id: number) => DELETE(`/preferences/soft?id=${id}`),
        {
            onSuccess() {
                client.refetchQueries(["report-templates"]);
            },
        }
    );

    const handlePageChange = (ev: any, page: number) => {
        setPagination({ ...pagination, page: page });
    };

    const handleRowsPerPageChange = (ev: any) => {
        setPagination({ ...pagination, limit: ev.target.value });
    };

    const handleDeleteReportTemplate = (id: number) => {
        console.log(id);
        deleteReportTemplateMutation.mutate(id);
    };

    useEffect(() => {
        if (selectedReportTemplate?.name) {
            setSelectedHeaders(selectedReportTemplate.columns);
        } else {
            setSelectedHeaders(defaultSelectedHeaders);
        }
    }, [selectedReportTemplate]);

    useEffect(() => {
        if (printMode) {
            if (_printables.length > 0) {
                const targetPrintables = selectedHeaders.filter((col: string) =>
                    _printables.includes(col)
                );

                setPrintables(targetPrintables);
            } else {
                setPrintables(selectedHeaders);
            }
        }
    }, [printMode, selectedHeaders]);

    return load ? (
        <Box sx={{ height: "100%" }}>
            <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
            >
                <Grid item xs={12} container spacing={1}>
                    {reportTemplates.length > 0 &&
                        reportTemplates.map((report, index: number) => (
                            <Grid item key={index}>
                                <Chip
                                    variant={
                                        selectedReportTemplate.name ===
                                        report.key
                                            ? "filled"
                                            : "outlined"
                                    }
                                    color="secondary"
                                    deleteIcon={
                                        selectedReportTemplate.name ===
                                        report.key ? (
                                            <CloseOutlined />
                                        ) : deleteReportTemplateMutation.isLoading ? (
                                            <CircularProgress size={15} />
                                        ) : (
                                            <DeleteOutlined />
                                        )
                                    }
                                    onDelete={
                                        selectedReportTemplate.name ===
                                        report.key
                                            ? () =>
                                                  setSelectedReportTemplate({
                                                      name: "",
                                                      columns: [],
                                                  })
                                            : () =>
                                                  handleDeleteReportTemplate(
                                                      report.id
                                                  )
                                    }
                                    size="small"
                                    label={report.key}
                                    onClick={() =>
                                        setSelectedReportTemplate({
                                            name: report.key,
                                            columns: report.value,
                                        })
                                    }
                                />
                            </Grid>
                        ))}

                    {hasReportTemplates && (
                        <Grid item>
                            <Dialog
                                open={openAddReportTemplate}
                                onClose={() => {
                                    setOpenAddReportTemplate(false);
                                    setNewReportTemplate({
                                        key: "",
                                        value: [],
                                    });
                                    setReportTemplateFeedback({
                                        ...reportTemplateFeedback,
                                        show: false,
                                        message: "",
                                    });
                                    client.invalidateQueries([
                                        "report-templates",
                                    ]);
                                }}
                                fullWidth
                            >
                                <DialogTitle>Add Report Template</DialogTitle>

                                <DialogContent>
                                    <Grid container spacing={1}>
                                        {reportTemplateFeedback.show && (
                                            <Grid item xs={12} mb={2}>
                                                <Alert
                                                    severity={
                                                        reportTemplateFeedback.status as any
                                                    }
                                                >
                                                    {
                                                        reportTemplateFeedback.message
                                                    }
                                                </Alert>
                                            </Grid>
                                        )}

                                        <Grid item xs={12} mt={1}>
                                            <TextField
                                                label="Report Name"
                                                size="small"
                                                fullWidth
                                                value={newReportTemplate.key}
                                                onChange={(e) =>
                                                    setNewReportTemplate({
                                                        ...newReportTemplate,
                                                        key: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                select
                                                required
                                                SelectProps={{
                                                    multiple: true,
                                                }}
                                                size="small"
                                                fullWidth
                                                label="Columns"
                                                value={newReportTemplate.value}
                                                onChange={(e) =>
                                                    setNewReportTemplate({
                                                        ...newReportTemplate,
                                                        value: e.target
                                                            .value as any,
                                                    })
                                                }
                                            >
                                                {Object.entries(headers).map(
                                                    ([k, v]: any[]) => (
                                                        <MenuItem
                                                            key={k}
                                                            value={k}
                                                        >
                                                            {v}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </TextField>
                                        </Grid>

                                        <Grid item mt={1}>
                                            <Button
                                                variant="contained"
                                                disableElevation
                                                disabled={
                                                    newReportTemplate.key
                                                        .length == 0 ||
                                                    newReportTemplate.value
                                                        .length == 0 ||
                                                    reportTemplateFeedback.loading
                                                }
                                                onClick={
                                                    handleAddReportTemplate
                                                }
                                            >
                                                {reportTemplateFeedback.loading
                                                    ? "saving "
                                                    : "save "}
                                                template
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </Dialog>
                        </Grid>
                    )}
                </Grid>

                <Grid item xs={12} md="auto">
                    {features?.download ? (
                        features.download
                    ) : (
                        <TableToExcel
                            fileName={name[name.length - 1]}
                            sheetName=""
                            targetRef={printRef}
                        />
                    )}

                    <Print
                        componentRef={printRef}
                        setPrintMode={setPrintMode}
                    />

                    <Tooltip title="Reload table">
                        <IconButton
                            size="small"
                            onClick={() => qc.resetQueries(queryKey)}
                        >
                            <ReplayOutlined fontSize="small" />
                        </IconButton>
                    </Tooltip>

                    <SelectColumns
                        columns={headers}
                        selectedColumns={selectedHeaders}
                        setColumns={setSelectedHeaders}
                    />

                    {hasReportTemplates && (
                        <Tooltip title="Add new report template">
                            <IconButton
                                size="small"
                                onClick={() => setOpenAddReportTemplate(true)}
                            >
                                <AddOutlined fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>

                <Grid item xs={12} md="auto">
                    {blindPagination ? (
                        <BlindPagination
                            pagination={pagination}
                            setPagination={setPagination}
                        />
                    ) : (
                        <TablePagination
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            rowsPerPage={pagination?.limit}
                            component="div"
                            page={pagination?.page}
                            count={rowsCount && rowsCount}
                            rowsPerPageOptions={[
                                100, 250, 500, 1000, 1500, 2000,
                            ]}
                        />
                    )}
                </Grid>

                {search && setSearch && (
                    <Grid item xs={12} md={3}>
                        <SearchBar filter={search} setFilter={setSearch} />
                    </Grid>
                )}
            </Grid>

            {(delMutation.isSuccess || delMutation.isError) && (
                <Grid item xs={12}>
                    <Alert severity={delMutation.status} sx={{ my: 2 }}>
                        {deletionResponse}
                    </Alert>
                </Grid>
            )}

            <Grid item xs={12} my={1}>
                {isLoading && <LinearProgress />}
            </Grid>

            <TableContainer
                component={Paper}
                variant="outlined"
                ref={printRef}
                sx={{
                    // height: "100%",
                    px: printMode ? 4 : undefined,
                }}
            >
                <Table
                    padding="none"
                    sx={{
                        minWidth: !printMode ? "auto" : "100%",
                    }}
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                colSpan={
                                    printMode
                                        ? selectedHeaders.length + 1
                                        : selectedHeaders.length + 2
                                }
                                // sx={{ px: 2 }}
                            >
                                {printMode && (
                                    <Header
                                        name={
                                            selectedReportTemplate.name ||
                                            reportName
                                        }
                                        details={reportDetails}
                                    />
                                )}
                            </TableCell>
                        </TableRow>

                        <TableRow
                            sx={{
                                textTransform: "capitalize",
                                "& > *": {
                                    bgcolor: "common.white",
                                    fontWeight: "bold",
                                    fontSize: printMode ? "12px" : "",
                                },
                            }}
                        >
                            <TableCell
                                style={{ textAlign: "center", maxWidth: "6ch" }}
                            >
                                S.no
                            </TableCell>
                            {selectedHeaders && printMode
                                ? printables.map((col: any, idx: number) => (
                                      <TableCell
                                          key={idx}
                                          style={{
                                              padding: "0 16px",
                                              whiteSpace: "nowrap",
                                          }}
                                      >
                                          {typeof headers[col] === "string"
                                              ? headers[col]
                                              : (headers[col] as ComplexHeaders)
                                                    ?.label}
                                      </TableCell>
                                  ))
                                : selectedHeaders.map(
                                      (col: any, idx: number) => (
                                          <TableCell
                                              key={idx}
                                              style={{
                                                  padding: "0 16px",
                                                  whiteSpace: "nowrap",
                                              }}
                                          >
                                              {typeof headers[col] === "string"
                                                  ? headers[col]
                                                  : (
                                                        headers[
                                                            col
                                                        ] as ComplexHeaders
                                                    )?.label}
                                          </TableCell>
                                      )
                                  )}

                            {!printMode && (
                                <MultiPermissionAuthorize
                                    ops={[
                                        ...permissions.edit,
                                        ...permissions.delete,
                                    ]}
                                    strategy="some"
                                >
                                    <TableCell style={{ padding: "0 16px" }}>
                                        actions
                                    </TableCell>
                                </MultiPermissionAuthorize>
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody
                        sx={{
                            "& *": printMode
                                ? {
                                      fontSize: "10px!important",
                                      whiteSpace: "nowrap",
                                  }
                                : {},
                        }}
                    >
                        {data &&
                            data.map((item: any, idx: number) => (
                                <Fragment key={idx}>
                                    <TableRow
                                        sx={
                                            !printMode
                                                ? {
                                                      bgcolor:
                                                          idx % 2 === 0
                                                              ? "inherit"
                                                              : "common.white",

                                                      "&:hover": {
                                                          bgcolor: "grey.300",
                                                      },
                                                  }
                                                : undefined
                                        }
                                    >
                                        <TableCell
                                            style={{
                                                textAlign: "center",
                                                maxWidth: "6ch",
                                            }}
                                        >
                                            {pagination.page > 0
                                                ? pagination.page *
                                                      pagination.limit +
                                                  idx +
                                                  1
                                                : idx + 1}
                                        </TableCell>

                                        {printMode
                                            ? printables.map(
                                                  (col: any, idx: number) => (
                                                      <TableCell
                                                          sx={{
                                                              px: 2,
                                                              py: printMode
                                                                  ? 0.5
                                                                  : undefined,
                                                          }}
                                                          key={idx}
                                                          width="fit-content"
                                                          style={{
                                                              ...(typeof headers[
                                                                  col
                                                              ] === "string"
                                                                  ? {}
                                                                  : headers[
                                                                        col
                                                                    ]?.styles(
                                                                        item
                                                                    )),
                                                          }}
                                                          {...(typeof headers[
                                                              col
                                                          ] === "string"
                                                              ? {}
                                                              : headers[
                                                                    col
                                                                ]?.props(
                                                                    item
                                                                ) ?? {})}
                                                      >
                                                          {item[col]}
                                                      </TableCell>
                                                  )
                                              )
                                            : selectedHeaders.map(
                                                  (col: any, idx: number) => (
                                                      <TableCell
                                                          sx={{
                                                              px: 2,
                                                              py: printMode
                                                                  ? 0.5
                                                                  : undefined,
                                                          }}
                                                          key={idx}
                                                          width="fit-content"
                                                          style={{
                                                              ...(typeof headers[
                                                                  col
                                                              ] === "string"
                                                                  ? {}
                                                                  : headers[
                                                                        col
                                                                    ]?.styles(
                                                                        item
                                                                    )),
                                                          }}
                                                          {...(typeof headers[
                                                              col
                                                          ] === "string"
                                                              ? {}
                                                              : headers[
                                                                    col
                                                                ]?.props(
                                                                    item
                                                                ) ?? {})}
                                                      >
                                                          {item[col]}
                                                      </TableCell>
                                                  )
                                              )}

                                        {!printMode && (
                                            <MultiPermissionAuthorize
                                                ops={[
                                                    ...permissions.edit,
                                                    ...permissions.delete,
                                                ]}
                                                strategy="some"
                                            >
                                                <TableCell
                                                    // width="fit-content"
                                                    sx={{
                                                        // display: "flex",
                                                        // flexWrap: "nowrap",
                                                        px: 2,
                                                        // height: "100%",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    <MultiPermissionAuthorize
                                                        ops={permissions.edit}
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                handleEdit(item)
                                                            }
                                                        >
                                                            <EditOutlined fontSize="small" />
                                                        </IconButton>
                                                    </MultiPermissionAuthorize>

                                                    <MultiPermissionAuthorize
                                                        ops={permissions.delete}
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                handleDelete(
                                                                    item[
                                                                        deletionProp
                                                                    ]
                                                                )
                                                            }
                                                        >
                                                            <DeleteOutlineOutlined fontSize="small" />
                                                        </IconButton>
                                                    </MultiPermissionAuthorize>
                                                </TableCell>
                                            </MultiPermissionAuthorize>
                                        )}
                                    </TableRow>
                                </Fragment>
                            ))}

                        {summaryRow && data && (
                            <TableRow sx={{ bgcolor: "grey.400" }}>
                                {summaryRow(selectedHeaders).map((summary) => (
                                    <TableCell
                                        sx={{
                                            px: 2,
                                            py: 0.5,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {summary}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )}
                    </TableBody>

                    {printMode && <Footer span={selectedHeaders.length + 1} />}
                </Table>
            </TableContainer>

            <Dialog open={open}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will irreversibly delete the item, continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpen(false)}
                    >
                        close
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                            next();
                            setOpen(false);
                        }}
                    >
                        delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    ) : null;
};

export default BaseTable;

type SelectColumnsProps = {
    columns: { [key: string]: string | { label: string } };
    selectedColumns: string[];
    setColumns: Dispatch<SetStateAction<string[]>>;
};

const SelectColumns = ({
    columns, //  All headers
    selectedColumns, //  Selected headers
    setColumns, //  Setter for selected headers
}: SelectColumnsProps) => {
    const [anchor, setAnchor] = useState<Element | null>(null);
    const [selected, setSelected] = useState<{ [key: string]: boolean }>(
        selectedColumns.reduce((prev, curr) => ({ ...prev, [curr]: true }), {})
    );

    const open = useMemo(() => Boolean(anchor), [anchor]);

    const handleChange = (checked: boolean, col: string) => {
        const targetSelected = {
            ...selected,
            [col]: checked,
        };

        setSelected(targetSelected);

        const targetCols = Object.entries(targetSelected)
            .filter(([k, v]) => v)
            .map((col) => col[0]);

        setColumns(targetCols);
    };

    useEffect(() => {
        setSelected(() =>
            selectedColumns.reduce(
                (prev, curr) => ({ ...prev, [curr]: true }),
                {}
            )
        );
    }, [selectedColumns]);

    return (
        <>
            <Tooltip title="Select Columns">
                <IconButton
                    size="small"
                    onClick={(e) => setAnchor(e.currentTarget as any)}
                >
                    <ViewColumn fontSize="small" />
                </IconButton>
            </Tooltip>

            <Menu
                open={open}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                color="primary"
            >
                {Object.entries(columns).map(([k, v]: any) => (
                    <MenuItem key={k} dense>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    checked={selected[k] ?? false}
                                    onChange={(e, checked) =>
                                        handleChange(checked, k)
                                    }
                                />
                            }
                            label={typeof v === "string" ? v : v.label}
                            value={k}
                            style={{ width: "100%" }}
                        />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
