import {
    Add,
    FilterAltOutlined,
    HorizontalRuleOutlined,
    TuneOutlined,
} from "@mui/icons-material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {
    Alert,
    Box,
    Button,
    Card,
    Chip,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { green, grey, purple } from "@mui/material/colors";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../../Utilities/AppContext";
import { GET } from "../../../Utilities/BaseService";
import {
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../../Utilities/constants";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { getReportTemplates } from "../../Payment/Payment";
import { currencyFormatter } from "../../Reading/Bill/Cells/Last12Summary";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";
import { getSelectedFilters } from "../Property/Property";
import MeterReplacement from "./MeterReplacement";
import PreviousMeter from "./PreviousMeter";
import { UnitCorrection } from "./UnitCorrection";

type Filter = {
    search: string;
    customer: number | "" | "all";
    district: number | "" | "all";
    village: number | "" | "all";
    hamlet: number | "" | "all";
    powerstation: number | "" | "all";
    property: number | "" | "all";
    connectionType: number[];
    connectionDate: string;
    status: "active" | "inactive" | "replaced" | "";
    id?: number;
};

const initialFilter: Filter = {
    search: "",
    customer: "",
    district: "",
    village: "",
    hamlet: "",
    powerstation: "",
    property: "",
    connectionType: [],
    connectionDate: "",
    status: "",
};

const initialClear = {
    district: false,
    village: false,
    hamlet: false,
    powerstation: false,
    property: false,
};

const Meter = () => {
    const [unitCorrectionOpen, setUnitCorrectionOpen] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [activeRow, setActiveRow] = useState<any>(null);
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({
        page: 0,
        limit: 100,
    });
    const [filter, setFilter] = useState(initialFilter);
    const [feedback, setFeedback] = useState("");
    const [reportTemplates, setReportTemplates] = useState<string[]>([]);
    const [showReplacementSlip, setShowReplacementSlip] = useState<
        null | number
    >(null);
    const [showPrevMeter, setShowPrevMeter] = useState(false);
    const [clearAll, setClearAll] = useState(initialClear);
    const [changeCount, setChangeCount] = useState(0);
    const [meterId, setMeterId] = useState<null | number>(null);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [intermediateFilter, setIntermediateFilter] =
        useState<Filter>(initialFilter);

    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));
    const { user } = useContext(AppContext);
    const location = useLocation();

    const getMeter = () => {
        setIsLoading(true);

        const params = Object.fromEntries(
            Object.entries(filter).filter(([k, v]) => v && v !== "all")
        );
        return GET("/customer/meter", {
            page: pagination.page + 1,
            limit: pagination.limit,
            ...params,
        });
    };

    const { data, isError, isSuccess } = useQuery(
        ["meter", pagination.page, pagination.limit, filter],
        getMeter,
        {
            enabled: Boolean(changeCount),
            onSuccess(res) {
                setIsLoading(false);

                if (res.data) {
                    setRowsCount(res.data.count);

                    const _selectedFills = getSelectedFilters(
                        res.data.rows[0],
                        ["district", "village", "hamlet", "powerstation"],
                        filter,
                        {
                            district: { label: "District", valueKey: "name" },
                            village: { label: "Village", valueKey: "name" },
                            hamlet: { label: "Hamlet", valueKey: "name" },
                            powerstation: {
                                label: "Power Station",
                                valueKey: "name",
                            },
                        }
                    );

                    setSelectedFilters(() => _selectedFills);
                }
            },
            onError(_err) {
                setIsLoading(false);
            },
        }
    );

    const { data: reportTemps } = useQuery(
        ["report-templates"],
        () => getReportTemplates(user.id, "Meters Report"),
        {
            onSuccess(res) {
                setReportTemplates(() =>
                    res.data.rows.map((item: any) => ({
                        id: item.id,
                        key: item.key,
                        value: JSON.parse(item.value),
                    }))
                );
            },
        }
    );

    const handleMeter = (row: any) => {
        setActiveRow(row);
    };

    useEffect(() => {
        if (intermediateFilter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
                property: false,
            });
            setIntermediateFilter({
                ...intermediateFilter,
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
                property: "",
            });
        }

        if (intermediateFilter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
            setIntermediateFilter({
                ...intermediateFilter,
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
            setIntermediateFilter({ ...intermediateFilter, hamlet: "" });
        }

        if (intermediateFilter.powerstation === "all") {
            setClearAll({ ...clearAll, powerstation: true });
            setIntermediateFilter({ ...intermediateFilter, powerstation: "" });
        }

        if (intermediateFilter.property === "all") {
            setClearAll({ ...clearAll, property: true });
            setIntermediateFilter({ ...intermediateFilter, property: "" });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
                property: false,
            });
        };
    }, [
        intermediateFilter.district,
        intermediateFilter.village,
        intermediateFilter.hamlet,
        intermediateFilter.powerstation,
        intermediateFilter.property,
    ]);

    useEffect(() => {
        if (changeCount) {
            setFilter({ ...filter, ...intermediateFilter });
        }
    }, [changeCount]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const _id = params.get("id");

        console.log(_id);

        if (_id) {
            setMeterId(parseInt(_id));

            setIntermediateFilter({
                ...intermediateFilter,
                id: parseInt(_id),
            });

            setChangeCount(changeCount + 1);
        }
    }, [location.pathname]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}
                >
                    <Grid item xs={12} md="auto">
                        <Typography variant="h5" color="GrayText">
                            Meters
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        spacing={1.5}
                        justifyContent="flex-end"
                    >
                        <Grid item flex={1}></Grid>

                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize ops={["CREATE METER"]}>
                                <Link
                                    to="/customer/meters/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{ height: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                        fullWidth
                                    >
                                        add meter
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} spacing={1.5}>
                        <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                            <Grid item xs={12} sm={6} md={3}>
                                <UpdatedSearchableInput
                                    label="District"
                                    api="/region/district"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    clearAll={clearAll.district}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                            <Grid item xs={12} sm={6} md={3}>
                                <UpdatedSearchableInput
                                    label="Village"
                                    api="/region/village"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.district}
                                    params={{
                                        district: intermediateFilter.district,
                                    }}
                                    clearAll={clearAll.village}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                            <Grid item xs={12} sm={6} md={3}>
                                <UpdatedSearchableInput
                                    label="hamlet"
                                    api="/region/hamlet"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.village}
                                    params={
                                        intermediateFilter.village &&
                                        intermediateFilter.village !== "all"
                                            ? {
                                                  village: [
                                                      intermediateFilter.village,
                                                  ],
                                              }
                                            : { village: [0] }
                                    }
                                    clearAll={clearAll.hamlet}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ POWERSTATION"]}>
                            <Grid item xs={12} sm={6} md={3}>
                                <UpdatedSearchableInput
                                    label="Powerstation"
                                    api="/powerstation"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.district}
                                    params={{
                                        district: intermediateFilter.district,
                                    }}
                                    clearAll={clearAll.powerstation}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ PROPERTY"]}>
                            <Grid item xs={12} sm={6} md={3}>
                                <UpdatedSearchableInput
                                    api="/customer/property"
                                    label="Property"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={[
                                        intermediateFilter.district,
                                        intermediateFilter.village,
                                        intermediateFilter.hamlet,
                                        intermediateFilter.powerstation,
                                    ]}
                                    params={Object.fromEntries(
                                        Object.entries(
                                            intermediateFilter
                                        ).filter(
                                            ([k, v]) =>
                                                [
                                                    "district",
                                                    "village",
                                                    "hamlet",
                                                    "powerstation",
                                                ].includes(k) &&
                                                v &&
                                                v !== "all"
                                        )
                                    )}
                                    clearAll={clearAll.property}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Connection Date"
                                name="connectionDate"
                                InputLabelProps={{ shrink: true }}
                                value={intermediateFilter.connectionDate}
                                type="date"
                                onChange={(e) =>
                                    setIntermediateFilter({
                                        ...intermediateFilter,
                                        connectionDate: e.target.value,
                                    })
                                }
                            />
                        </Grid>

                        <MultiPermissionAuthorize
                            ops={["READ CONNECTION_TYPE"]}
                        >
                            <Grid item xs={12} sm={6} md={3}>
                                <UpdatedSearchableInput
                                    label="Connection Type"
                                    _name="connectionType"
                                    api="/connection"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={Object.fromEntries(
                                        Object.entries(
                                            intermediateFilter
                                        ).filter(([k, v]) =>
                                            [
                                                "district",
                                                "village",
                                                "hamlet",
                                                "powerstation",
                                            ].includes(k)
                                        )
                                    )}
                                    params={Object.fromEntries(
                                        Object.entries(
                                            intermediateFilter
                                        ).filter(
                                            ([k, v]) =>
                                                [
                                                    "district",
                                                    "village",
                                                    "hamlet",
                                                    "powerstation",
                                                ].includes(k) &&
                                                v &&
                                                v !== "all"
                                        )
                                    )}
                                    clearAll={clearAll.powerstation}
                                    multiple
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ PRICE_TYPE"]}>
                            <Grid item xs={12} sm={6} md={3}>
                                <UpdatedSearchableInput
                                    label="Price Type"
                                    _name="priceType"
                                    api="/priceType"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={Object.keys(intermediateFilter).filter(
                                        ([k, v]) =>
                                            [
                                                "district",
                                                "village",
                                                "hamlet",
                                                "powerstation",
                                            ].includes(k)
                                    )}
                                    params={Object.fromEntries(
                                        Object.entries(
                                            intermediateFilter
                                        ).filter(
                                            ([k, v]) =>
                                                [
                                                    "district",
                                                    "village",
                                                    "hamlet",
                                                    "powerstation",
                                                ].includes(k) &&
                                                v &&
                                                v !== "all"
                                        )
                                    )}
                                    clearAll={clearAll.district}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                label="Status"
                                size="small"
                                fullWidth
                                select
                                onChange={(e) =>
                                    setIntermediateFilter({
                                        ...intermediateFilter,
                                        status: e.target.value as any,
                                    })
                                }
                            >
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="inactive">Inactive</MenuItem>
                                <MenuItem value="replaced">Replaced</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <SearchBar
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setChangeCount(changeCount + 1)}
                                sx={{ minWidth: "22ch", height: "100%" }}
                                startIcon={<FilterAltOutlined />}
                                fullWidth
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            {(!changeCount || !data?.data.rows.length || isError) && (
                <Alert severity="info">
                    {!data?.data.rows.length && isSuccess
                        ? noDataMessage
                        : !hasValue(filter) && !changeCount
                        ? filterSelectionMessage
                        : filterSelectionMessage}
                </Alert>
            )}

            <Box sx={{ height: "55%" }}>
                <BaseTable
                    load={Boolean(changeCount)}
                    headers={{
                        id: "ID",
                        meterNo: "Meter No",
                        customer: "Customer",
                        connectionCharges: "Connection Charges",
                        connectionDate: "Connection Date",
                        property: "Property",
                        connectionType: "Connection Type",
                        priceType: "Price Type",
                        powerstation: "Powerstation",
                        lineRent: "Line Rent",
                        surcharge: "Surcharge",
                        unitChargeRule: "Unit Charge Rule",
                        credit: "Credit Amount",
                        district: "District",
                        village: "Village",
                        hamlet: "Hamlet",
                        view: "Replace Meter",
                        status: "Meter Status",
                        prevMeter: "Previous Meter",
                        unitCorrection: "Unit correction",
                    }}
                    defaultSelectedHeaders={[
                        "meterNo",
                        "customer",
                        "connectionCharges",
                        "connectionDate",
                        "property",
                        "connectionType",
                        "powerstation",
                        "view",
                        "status",
                        "unitCorrection",
                    ]}
                    _printables={[
                        "id",
                        "meterNo",
                        "customer",
                        "connectionCharges",
                        "connectionDate",
                        "property",
                        "connectionType",
                        "priceType",
                        "powerstation",
                        "lineRent",
                        "surcharge",
                        "unitChargeRule",
                        "district",
                        "village",
                        "hamlet",
                        "status",
                        "prevMeter",
                        "credit",
                    ]}
                    data={data?.data.rows.map((row: any) => ({
                        ...row,
                        view:
                            row.status === "active" ? (
                                <IconButton onClick={() => handleMeter(row)}>
                                    <AutorenewIcon />
                                </IconButton>
                            ) : (
                                <IconButton disabled>
                                    <AutorenewIcon />
                                </IconButton>
                            ),
                        property: row.property?.name,
                        connectionType: row.connectionType?.name,
                        priceType: row.priceType?.name,
                        powerstation: row.powerstation?.name,
                        customer: row.property?.customer?.name,
                        district: row.district?.name,
                        village: row.village?.name,
                        hamlet: row.hamlet?.name,
                        connectionCharges: currencyFormatter.format(
                            row.connectionCharges
                        ),
                        unitChargeRule: row?.priceType?.unitChargeRule?.name,
                        lineRent: currencyFormatter.format(
                            row?.priceType?.lineRent?.amount
                        ),
                        surcharge: row?.priceType?.surcharge?.isSurchargePct
                            ? row.priceType?.surcharge?.value + " %"
                            : currencyFormatter.format(
                                  row.priceType?.surcharge?.value
                              ),
                        status: (
                            <>
                                <Chip
                                    label={row.status.toUpperCase()}
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        fontWeight: "bold",
                                        bgcolor:
                                            row.status === "active"
                                                ? green[50]
                                                : row.status === "replaced"
                                                ? purple[50]
                                                : grey[200],
                                        color:
                                            row.status === "active"
                                                ? green[500]
                                                : row.status === "replaced"
                                                ? purple[500]
                                                : grey[500],
                                        border: "none",
                                        width: "13ch",
                                    }}
                                />
                            </>
                        ),

                        unitCorrection: (
                            <IconButton
                                onClick={() => setUnitCorrectionOpen(row)}
                            >
                                <TuneOutlined />
                            </IconButton>
                        ),

                        prevMeter: row.prevMeter ? (
                            <>
                                <Button
                                    color="info"
                                    onClick={() => setShowPrevMeter(true)}
                                >
                                    {row.prevMeter.meterNo}
                                </Button>
                                <PreviousMeter
                                    open={showPrevMeter}
                                    setOpen={setShowPrevMeter}
                                    meter={row.prevMeter}
                                />
                            </>
                        ) : (
                            <Button fullWidth disabled={!row.prevMeter}>
                                <HorizontalRuleOutlined />
                            </Button>
                        ),
                    }))}
                    reportName="Meters List"
                    delEndPoint="/customer/meter"
                    rowsCount={rowsCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    isLoading={isLoading}
                    feedback={feedback}
                    setFeedback={setFeedback}
                    permissions={{
                        edit: ["UPDATE METER"],
                        delete: ["DELETE METER"],
                    }}
                    queryKey={[
                        "meter",
                        pagination.page,
                        pagination.limit,
                        filter,
                    ]}
                    hasReportTemplates
                    reportTemplates={reportTemplates}
                    width={1366}
                    reportDetails={selectedFilters}
                />

                {Boolean(activeRow) && (
                    <MeterReplacement
                        activeRow={activeRow}
                        setActiveRow={setActiveRow}
                    />
                )}

                {Boolean(unitCorrectionOpen) && (
                    <UnitCorrection
                        meter={unitCorrectionOpen}
                        open={Boolean(unitCorrectionOpen)}
                        close={() => setUnitCorrectionOpen(null)}
                    />
                )}
            </Box>
        </Box>
    );
};

export default Meter;
