/* eslint-disable eqeqeq */
import {
    Checkbox,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Pagination,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import { GET } from "../../Utilities/BaseService";
import SearchBar from "../Table/Components/SearchBar";

type Props = {
    user: any;
    setUser: Dispatch<SetStateAction<any>>;
    changes: any;
    setChanges: Dispatch<SetStateAction<any>>;
    updateMode: boolean;
};

type RegionStackProps = {
    label: string;
    api: string;
    variant: "district" | "village" | "hamlet" | "powerstation";
    regions: any;
    setRegions: Dispatch<SetStateAction<any[]>>;
    changes: any;
    setChanges: Dispatch<SetStateAction<any>>;
    updateMode: boolean;
};

const RegionStack = ({
    label,
    api,
    variant,
    regions,
    setRegions,
    updateMode,
    changes,
    setChanges,
}: RegionStackProps) => {
    const [filter, setFilter] = useState<any>({ search: "" });
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        count: 0,
    });
    const { data: res, isFetching } = useQuery(
        [
            label,
            changes[
                variant === "village"
                    ? "district"
                    : variant === "hamlet"
                    ? "village"
                    : "district"
            ],
            filter,
            pagination.page,
            pagination.limit,
        ],
        () => {
            const otherOps =
                variant !== "district" && variant !== "hamlet"
                    ? JSON.stringify([
                          {
                              op: "in",
                              isDate: false,
                              operands: [
                                  regions.map((r: any) => r["district"]),
                              ],
                              col: "district",
                          },
                      ])
                    : undefined;

            return GET(api, {
                otherOps,
                district:
                    variant === "hamlet"
                        ? regions.length
                            ? regions.map((r: any) => r.district)
                            : [0]
                        : ["village"].includes(variant)
                        ? regions.length
                            ? regions.map((r: any) => r["district"])
                            : [0]
                        : undefined,
                village:
                    variant === "hamlet"
                        ? regions.length
                            ? regions.map((r: any) => r.village)
                            : [0]
                        : undefined,
                search: filter.search,
                page: pagination.page,
                limit: pagination.limit,
            });
        },
        {
            onSuccess(res) {
                setPagination({ ...pagination, count: res.data.count });
            },
        }
    );

    useEffect(() => {
        setPagination({ ...pagination, page: 1 });
    }, [filter.search]);

    const handleChange = (ev: ChangeEvent<any>, checked: boolean) => {
        if (variant === "district") {
            const targetRegion = regions.find(
                (r: any) => r.district == ev.target.value
            );

            if (targetRegion) {
                setRegions(regions.filter((r: any) => r != targetRegion));
            } else {
                setRegions([...regions, { district: ev.target.value }]);
            }
        } else if (variant === "village") {
            const village = res?.data.rows.find(
                (v: any) => v.id == ev.target.value
            );
            const districtId = village.district.id;

            const targetRegions = regions.filter(
                (r: any) => r.district == districtId
            );

            const newTargetRegions: any[] = [];
            let found = false;

            for (const region of targetRegions) {
                if (region.village == ev.target.value) {
                    newTargetRegions.push({
                        ...region,
                        village: null,
                        hamlet: null,
                    });
                    found = true;
                } else {
                    newTargetRegions.push(region);
                }
            }

            const finalTargetRegions: any[] = [];
            let set = false;

            console.log(
                "targetRegions",
                targetRegions,
                "newTargetRegions",
                newTargetRegions
            );

            if (!found) {
                for (const r of targetRegions) {
                    if (!r.village && !set) {
                        finalTargetRegions.push({
                            ...r,
                            village: ev.target.value,
                        });
                        set = true;
                    } else {
                        finalTargetRegions.push(r);
                    }
                }

                console.log("finalTargetRegions", finalTargetRegions);

                if (set) {
                    setRegions([
                        ...regions.filter(
                            (r: any) => !targetRegions.includes(r)
                        ),
                        ...finalTargetRegions,
                    ]);
                } else {
                    setRegions([
                        ...regions,
                        { district: districtId, village: ev.target.value },
                    ]);
                }
            } else {
                setRegions([
                    ...regions.filter((r: any) => !targetRegions.includes(r)),
                    ...newTargetRegions,
                ]);
            }
        } else if (variant === "hamlet") {
            const hamlet = res?.data.rows.find(
                (h: any) => h.id == ev.target.value
            );
            const villageId = hamlet.village.id;

            const targetRegions = regions.filter(
                (r: any) => r.village == villageId
            );

            const newTargetRegions: any[] = [];
            let found = false;

            for (const region of targetRegions) {
                if (region.hamlet == ev.target.value) {
                    newTargetRegions.push({ ...region, hamlet: null });
                    found = true;
                } else {
                    newTargetRegions.push(region);
                }
            }

            const finalTargetRegions: any[] = [];
            let set = false;

            if (!found) {
                for (const r of targetRegions) {
                    if (!r.hamlet && !set) {
                        finalTargetRegions.push({
                            ...r,
                            hamlet: ev.target.value,
                        });
                        set = true;
                    } else {
                        finalTargetRegions.push(r);
                    }
                }

                if (set) {
                    setRegions([
                        ...regions.filter(
                            (r: any) => !targetRegions.includes(r)
                        ),
                        ...finalTargetRegions,
                    ]);
                } else {
                    setRegions([
                        ...regions,
                        {
                            district: targetRegions[0].district,
                            village: villageId,
                            hamlet: ev.target.value,
                        },
                    ]);
                }
            } else {
                setRegions([
                    ...regions.filter((r: any) => !targetRegions.includes(r)),
                    ...newTargetRegions,
                ]);
            }
        } else {
            const powerstation = res?.data.rows.find(
                (ps: any) => ps.id == ev.target.value
            );
            const districtId = powerstation.district.id;

            const targetRegions = regions.filter(
                (r: any) => r.district == districtId
            );

            const newTargetRegions: any[] = [];
            let found = false;

            for (const region of targetRegions) {
                if (region.powerstation == ev.target.value) {
                    newTargetRegions.push({ ...region, powerstation: null });
                    found = true;
                } else {
                    newTargetRegions.push(region);
                }
            }

            const finalTargetRegions: any[] = [];
            let set = false;

            if (!found) {
                for (const r of targetRegions) {
                    if (!r.powerstation && !set) {
                        finalTargetRegions.push({
                            ...r,
                            powerstation: ev.target.value,
                        });
                        set = true;
                    } else {
                        finalTargetRegions.push(r);
                    }
                }

                if (set) {
                    setRegions([
                        ...regions.filter(
                            (r: any) => !targetRegions.includes(r)
                        ),
                        ...finalTargetRegions,
                    ]);
                } else {
                    setRegions([
                        ...regions,
                        { district: districtId, powerstation: ev.target.value },
                    ]);
                }
            } else {
                setRegions([
                    ...regions.filter((r: any) => !targetRegions.includes(r)),
                    ...newTargetRegions,
                ]);
            }
        }

        setChanges({ ...changes, [variant]: changes[variant] + 1 });
    };

    return (
        <Grid
            item
            xs={12}
            md={3}
            p={1}
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                "& > div": {
                    my: 0.5,
                },
                ...(variant === "village" || variant === "powerstation"
                    ? {
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                          borderColor: "action.disabled",
                      }
                    : {}),
            }}
        >
            <Typography mb={2}>{label}</Typography>

            <Pagination
                size="small"
                count={Math.ceil(pagination.count / pagination.limit)}
                page={pagination.page}
                onChange={(ev, page) => setPagination({ ...pagination, page })}
            />
            <SearchBar filter={filter} setFilter={setFilter} />
            {isFetching && <CircularProgress size="1.5rem" />}
            <List dense sx={{ width: "100%" }}>
                {res?.data.rows.map((row: any) => (
                    <ListItem key={row.id} title={row.name}>
                        <ListItemText
                            primaryTypographyProps={{
                                style: {
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                },
                            }}
                            primary={row.name}
                        />
                        <ListItemSecondaryAction>
                            <Checkbox
                                checked={Boolean(
                                    regions?.find(
                                        (r: any) => r[variant] == row.id
                                    )
                                )}
                                onChange={handleChange}
                                value={row.id}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Grid>
    );
};

export const UserRegions = ({
    user,
    setUser,
    updateMode,
    changes,
    setChanges,
}: Props) => {
    return (
        <Grid
            container
            spacing={1}
            p={1}
            sx={{
                height: "calc(26px + 40px + 36px + calc(40px * 10))",
                // overflowY: "auto",
            }}
        >
            <RegionStack
                api="/region/district"
                label="Districts"
                variant="district"
                regions={user.regions}
                setRegions={(value: any) =>
                    setUser({ ...user, regions: value })
                }
                updateMode={updateMode}
                changes={changes}
                setChanges={setChanges}
            />

            <RegionStack
                api="/powerstation"
                label="Powerstations"
                variant="powerstation"
                regions={user.regions}
                setRegions={(value: any) =>
                    setUser({ ...user, regions: value })
                }
                updateMode={updateMode}
                changes={changes}
                setChanges={setChanges}
            />

            <RegionStack
                api="/region/village"
                label="Villages"
                variant="village"
                regions={user.regions}
                setRegions={(value: any) =>
                    setUser({ ...user, regions: value })
                }
                updateMode={updateMode}
                changes={changes}
                setChanges={setChanges}
            />

            <RegionStack
                api="/region/hamlet"
                label="Hamlets"
                variant="hamlet"
                regions={user.regions}
                setRegions={(value: any) =>
                    setUser({ ...user, regions: value })
                }
                updateMode={updateMode}
                changes={changes}
                setChanges={setChanges}
            />
        </Grid>
    );
};
