import { Add, FilterAltOutlined } from "@mui/icons-material";
import { Alert, Box, Button, Card, Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { theme } from "../../../Theme/theme";
import { GET } from "../../../Utilities/BaseService";
import {
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../../Utilities/constants";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";

type Filter = {
    search: string;
    district: (number | never)[] | "all";
    village: (number | never)[] | "all";
};
const initialFilter: Filter = { search: "", district: [], village: [] };

const fetchHamlets = (params: any = {}) => {
    return GET("/region/hamlet", params);
};

const Hamlet = () => {
    const [feedback, setFeedback] = useState("");
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [changeCount, setChangeCount] = useState(0);
    const [intermediateFilter, setIntermediateFilter] =
        useState<Filter>(initialFilter);
    const [filter, setFilter] = useState<any>(initialFilter);
    const [clearAll, setClearAll] = useState({
        district: false,
        village: false,
    });
    const [load, setLoad] = useState(false);

    const { data, isLoading, isSuccess, isError } = useQuery(
        ["hamlets", pagination.page, pagination.limit, filter],
        () =>
            fetchHamlets({
                page: pagination.page + 1,
                limit: pagination.limit,
                ...Object.fromEntries(
                    Object.entries(filter).filter(([k, v]) => v && v !== "all")
                ),
            }),
        {
            enabled: Boolean(changeCount),
            onSuccess(res) {
                if (res) setRowsCount(res?.data.count);
            },
        }
    );

    useEffect(() => {
        if ((intermediateFilter?.district.length ?? 0) === 0) {
            setClearAll({ district: true, village: true });
        }

        if (
            intermediateFilter?.village === "all" ||
            (intermediateFilter?.village.length ?? 0) === 0
        ) {
            setClearAll({ ...clearAll, village: true });
        }

        return () => {
            setClearAll({ district: false, village: false });
        };
    }, [intermediateFilter]);

    useEffect(() => {
        if (changeCount) {
            setFilter({ ...filter, ...(intermediateFilter as Filter) });
        }
    }, [changeCount]);

    return (
        <Box>
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography color="GrayText" variant="h5">
                            Hamlets
                        </Typography>
                    </Grid>

                    <Grid item>
                        <MultiPermissionAuthorize ops={["CREATE HAMLET"]}>
                            <Link
                                to="/regions/hamlets/add"
                                style={{ textDecoration: "none" }}
                            >
                                <Button
                                    variant="outlined"
                                    disableElevation
                                    color="secondary"
                                    startIcon={<Add />}
                                    fullWidth
                                    sx={{
                                        height: "100%",
                                        minWidth: theme.spacing(20),
                                    }}
                                >
                                    add hamlet
                                </Button>
                            </Link>
                        </MultiPermissionAuthorize>
                    </Grid>
                </Grid>

                <Grid container spacing={1} mt={1}>
                    <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                        <Grid item xs={12} md={4}>
                            <UpdatedSearchableInput
                                label="Districts"
                                _name="district"
                                api="/region/district"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                multiple
                                clearAll={clearAll.district}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                        <Grid item xs={12} md={4}>
                            <UpdatedSearchableInput
                                api="/region/village"
                                label="Villages"
                                _name="village"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                dep={intermediateFilter?.district}
                                params={{
                                    district: Boolean(
                                        intermediateFilter?.district.length || 0
                                    )
                                        ? intermediateFilter?.district !==
                                              "all" &&
                                          intermediateFilter?.district
                                        : [0],
                                }}
                                multiple
                                clearAll={clearAll.village}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <Grid item xs={12} md={4}>
                        <SearchBar
                            filter={intermediateFilter}
                            setFilter={setIntermediateFilter}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        lg="auto"
                        sx={{ minWidth: theme.spacing(20) }}
                    >
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => setChangeCount(changeCount + 1)}
                            sx={{ height: "100%" }}
                            startIcon={<FilterAltOutlined />}
                            fullWidth
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Card>

            {(!changeCount || !data?.data.rows.length || isError) && (
                <Alert severity="info">
                    {!data?.data.rows.length && isSuccess
                        ? noDataMessage
                        : !hasValue(filter) && !changeCount
                        ? filterSelectionMessage
                        : filterSelectionMessage}
                </Alert>
            )}

            <BaseTable
                headers={{
                    id: "ID",
                    name: "Name",
                    districtName: "District",
                    villageName: "Village",
                }}
                data={data?.data.rows.map((row: any) => ({
                    ...row,
                    villageName: row.village.name,
                    districtName: row.village.district.name,
                }))}
                defaultSelectedHeaders={[
                    "id",
                    "name",
                    "districtName",
                    "villageName",
                ]}
                delEndPoint="/region/hamlet"
                feedback={feedback}
                setFeedback={setFeedback}
                rowsCount={rowsCount}
                pagination={pagination}
                setPagination={setPagination}
                isLoading={isLoading}
                permissions={{
                    edit: ["UPDATE HAMLET"],
                    delete: ["DELETE HAMLET"],
                }}
                queryKey={[
                    "hamlets",
                    pagination.page,
                    pagination.limit,
                    filter,
                ]}
                reportName="Hamlets Report"
                load={Boolean(changeCount)}
            />
        </Box>
    );
};

export default Hamlet;
