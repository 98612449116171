import { Close, Done, InfoOutlined } from "@mui/icons-material";
import {
    Autocomplete,
    Checkbox,
    FormControl,
    IconButton,
    TableCell,
    TableRow,
    TextField,
} from "@mui/material";
import {
    ChangeEvent,
    Dispatch,
    MutableRefObject,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from "react";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";

type Props = {
    collectee: Collectee;
    // collections: { [key: number]: RawCollection };
    // setCollections: Dispatch<SetStateAction<{ [key: number]: RawCollection }>>;
    collectionsRef: MutableRefObject<{ [key: number]: RawCollection }>;
    collectionDate: string;
    paymentMethodRes: any[];
    collectionResults: any;
    setActiveResult: Dispatch<SetStateAction<any>>;
    count: number;
    checkAll: boolean;
    rowIndex: number;
    amountInputsRef: MutableRefObject<{ [rowIndex: number]: HTMLInputElement }>;
    adjustmentInputsRef: MutableRefObject<{
        [rowIndex: number]: HTMLInputElement;
    }>;
    columnVisibility: { [key: string]: boolean };
    // defaultPaymentMethod: null | { id: string; name: string };
    // checkedRows: { [key: number]: boolean };
    // setCheckedRows: Dispatch<SetStateAction<{ [key: number]: boolean }>>;
};

const calculateSurcharge = (
    collectee: Collectee,
    collectionDate: string
): number => {
    let surchargeAmount = 0;
    const _collDate = new Date(collectionDate).getTime();

    for (const reading of collectee.readings) {
        const dueDate = new Date(reading.dueDate).getTime();

        if (dueDate < _collDate) {
            surchargeAmount += reading.applicableSurcharge;
        }
    }

    return surchargeAmount;
};

const formatter = Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});

const initialRowData: RawCollection = {
    amount: 0,
    adjustment: 0,
    description: "",
    paymentMethod: null,
    checked: false,
    meterNo: "",
};

export const CollectionRow = ({
    collectee,
    // collections,
    // setCollections,
    collectionDate,
    paymentMethodRes,
    collectionResults,
    setActiveResult,
    count,
    checkAll,
    rowIndex,
    amountInputsRef,
    collectionsRef,
    adjustmentInputsRef,
    columnVisibility,
}: // defaultPaymentMethod,
Props) => {
    const [rowData, setRowData] = useState(initialRowData);

    const acsPaid = useMemo(() => {
        const acs = collectee.readings
            .map((r) => r.additionalCharges.map((ac: any) => ac.collections))
            .flat(2);
        return acs.reduce((prev, curr) => prev + curr.amount, 0);
    }, [collectionDate, collectee]);

    // const handleChange = (ev: ChangeEvent<any>, id: number) => {

    //     const { name, value } = ev.target;

    //     const isAmountChange = ["amount", "adjustment"].includes(name);

    //     let validAmount = 0;

    //     if (isAmountChange) {
    //         validAmount = Math.min(totalAmount, parseFloat(value || "0"));

    //         console.log(validAmount, totalAmount);
    //     }

    //     setRowData((curr) => {
    //         const change = {
    //             ...curr,
    //             [name]: isAmountChange
    //                 ? validAmount < 0
    //                     ? 0
    //                     : validAmount
    //                 : value,
    //             checked: Boolean(value),
    //         };

    //         collectionsRef.current = {
    //             ...collectionsRef.current,
    //             [id]: {
    //                 ...collectionsRef.current[id],
    //                 ...change,
    //             },
    //         };

    //         return change;
    //     });

    //     // setCollections(() => ({
    //     //     ...collections,
    //     //     [id]: {
    //     //         ...collections[id],
    //     //         [name]:
    //     //             name === "amount" ? Math.min(totalAmount, value) : value,
    //     //         checked: true,
    //     //     },
    //     // }));
    // };

    const handleChange = (ev: ChangeEvent<any>, id: number) => {
        const { name, value } = ev.target;

        const isAmountChange = ["amount", "adjustment"].includes(name);
        let validAmount = parseFloat(value) || 0; // Ensure it's a valid number

        if (isAmountChange) {
            validAmount = Math.min(totalAmount, validAmount); // Restrict max value
            validAmount = Math.max(validAmount, 0); // Ensure non-negative
            console.log(
                "Valid Amount:",
                validAmount,
                "Total Amount:",
                totalAmount
            );
        }

        setRowData((curr) => {
            const updatedRow = {
                ...curr,
                [name]: isAmountChange ? validAmount : value,
                checked: Boolean(value),
            };

            collectionsRef.current = {
                ...collectionsRef.current,
                [id]: {
                    ...collectionsRef.current[id],
                    ...updatedRow,
                },
            };

            return { ...curr, ...updatedRow }; // Ensure proper state update
        });
    };

    const handleSelect = (ev: any, checked: boolean) => {
        setRowData((curr) => {
            const updated = { ...curr, checked: checked };
            collectionsRef.current[collectee.id] = updated;
            return updated;
        });
    };

    const surcharge = useMemo(() => {
        let appliedSurcharge = 0;
        let paidSurcharge = 0;
        let surchargeAdj = 0;

        for (const reading of collectee.readings) {
            let readingCollectionDate: number | null = null;

            for (const collection of reading.collections) {
                if (
                    !readingCollectionDate ||
                    readingCollectionDate > collection.collectionDate
                ) {
                    readingCollectionDate = new Date(
                        collection.collectionDate
                    ).getTime();
                }
            }

            if (!readingCollectionDate) {
                readingCollectionDate = new Date(collectionDate).getTime();
            }

            if (readingCollectionDate > new Date(reading.dueDate).getTime()) {
                // Amount paid, surcharge applies
                appliedSurcharge += reading.applicableSurcharge;
                paidSurcharge += reading.payedSurcharge;
                surchargeAdj += reading.surchargeAdj;
            }
        }
        console.log(
            surchargeAdj,
            "surcharge Adjustment",
            appliedSurcharge,
            "appliead surcharge",
            paidSurcharge,
            "paid surcharge"
        );
        return appliedSurcharge - (paidSurcharge + surchargeAdj);
    }, [collectionDate, collectee]);

    const totalAmount = useMemo(() => {
        const readingTotal = collectee.readings.reduce(
            (prev, curr) => prev + curr.totalAmount,
            0
        );
        const collectionTotal = collectee.readings.reduce(
            (prev, curr) =>
                prev +
                curr.collections.reduce(
                    (p: any, c: any) => p + c.amount + c.adjustment,
                    0
                ),
            0
        );

        const total = readingTotal + surcharge - collectionTotal - acsPaid;
        console.log(
            readingTotal,
            "readingTotal",
            surcharge,
            "Surcharge",
            collectionTotal,
            "collectionTotal",
            acsPaid,
            "acsPaid"
        );

        console.log(total);

        return total;
    }, [surcharge, collectee, acsPaid]);

    useEffect(() => {
        if (!rowData.checked) return;

        setRowData((curr) => ({
            ...curr,
            paymentMethod:
                collectionsRef.current[collectee.id].paymentMethod ?? null,
        }));
    }, [rowData.checked, collectionsRef.current[collectee.id].paymentMethod]);

    useEffect(() => {
        setRowData((curr) => ({
            ...curr,
            checked: collectionsRef?.current[collectee.id]?.checked,
            meterNo: collectionsRef.current[collectee.id]?.meterNo,
        }));
    }, [collectionsRef?.current[collectee.id]?.checked]);

    return (
        <TableRow>
            <TableCell>
                <FormControl>
                    <Checkbox
                        size="small"
                        checked={rowData.checked}
                        onChange={handleSelect}
                    />
                </FormControl>
            </TableCell>

            {columnVisibility["Response"] && collectionResults[collectee.id] ? (
                <TableCell>
                    <IconButton onClick={() => setActiveResult(collectee.id)}>
                        {collectionResults[collectee.id].success ? (
                            <Done color="success" />
                        ) : (
                            <Close color="error" />
                        )}
                    </IconButton>
                </TableCell>
            ) : (
                columnVisibility["Response"] && (
                    <TableCell>
                        <IconButton disabled>
                            <InfoOutlined color="disabled" />
                        </IconButton>
                    </TableCell>
                )
            )}

            {columnVisibility["Serial no."] && <TableCell>{count}</TableCell>}
            {columnVisibility["Meter no."] && (
                <TableCell> {collectee?.meterNo}</TableCell>
            )}

            {columnVisibility["Customer"] && (
                <TableCell> {collectee?.property?.customer?.name}</TableCell>
            )}

            {columnVisibility["Property name"] && (
                <TableCell>{collectee?.property?.name}</TableCell>
            )}
            {columnVisibility["Total"] && (
                <TableCell>{formatter.format(totalAmount)}</TableCell>
            )}
            {columnVisibility["Collect amount"] && (
                <TableCell>
                    <TextField
                        inputRef={(el) => {
                            if (el) {
                                amountInputsRef.current[rowIndex] = el;
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                if (amountInputsRef.current[rowIndex + 1]) {
                                    e.preventDefault();
                                    amountInputsRef.current[
                                        rowIndex + 1
                                    ].focus();
                                }
                            }
                        }}
                        size="small"
                        name="amount"
                        label="Amount"
                        // value={collections[collectee.id]?.amount}
                        value={rowData.amount || ""}
                        onChange={(ev) => handleChange(ev, collectee.id)}
                        type="number"
                        inputProps={{ min: 0, max: totalAmount }}
                        sx={{
                            width: "20ch",
                            "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                                {
                                    display: "none",
                                },
                            "& input[type=number]": {
                                MozAppearance: "textfield", // Removes spinner in Firefox
                            },
                            minWidth: "20ch",
                        }}
                        InputProps={{
                            inputProps: {
                                style: {
                                    appearance: "textfield", // Removes spinner in modern browsers
                                },
                            },
                        }}
                    />
                </TableCell>
            )}
            {columnVisibility["Adjustment"] && (
                <MultiPermissionAuthorize ops={["CREATE READING_ADJUSTMENT"]}>
                    <TableCell>
                        <TextField
                            size="small"
                            name="adjustment"
                            label="Adjustment"
                            // value={collections[collectee.id]?.adjustment}
                            value={rowData.adjustment || ""}
                            onChange={(ev) => handleChange(ev, collectee.id)}
                            type="number"
                            inputProps={{ min: 0.0, step: ".1" }}
                            sx={{
                                width: "20ch",
                                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                                    {
                                        display: "none",
                                    },
                                "& input[type=number]": {
                                    MozAppearance: "textfield", // Removes spinner in Firefox
                                },
                                minWidth: "20ch",
                            }}
                            InputProps={{
                                inputProps: {
                                    style: {
                                        appearance: "textfield", // Removes spinner in modern browsers
                                    },
                                },
                            }}
                            inputRef={(el) => {
                                if (el) {
                                    adjustmentInputsRef.current[rowIndex] = el;
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    if (
                                        adjustmentInputsRef.current[
                                            rowIndex + 1
                                        ]
                                    ) {
                                        e.preventDefault();
                                        adjustmentInputsRef.current[
                                            rowIndex + 1
                                        ].focus();
                                    }
                                }
                            }}
                        />
                    </TableCell>
                </MultiPermissionAuthorize>
            )}

            {columnVisibility["Payment Method"] && (
                <TableCell style={{ minWidth: "256px" }}>
                    <Autocomplete
                        fullWidth
                        size="small"
                        options={paymentMethodRes}
                        getOptionLabel={(option: any) => option.name}
                        value={rowData.paymentMethod || null}
                        onChange={(_, newValue: any) => {
                            setRowData((curr) => {
                                const change = {
                                    ...curr,
                                    paymentMethod: newValue,
                                };

                                collectionsRef.current = {
                                    ...collectionsRef.current,
                                    [collectee.id]: {
                                        ...collectionsRef.current[collectee.id],
                                        ...change,
                                    },
                                };

                                return change;
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Payment Method"
                                required={
                                    rowData.checked == true &&
                                    !rowData?.paymentMethod?.id
                                }
                            />
                        )}
                    />
                </TableCell>
            )}

            {columnVisibility["Description"] && (
                <TableCell>
                    <TextField
                        sx={{ minWidth: "50ch" }}
                        multiline
                        minRows={1}
                        maxRows={5}
                        size="small"
                        name="description"
                        label="Description"
                        // value={collections[collectee.id]?.description}
                        value={rowData.description}
                        onChange={(ev) => handleChange(ev, collectee.id)}
                        placeholder={"Optional"}
                    />
                </TableCell>
            )}
        </TableRow>
    );
};
