import { AddOutlined, Close, Done, InfoOutlined } from "@mui/icons-material";
import {
    Box,
    Checkbox,
    Chip,
    Dialog,
    DialogContent,
    Divider,
    IconButton,
    InputAdornment,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { currencyFormatter } from "../../Bill/Cells/Last12Summary";
import { CreateReadingData, CurrReadingFrom, ReadingCtx } from "../AddReadings";

type Props = {
    target: any;
    updateMode?: boolean;
    count: number;
    columnVisibility: { [key: string]: boolean };
    index: number;
    overallLineRent: number;
};

type Surcharge = {
    id: number;
    isSurchargePct: boolean;
    name: string;
    value: number;
    calculationStrategy: string;
};

const getUnitPriceAdaptive = (components: any[], units: number) => {
    let unitsAmount = 0;
    let unitsRemaining = units;
    let unitPrice = [];

    const componentsByConsumption: {
        [consumption: string]: any[];
    } = {};

    for (const comp of components) {
        if (
            Object.keys(componentsByConsumption).includes(
                comp.consumption.toString()
            )
        ) {
            componentsByConsumption[comp.consumption].push(comp);
        } else {
            componentsByConsumption[comp.consumption] = [comp];
        }
    }

    const maximumRange = Object.keys(componentsByConsumption)
        .map((c) => parseInt(c))
        .sort((a, b) => b - a)[0];
    const targetSubCategory =
        units > maximumRange ? "-1" : maximumRange.toString();

    const targetComps = componentsByConsumption[targetSubCategory].sort(
        (a, b) => a.start - b.start
    );

    for (const comp of targetComps) {
        if (unitsRemaining === 0) break;

        const targetCost = comp.cost;

        let unitsInRange = 0;

        if (comp.end == -1) {
            unitsInRange = unitsRemaining;
            unitsRemaining = 0;
        } else {
            const compRange = comp.end - Math.max(0, comp.start - 1);

            if (unitsRemaining - compRange < 0) {
                unitsInRange = unitsRemaining;
                unitsRemaining = 0;
            } else {
                unitsInRange = compRange;
                unitsRemaining -= compRange;
            }
        }

        unitsAmount += targetCost * unitsInRange;

        unitPrice.push([unitsInRange, targetCost]);
    }

    return [unitsAmount, unitPrice];
};

// TODO Add adaptive surcharge calculation
const getUnitPrice = (meter: any, units: number) => {
    const intUnits = units;
    const components = meter.priceType.unitChargeRule.components;

    const lastComponent = components.find((c: any) => c.end === -1);

    if (!lastComponent) {
    }

    if (intUnits > lastComponent?.start) {
        return lastComponent?.cost;
    } else {
        const targetComp = components
            .filter((c: any) => c?.end !== -1)
            .find((c: any) => intUnits >= c?.start && intUnits <= c?.end);
        return targetComp?.cost ?? 0;
    }
};

const initialRowData: CreateReadingData = {
    select: false,
    units: 0,
    lineRent: 0,
    isLineRentEditable: false,
    additionalCharges: [],
    arrears: 0,
    feedback: {
        message: "",
        status: null,
    },
};

export const ReadingRow = ({
    target,
    updateMode = false,
    count,
    columnVisibility,
    index,
    overallLineRent,
}: Props) => {
    const { readingsDataRef } = useContext(ReadingCtx);
    const [rowData, setRowData] = useState(initialRowData);

    const handleSelect = (ev: any, checked: boolean) => {
        setRowData((curr) => {
            const updated = { ...curr, select: checked };

            readingsDataRef.current[target.id] = updated;

            return updated;
        });
        // setReadingsData({
        //     ...readingsData,
        //     [target.id]: { ...readingsData[target.id], select: checked },
        // });
    };

    useEffect(() => {
        if (!target) return;

        console.log(target.lineRent);

        setRowData((curr) => ({
            ...curr,
            ...readingsDataRef.current[target.id],
            lineRent: target.lineRent,
            isLineRentEditable: target.lineRentEditable,
        }));
    }, [target.id, readingsDataRef.current[target.id]]);

    return (
        <TableRow>
            <TableCell>
                <Checkbox
                    size="small"
                    checked={rowData.select}
                    onChange={handleSelect}
                />
            </TableCell>
            {columnVisibility["Status"] && (
                <StatusCell
                    target={target}
                    feedback={readingsDataRef.current[target.id].feedback}
                />
            )}

            {columnVisibility["Serial no."] && <SerialNoCell count={count} />}

            {columnVisibility["Meter no."] && (
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                    {target.meterNo}
                </TableCell>
            )}

            {columnVisibility["Customer name"] && (
                <TableCell>{target.property?.customer?.name}</TableCell>
            )}

            {columnVisibility["Previous reading"] && (
                <PreviousReadingCell target={target} updateMode={updateMode} />
            )}

            {columnVisibility["Curr. reading"] && (
                <CurrentReadingCell
                    target={target}
                    updateMode={updateMode}
                    rowIndex={index}
                    onChange={(reading: string) => {
                        setRowData((curr) => {
                            const change = {
                                ...curr,
                                units: parseInt(reading) || 0,
                                select: Boolean(reading),
                            };

                            readingsDataRef.current[target.id] = change;

                            return change;
                        });
                    }}
                />
            )}

            {columnVisibility["Consumed Units"] && (
                <ConsumedUnitsCell target={target} units={rowData.units} />
            )}

            {columnVisibility["Line Rent"] && (
                <LRCell
                    lineRent={rowData.lineRent}
                    target={target}
                    rowIndex={index}
                    onChange={(value: string) => {
                        const lineRent = parseInt(value || "0");

                        if (typeof lineRent !== "number") return;

                        setRowData((curr) => {
                            const change = {
                                ...curr,
                                lineRent,
                                select: true,
                            };

                            readingsDataRef.current[target.id] = change;

                            return change;
                        });
                    }}
                />
            )}

            {columnVisibility["Additional Charges"] && (
                <ACSCell
                    target={target}
                    additionalCharges={rowData.additionalCharges}
                    onChange={(acs) =>
                        setRowData(readingsDataRef.current[target.id])
                    }
                />
            )}

            {columnVisibility["Calculations"] && (
                <UnitAmountCalcCell target={target} units={rowData.units} />
            )}

            {columnVisibility["Surcharge"] && (
                <SurchargeCell
                    target={target}
                    units={rowData.units}
                    lineRent={rowData.lineRent}
                    additionalCharges={rowData.additionalCharges}
                />
            )}

            {columnVisibility["Connection charges"] && (
                <ConnChargesCell target={target} />
            )}
        </TableRow>
    );
};

const StatusCell = ({
    target,
    feedback,
}: {
    target: any;
    feedback: {
        message: string;
        status: "success" | "error" | null;
    };
}) => {
    const [open, setOpen] = useState(false);

    const { readingsDataRef } = useContext(ReadingCtx);

    return (
        <TableCell>
            {/* {feedback ? ( */}
            <IconButton
                disabled={feedback?.status === null}
                size="small"
                onClick={
                    feedback?.status === "success" ||
                    feedback?.status === "error"
                        ? () => setOpen(true)
                        : undefined
                }
            >
                {feedback?.status === "success" ? (
                    <Done color="success" />
                ) : feedback?.status === "error" ? (
                    <Close color="error" />
                ) : (
                    <InfoOutlined />
                )}
            </IconButton>
            {/* ) : null} */}

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent>{feedback?.message}</DialogContent>
            </Dialog>
        </TableCell>
    );
};

const PreviousReadingCell = ({ target, updateMode }: any) => {
    return (
        <TableCell>
            {updateMode ? target.lastReadingPrevUnits : target.lastReading ?? 0}
        </TableCell>
    );
};

const CurrentReadingCell = ({
    target,
    updateMode,
    rowIndex,
    onChange,
}: any) => {
    const {
        readingsData,
        setReadingsData,
        readingsDataRef,
        currReadingFrom,
        mode,
        currReadingInputsRef,
        setRowChangeCountMap,
    } = useContext(ReadingCtx);
    const [value, setValue] = useState<any>("");

    const is_value_less_than_last_reading = useMemo(
        () => value < parseInt(target.lastReading || "0"),
        [value, target.id]
    );

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        let value = parseInt(e.target.value) || 0;

        setValue(value);

        const validVal =
            value < parseInt(target.lastReading || "0") ? "" : value;

        readingsDataRef.current = {
            ...readingsDataRef.current,
            [target.id]: {
                ...readingsDataRef.current[target.id],
                units: validVal,
                select: true,
            },
        };

        onChange(value);

        // if (value < parseInt(target.lastReading || "0")) return;

        // setRowChangeCountMap((curr) => ({
        //     ...curr,
        //     [target.id]: (curr[target.id] || 0) + 1,
        // }));

        // setReadingsData(
        //     readingsDataRef.current

        // {
        //     ...readingsData,
        //     [target.id]: {
        //         ...readingsData[target.id],
        //         units:
        //             value < parseInt(target.lastReading || "0")
        //                 ? ""
        //                 : value,
        //         select: true,
        //     },
        // }
        // );
    }

    useEffect(() => {
        if (!target.id) return;

        const units = updateMode
            ? target.initialReading
            : currReadingFrom === CurrReadingFrom.ZERO
            ? ""
            : target.lastReading;

        setReadingsData((curr) => ({
            ...curr,
            [target.id]: {
                ...curr[target.id],
                units,
            },
        }));

        setValue(units);
    }, [target.id, currReadingFrom]);

    return readingsData[target.id] ? (
        <TableCell>
            <TextField
                inputRef={(el) => {
                    if (el) {
                        currReadingInputsRef.current = {
                            ...currReadingInputsRef.current,
                            [rowIndex]: el,
                        };
                    }
                }}
                type="number"
                size="small"
                required={false}
                // placeholder={target.lastReading}
                onChange={handleChange}
                value={value}
                sx={{
                    width: "20ch",
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                        {
                            display: "none",
                        },
                    "& input[type=number]": {
                        MozAppearance: "textfield", // Removes spinner in Firefox
                    },
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();

                        if (currReadingInputsRef.current[rowIndex + 1]) {
                            currReadingInputsRef.current[rowIndex + 1].focus();
                        }
                    }
                }}
                InputProps={{
                    inputProps: {
                        style: {
                            appearance: "textfield", // Removes spinner in modern browsers
                        },
                    },
                }}
                inputProps={
                    readingsData[target.id].select
                        ? {
                              min: target.lastReading,
                              onWheel: (event: any) => event.target.blur(),
                          }
                        : undefined
                }
                error={
                    // readingsData[target.id].select &&
                    value && value < parseInt(target.lastReading || "0")
                }
                helperText={
                    // readingsData[target.id].select &&
                    value && is_value_less_than_last_reading
                        ? `Reading must be >= ${target.lastReading}.`
                        : ""
                }
                onWheel={(ev) => ev.preventDefault()}
            />
        </TableCell>
    ) : null;
};

const ConsumedUnitsCell = ({ target, units }: any) => {
    const { readingsData } = useContext(ReadingCtx);

    // const calcRows = useMemo(() => {
    //     if (target.priceType.unitChargeRule.isAdaptive) {
    //         return getUnitPriceAdaptive(
    //             target.priceType.unitChargeRule.components,
    //             readingsData[target.id].units - target.lastReading
    //         );
    //     } else {
    //         return getUnitPrice(
    //             target,
    //             readingsData[target.id].units - target.lastReading
    //         );
    //     }
    // }, [target, readingsData]);

    return (
        <TableCell sx={{ minWidth: "20ch" }}>
            {units - target.lastReading}
        </TableCell>
    );
};

const LRCell = ({
    target,
    onChange,
    lineRent: defaultLineRent,
    rowIndex,
}: any) => {
    const { readingsDataRef, setReadingsData, currLineRentInputsRef } =
        useContext(ReadingCtx);
    const [lineRent, setLineRent] = useState(defaultLineRent);

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        if (!target.lineRentEditable) return;

        setLineRent(e.target.value);

        onChange(e.target.value);

        // setReadingsData({
        //     ...readingsData,
        //     [target.id]: {
        //         ...readingsData[target.id],
        //         lineRent: e.target.value,
        //         select: true,
        //     },
        // });
    }

    useEffect(() => {
        if (!readingsDataRef.current[target.id].isLineRentEditable) return;

        setLineRent(readingsDataRef.current[target.id].lineRent);
    }, [readingsDataRef.current[target.id].lineRent]);

    return target ? (
        <TableCell>
            <TextField
                type="number"
                onChange={handleChange}
                disabled={!target.lineRentEditable}
                size="small"
                value={lineRent}
                inputRef={(el) => {
                    if (el) {
                        currLineRentInputsRef.current = {
                            ...currLineRentInputsRef.current,
                            [rowIndex]: el,
                        };
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();

                        if (currLineRentInputsRef.current[rowIndex + 1]) {
                            currLineRentInputsRef.current[rowIndex + 1].focus();
                        }
                    }
                }}
                sx={{
                    width: "12ch",
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                        {
                            display: "none",
                        },
                    "& input[type=number]": {
                        MozAppearance: "textfield", // Removes spinner in Firefox
                    },
                }}
                inputProps={{
                    min: 0,
                    onWheel: (event: any) => event.target.blur(),
                }}
                onWheel={(ev) => ev.preventDefault()}
                InputProps={{
                    inputProps: { style: { appearance: "textfield" } },
                }}
            />
        </TableCell>
    ) : null;
};

const ACSCell = ({
    target,
    additionalCharges,
    onChange,
}: {
    target: any;
    additionalCharges: AdditionalCharge[];
    onChange: (acs: AdditionalCharge[]) => void;
}) => {
    const { readingsDataRef, setActiveMeter } = useContext(ReadingCtx);

    const handleDeleteACS = (idx: number) => {
        const newACS = additionalCharges.filter((x, id) => id != idx);

        readingsDataRef.current = {
            ...readingsDataRef.current,
            [target.id]: {
                ...readingsDataRef.current[target.id],
                additionalCharges: newACS,
            },
        };

        onChange(newACS);

        // setReadingsData({
        //     ...readingsData,
        //     [target.id]: {
        //         ...readingsData[target.id],
        //         additionalCharges: newACS,
        //     },
        // });
    };

    return target ? (
        <TableCell>
            <Box display="flex" alignItems="center">
                <Box display="flex" gap="2px">
                    {additionalCharges.map((c, idx) => (
                        <Chip
                            key={idx}
                            variant="filled"
                            size="small"
                            label={c.amount}
                            onDelete={() => handleDeleteACS(idx)}
                        />
                    ))}
                </Box>

                <IconButton
                    size="small"
                    onClick={() => setActiveMeter(target.id)}
                >
                    <AddOutlined />
                </IconButton>
            </Box>
        </TableCell>
    ) : null;
};

const UnitAmountCalcCell = ({ target, units }: any) => {
    const { readingsData } = useContext(ReadingCtx);

    const calcRows = useMemo(() => {
        if (target.priceType.unitChargeRule.isAdaptive) {
            return getUnitPriceAdaptive(
                target.priceType.unitChargeRule.components,
                units - target.lastReading
            );
        } else {
            return getUnitPrice(target, units - target.lastReading);
        }
    }, [target, units]);

    return (
        <TableCell sx={{ minWidth: "20ch" }}>
            {target.priceType.unitChargeRule?.isAdaptive ? (
                <div style={{ textAlign: "right" }}>
                    {calcRows[1].map(([cost, units]: any[], idx: number) => (
                        <div key={idx}>{`${cost} x ${units} = ${
                            cost * units
                        }`}</div>
                    ))}
                    <Divider />
                    <div>=&nbsp;{calcRows[0]}</div>
                </div>
            ) : (
                <>
                    {readingsData[target.id]?.units - target?.lastReading} x{" "}
                    {calcRows} ={" "}
                    {calcRows *
                        (readingsData[target.id]?.units - target?.lastReading)}
                </>
            )}
        </TableCell>
    );
};

const SurchargeCell = ({
    target,
    units,
    lineRent,
    additionalCharges,
}: {
    target: any;
    units: number;
    lineRent: number;
    additionalCharges: AdditionalCharge[];
}) => {
    const { readingsData } = useContext(ReadingCtx);

    const surcharge = useMemo(() => {
        if (!target || !(readingsData && readingsData[target.id])) return 0;
        let totalSurcharge = 0;

        if (target.priceType.surcharge.isSurchargePct) {
            const strategies = JSON.parse(
                target.priceType.surcharge.calculationStrategy ?? "[]"
            );
            const pct = target.priceType.surcharge.value / 100;

            for (const strat of strategies) {
                switch (strat) {
                    case "units":
                        const currentUnits = parseInt(units + "");
                        const prevUnits = target.lastReading;
                        const totalUnits = currentUnits - prevUnits;
                        const unitsAmount =
                            totalUnits * getUnitPrice(target, totalUnits) * pct;
                        totalSurcharge += unitsAmount;

                        console.log(
                            "units amount: ",
                            getUnitPrice(target, totalUnits),
                            "surcharge pct: ",
                            pct
                        );
                        break;

                    case "lr":
                        const meterLR = target.priceType.lineRent.amount;
                        totalSurcharge +=
                            (target.lineRentEditable ? lineRent : meterLR) *
                            pct;
                        break;

                    case "arrears":
                        totalSurcharge += target.arrears * pct;
                        break;

                    case "acs":
                        const acs = additionalCharges;
                        const totalAmount = acs.reduce(
                            (prev, curr) => prev + curr.amount,
                            0
                        );
                        totalSurcharge += totalAmount * pct;
                        break;

                    case "connCharges":
                        totalSurcharge += target.connectionChargesApplied
                            ? 0
                            : target.connectionCharges * pct;
                        break;
                }
            }

            return totalSurcharge;
        } else {
            return target.priceType.surcharge.value;
        }
    }, [target, additionalCharges.length, units, lineRent]);

    return <TableCell>{currencyFormatter.format(surcharge)}</TableCell>;
};

const ConnChargesCell = ({ target }: any) => {
    return (
        <TableCell>
            {currencyFormatter.format(
                target.connectionChargesApplied ? 0 : target.connectionCharges
            )}
        </TableCell>
    );
};

const ArrearsCell = ({ target }: any) => {
    const { readingsData, setReadingsData } = useContext(ReadingCtx);
    const [enabled, setEnabled] = useState(false);

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setReadingsData({
            ...readingsData,
            [target.id]: {
                ...readingsData[target.id],
                arrears: enabled
                    ? Math.max(0, parseFloat(ev.target.value))
                    : null,
            },
        });
    };

    return (
        <TableCell>
            <TextField
                disabled={!enabled}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip title="Enable arrears">
                                <Checkbox
                                    size="small"
                                    checked={enabled}
                                    onChange={(ev, checked) => {
                                        handleChange(ev);
                                        setEnabled(checked);
                                    }}
                                />
                            </Tooltip>
                        </InputAdornment>
                    ),
                }}
                type="number"
                variant="outlined"
                sx={{ width: "20ch" }}
                size="small"
                value={readingsData[target.id].arrears}
                onChange={handleChange}
            />
        </TableCell>
    );
};

const SerialNoCell = ({ count }: { count: number }) => {
    return <TableCell>{count}</TableCell>;
};
