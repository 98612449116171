import axios from "axios";
import { baseAPI } from "../Utilities/constants";

export class ReadingService {
    static readonly readingEndPoint = `${baseAPI}/reading/bulk/excel`;

    static readingColumns = ["meterNo", "units", "lineRent"];

    static async createReadingCollections(readings: any[]) {
        console.log(readings);
        const cleanCollection = [];

        for (const reading of readings) {
            const clean: any = {};

            for (const col of this.readingColumns) {
                clean[col] = reading[col];
            }

            cleanCollection.push(clean);
        }

        try {
            const response = await axios.post(
                this.readingEndPoint,
                { meters: cleanCollection },
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log("Error creating reading collections", err);
            return [null, err.response?.data];
        }
    }

    // static createCollectionBulk = async (data: any) => {
    //     try {
    //         const response = await axios.post(this.collecectionEndPoint, data, {
    //             withCredentials: true,
    //         });
    //         return [response.data, null];
    //     } catch (err: any) {
    //         console.error(err);
    //         return [null, err || notConnectedMessage];
    //     }
    // };
}
