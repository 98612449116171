import axios from "axios";
import { baseAPI } from "./constants";

export const GET = (url: string, params: any = {}) => {
    if (!navigator.onLine) {
        console.log("Internet is offline, skipping request.");
        return Promise.reject({ message: "No internet connection" }); // Reject the promise
    }

    return axios.get(baseAPI + url, {
        withCredentials: true,
        params,
    });
};

export const POST = (url: string, data: any = null, params: any = {}) => {
    return axios.post(baseAPI + url, data, {
        withCredentials: true,
        params,
    });
};

export const PATCH = async (
    url: string,
    data: any = null,
    params: any = {}
) => {
    return axios.patch(baseAPI + url, data, {
        withCredentials: true,
        params,
    });
};
export const DELETE = async (
    url: string,
    data: any = null,
    params: any = {}
) => {
    return axios.delete(baseAPI + url, {
        withCredentials: true,
        params,
    });
};
