import { Add, FilterAltOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GET } from "../../Utilities/BaseService";
import {
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../Utilities/constants";
import { MultiPermissionAuthorize } from "../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../Utilities/UpdatedSearchableInput";
import BaseTable from "../Table/BaseTable";
import SearchBar from "../Table/Components/SearchBar";
import { getSelectedFilters } from "./Property/Property";

type Filter = {
    search: string;
    district: number | "" | "all";
    village: number | "" | "all";
    hamlet: number | "" | "all";
    powerstation: number | "" | "all";
    property: number | "" | "all";
    id?: number;
};

const initialFilter: Filter = {
    search: "",
    district: "",
    village: "",
    hamlet: "",
    powerstation: "",
    property: "",
};

const initialClear = {
    district: false,
    village: false,
    hamlet: false,
    powerstation: false,
    property: false,
};

const Customer = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [filter, setFilter] = useState<any>(initialFilter);
    const [clearAll, setClearAll] = useState(initialClear);
    const [changeCount, setChangeCount] = useState(0);
    const [consumerId, setConsumerId] = useState<null | number>(null);
    const [intermediateFilter, setIntermediateFilter] =
        useState<Filter>(initialFilter);
    const [feedback, setFeedback] = useState("");
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));
    const [selectedFilters, setSelectedFilters] = useState({});

    const location = useLocation();

    const getCustomer = () => {
        return GET("/customer/", {
            page: pagination.page + 1,
            limit: pagination.limit,
            ...Object.fromEntries(
                Object.entries(filter).filter(([k, v]) => v && v != "all")
            ),
        });
    };

    const { data, isLoading, isError, isSuccess } = useQuery(
        ["customer", pagination.page, pagination.limit, filter],
        getCustomer,
        {
            enabled: Boolean(changeCount),
            onSuccess(res) {
                if (res.data) {
                    setRowsCount(res.data.count);

                    const _selectedFills = getSelectedFilters(
                        res.data.rows[0],
                        ["district", "village", "hamlet", "powerstation"],
                        filter,
                        {
                            district: { label: "District", valueKey: "name" },
                            village: { label: "Village", valueKey: "name" },
                            hamlet: { label: "Hamlet", valueKey: "name" },
                            powerstation: {
                                label: "Power Station",
                                valueKey: "name",
                            },
                        }
                    );

                    console.log(_selectedFills);

                    setSelectedFilters(() => _selectedFills);
                }
            },
        }
    );

    useEffect(() => {
        if (intermediateFilter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
                property: false,
            });
            setIntermediateFilter({
                ...intermediateFilter,
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
                property: "",
            });
        }

        if (intermediateFilter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
            setIntermediateFilter({
                ...intermediateFilter,
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
            setIntermediateFilter({ ...intermediateFilter, hamlet: "" });
        }

        if (intermediateFilter.powerstation === "all") {
            setClearAll({ ...clearAll, powerstation: true });
            setIntermediateFilter({ ...intermediateFilter, powerstation: "" });
        }

        if (filter.property === "all") {
            setClearAll({ ...clearAll, property: true });
            setIntermediateFilter({ ...intermediateFilter, property: "" });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
                property: false,
            });
        };
    }, [
        intermediateFilter.district,
        intermediateFilter.village,
        intermediateFilter.hamlet,
        intermediateFilter.powerstation,
        intermediateFilter.property,
    ]);

    useEffect(() => {
        if (changeCount) {
            setFilter({ ...filter, ...intermediateFilter });
        }
    }, [changeCount]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const _id = params.get("id");

        console.log(_id);

        if (_id) {
            setConsumerId(parseInt(_id));

            setIntermediateFilter({
                ...intermediateFilter,
                id: parseInt(_id),
            });

            setChangeCount(changeCount + 1);
        }
    }, [location.pathname]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: mdDown ? "auto" : `calc(100vh - 96px)`,
                }}
            >
                <Card
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.common.white,
                        p: 2,
                        mb: "1rem",
                    }}
                >
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1.5}
                    >
                        <Grid item xs={12} md="auto">
                            <Typography variant="h5" color="GrayText">
                                Consumers
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            md={6}
                            container
                            spacing={1.5}
                            justifyContent="flex-end"
                        >
                            <Grid item flex={1}></Grid>

                            <Grid item xs={12} md="auto">
                                <MultiPermissionAuthorize
                                    ops={["CREATE CUSTOMER"]}
                                >
                                    <Link
                                        to="/customer/consumers/add"
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Button
                                            sx={{
                                                minWidth: "22ch",
                                                height: "100%",
                                            }}
                                            variant="outlined"
                                            color="secondary"
                                            startIcon={<Add />}
                                            fullWidth
                                        >
                                            add consumer
                                        </Button>
                                    </Link>
                                </MultiPermissionAuthorize>
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} spacing={1.5}>
                            <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                                <Grid item flex={1}>
                                    <UpdatedSearchableInput
                                        label="District"
                                        api="/region/district"
                                        filter={intermediateFilter}
                                        setFilter={setIntermediateFilter}
                                        clearAll={clearAll.district}
                                    />
                                </Grid>
                            </MultiPermissionAuthorize>

                            <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                                <Grid item flex={1}>
                                    <UpdatedSearchableInput
                                        label="Village"
                                        api="/region/village"
                                        filter={intermediateFilter}
                                        setFilter={setIntermediateFilter}
                                        dep={intermediateFilter.district}
                                        params={{
                                            district:
                                                intermediateFilter.district,
                                        }}
                                        clearAll={clearAll.village}
                                    />
                                </Grid>
                            </MultiPermissionAuthorize>

                            <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                                <Grid item flex={1}>
                                    <UpdatedSearchableInput
                                        label="hamlet"
                                        api="/region/hamlet"
                                        filter={intermediateFilter}
                                        setFilter={setIntermediateFilter}
                                        dep={intermediateFilter.village}
                                        params={
                                            intermediateFilter.village &&
                                            intermediateFilter.village !== "all"
                                                ? {
                                                      village: [
                                                          intermediateFilter.village,
                                                      ],
                                                  }
                                                : { village: [0] }
                                        }
                                        clearAll={clearAll.hamlet}
                                    />
                                </Grid>
                            </MultiPermissionAuthorize>

                            {/* <MultiPermissionAuthorize
                                ops={["READ POWERSTATION"]}
                            >
                                <Grid item flex={1}>
                                    <UpdatedSearchableInput
                                        label="Powerstation"
                                        api="/powerstation"
                                        filter={intermediateFilter}
                                        setFilter={setIntermediateFilter}
                                        dep={intermediateFilter.district}
                                        params={{
                                            district:
                                                intermediateFilter.district,
                                        }}
                                        clearAll={clearAll.powerstation}
                                    />
                                </Grid>
                            </MultiPermissionAuthorize> */}

                            <Grid item flex={1}>
                                <SearchBar
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                />
                            </Grid>

                            <Grid item>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() =>
                                        setChangeCount(changeCount + 1)
                                    }
                                    sx={{
                                        minWidth: "22ch",
                                        height: "100%",
                                    }}
                                    startIcon={<FilterAltOutlined />}
                                    fullWidth
                                >
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>

                {/* {hasValue(filter) ? (
                    <Alert severity="info">{noDataMessage}</Alert>
                ) : (
                    <Alert severity="info">{filterSelectionMessage}</Alert>
                )} */}

                {(!changeCount || !data?.data.rows.length || isError) && (
                    <Alert severity="info">
                        {!data?.data.rows.length && isSuccess
                            ? noDataMessage
                            : !hasValue(filter) && !changeCount
                            ? filterSelectionMessage
                            : ""}
                    </Alert>
                )}
                <Box sx={{ height: "70%" }}>
                    <BaseTable
                        headers={{
                            id: "ID",
                            name: {
                                label: "Name",
                                styles: (item: any) => ({
                                    maxWidth: "20ch",
                                    whiteSpace:
                                        item?.name?.length > 10
                                            ? "normal"
                                            : "nowrap",
                                }),
                                props: (item: any) => ({}),
                            },
                            fatherName: "Fathername",
                            cnic: "CNIC",
                            mobileNo: "Contact No",
                            district: "District",
                            village: "Village",
                            hamlet: "Hamlet",
                            powerstation: "Powerstation",
                        }}
                        data={data?.data.rows.map((row: any) => ({
                            ...row,
                            district: row?.district?.name ?? "Global",
                            village: row?.village?.name ?? "Global",
                            hamlet: row?.hamlet?.name ?? "Global",
                            powerstation: row?.powerstation?.name ?? "Global",
                        }))}
                        delEndPoint="/customer"
                        feedback={feedback}
                        setFeedback={setFeedback}
                        rowsCount={rowsCount}
                        pagination={pagination}
                        setPagination={setPagination}
                        isLoading={isLoading}
                        load={data?.data.rows.length > 0}
                        permissions={{
                            edit: ["UPDATE CUSTOMER"],
                            delete: ["DELETE CUSTOMER"],
                        }}
                        queryKey={[
                            "customer",
                            pagination.page,
                            pagination.limit,
                            filter,
                        ]}
                        reportName="Customers List"
                        reportDetails={selectedFilters}
                        defaultSelectedHeaders={[
                            "id",
                            "name",
                            "fatherName",
                            "cnic",
                            "mobileNo",
                        ]}
                    />
                </Box>
            </Box>
        </>
    );
};
export default Customer;
