import { Add } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    TextField,
    useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GET, PATCH, POST } from "../../Utilities/BaseService";
import UpdatedSearchableInput from "../../Utilities/UpdatedSearchableInput";

const addCustomer = (customer: any) => {
    return POST("/customer", customer);
};
const updateDist = ({ customer, id }: any) => {
    return PATCH("/customer", customer, { id });
};

const AddCustomer = ({ updateMode }: any) => {
    const [customer, setCustomer] = useState({
        name: "",
        fatherName: "",
        cnic: "",
        mobileNo: "",
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });
    const [feedback, setFeedback] = useState("");
    const location = useLocation();
    const [id, setId] = useState("");
    const [clear, setClear] = useState({
        district: false,
        village: false,
        hamlet: false,
        powerstation: false,
    });
    const [defaultRegions, setDefaultRegions] = useState({
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });
    const theme = useTheme();

    const getCustomerById = () => {
        return GET(`/customer?id=${id}`);
    };

    const handleCnicChange = (ev: any) => {
        const cnic = ev.target.value;

        let _cnic = cnic;
        if (_cnic.length === 5 || _cnic.length === 13) {
            _cnic += "-";
        } else if (_cnic.length > 15) {
            return;
        }

        setCustomer({ ...customer, cnic: _cnic });
    };

    const handleMobileChange = (ev: any) => {
        const mbl = ev.target.value;
        let _mbl = mbl;

        if (_mbl.length === 4) {
            _mbl += "-";
        } else if (_mbl.length > 12) {
            return;
        }

        setCustomer({ ...customer, mobileNo: _mbl });
    };

    const { data: customerById } = useQuery([`customer`], getCustomerById, {
        enabled: Boolean(id),
        onSuccess(response) {
            if (response.data.rows.length > 0) {
                const _c = response.data.rows[0];

                setDefaultRegions({
                    district: _c.district.id,
                    village: _c.village.id,
                    hamlet: _c.hamlet.id,
                    powerstation: _c.powerstation.id,
                });

                setCustomer({
                    ...customer,
                    name: _c.name,
                    fatherName: _c.fatherName,
                    cnic: _c.cnic,
                    mobileNo: _c.mobileNo,
                    district: _c.district.id,
                    village: _c.village.id,
                    hamlet: _c.hamlet.id,
                    powerstation: _c.powerstation.id,
                });
            }
        },
        refetchOnMount: true,
    });

    const handleSubmit = (ev: any) => {
        ev.preventDefault();
        {
            !updateMode
                ? addMutation.mutate(customer)
                : updateMutation.mutate({
                      customer,
                      id,
                  });
        }
    };

    const addMutation = useMutation(addCustomer, {
        onSuccess(res) {
            setFeedback(res.data.message);
        },
        onError(err: any) {
            setFeedback(err?.response.data.message);
        },
    });

    const updateMutation = useMutation(updateDist, {
        onSuccess(res) {
            setFeedback(res.data.message);
        },
        onError(err: any) {
            return setFeedback(err.response.data.message);
        },
    });

    useEffect(() => {
        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");

            if (_id) {
                setId(_id);
            }
        }
    }, [updateMode]);

    useEffect(() => {
        if (customer.district === "all") {
            setClear({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });
            setCustomer({
                ...customer,
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (customer.village === "all") {
            setClear({ ...clear, village: true, hamlet: true });
            setCustomer({
                ...customer,
                village: "",
                hamlet: "",
            });
        }

        if (customer.hamlet === "all") {
            setClear({ ...clear, hamlet: true });
            setCustomer({ ...customer, hamlet: "" });
        }

        if (defaultRegions.district !== customer.district) {
            setClear({
                ...clear,
                village: true,
                hamlet: true,
            });

            setDefaultRegions({
                ...defaultRegions,
                district: customer.district as any,
            });

            setCustomer({
                ...customer,
                village: "",
                hamlet: "",
            });
        }

        return () => {
            setClear({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [customer.district, customer.village, customer.hamlet]);

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title={updateMode ? "update customer" : "Add customer"}
                    titleTypographyProps={{ color: "gray" }}
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />
                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={1.5}
                    >
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                size="small"
                                name="name"
                                value={customer.name}
                                label="Name"
                                onChange={(ev) =>
                                    setCustomer({
                                        ...customer,
                                        name: ev.target.value,
                                    })
                                }
                                fullWidth
                                type="text"
                                required
                                color={
                                    addMutation.isSuccess
                                        ? "success"
                                        : "primary"
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                size="small"
                                name="fatherName"
                                value={customer.fatherName}
                                label="Father Name"
                                onChange={(ev) =>
                                    setCustomer({
                                        ...customer,
                                        fatherName: ev.target.value,
                                    })
                                }
                                fullWidth
                                type="text"
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                size="small"
                                name="cnic"
                                value={customer.cnic}
                                label="CNIC"
                                // onChange={(ev) =>
                                //     setCustomer({
                                //         ...customer,
                                //         cnic: ev.target.value,
                                //     })
                                onChange={handleCnicChange}
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                size="small"
                                name="mobileNo"
                                value={customer.mobileNo}
                                label="Contact Number"
                                // onChange={(ev) =>
                                //     setCustomer({
                                //         ...customer,
                                //         mobileNo: ev.target.value,
                                //     })
                                // }
                                onChange={handleMobileChange}
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="District"
                                api="/region/district"
                                filter={customer}
                                setFilter={setCustomer}
                                required
                                defaultValue={
                                    updateMode && defaultRegions.district
                                }
                                clearAll={clear.district}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                key={defaultRegions.village}
                                label="Village"
                                api="/region/village"
                                filter={customer}
                                setFilter={setCustomer}
                                dep={customer.district}
                                params={{ district: customer.district }}
                                defaultValue={
                                    updateMode && defaultRegions.village
                                }
                                clearAll={clear.village}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Hamlet"
                                api="/region/hamlet"
                                filter={customer}
                                setFilter={setCustomer}
                                dep={customer.village}
                                params={
                                    customer.village &&
                                    customer.village !== "all"
                                        ? { village: [customer.village] }
                                        : { village: [0] }
                                }
                                defaultValue={
                                    updateMode && defaultRegions.hamlet
                                }
                                clearAll={clear.hamlet}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Powerstation"
                                api="/powerstation"
                                filter={customer}
                                setFilter={setCustomer}
                                dep={customer.district}
                                params={{ district: customer.district }}
                                defaultValue={
                                    updateMode && defaultRegions.district
                                }
                                clearAll={clear.powerstation}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                endIcon={
                                    addMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                size="small"
                                type="submit"
                            >
                                {updateMode ? "update" : "add"} customer
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess || updateMutation.isSuccess ? (
                <Alert severity="success">{feedback}.</Alert>
            ) : addMutation.isError || updateMutation.isError ? (
                <Alert severity="error">{feedback}.</Alert>
            ) : null}
        </>
    );
};

export default AddCustomer;
