import { useTheme } from "@emotion/react";
import { Public } from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Icon,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../Utilities/AppContext";
import { GET } from "../../Utilities/BaseService";
import MainView from "./Mainview";
import { OfflineIcon } from "./OfflinePage";
import Sidebar from "./Sidebar";
import { Topbar } from "./Topbar";

export const Layout = (props) => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const theme = useTheme();
    const { user, isOffline, setIsOffline } = React.useContext(AppContext);

    const checkConn = async () => {
        setLoading(true);

        try {
            const response = await GET("/");
            if (response?.data) {
                // navigate(-1);
                // setShowOfflineDialog(false);
                setIsOffline(false);
            }
        } catch (err) {
            // setShowOfflineDialog(true);
            setIsOffline(true);
        }
        setLoading(false);
    };

    return (
        <Box {...props}>
            <CssBaseline />
            <>
                {user && location.pathname !== "/login" && (
                    <>
                        <Topbar
                            sidebarOpen={sidebarOpen}
                            setSidebarOpen={setSidebarOpen}
                        />
                        <Sidebar
                            sidebarOpen={sidebarOpen}
                            setSidebarOpen={setSidebarOpen}
                        />
                    </>
                )}
                <MainView open={sidebarOpen} />
            </>

            <Dialog open={isOffline} fullWidth>
                <DialogContent>
                    <div
                        style={{
                            width: "100%",

                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div style={{ textAlign: "center" }}>
                            <Icon
                                sx={{
                                    width: "7rem",
                                    height: "7rem",
                                }}
                            >
                                <Public style={{ fontSize: "7rem" }} />
                                <OfflineIcon color={theme.palette.grey[400]} />
                            </Icon>

                            <Typography variant="h5">
                                You are offline!
                            </Typography>

                            <Typography variant="body1">
                                You're offline, but you can still stay on this
                                page. Please reconnect to the internet and click
                                retry to continue syncing your data.
                            </Typography>
                        </div>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={checkConn}
                        variant="contained"
                        endIcon={
                            loading ? <CircularProgress size="1rem" /> : null
                        }
                    >
                        Retry
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
